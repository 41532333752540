import { usePingMachine } from "hooks/machine";
import { MdOutlineCast, MdWifiOff, MdWifi, MdEdit } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//TOOD there is a problem with span on line 44, if the screen is too small there will be a horizontal scrollbar
export default function MachineRow({ machine, machineIdx }) {
  let { factoryId } = useParams();
  const navigate = useNavigate();
  const { data, error, isFetching } = usePingMachine(machine.serialNumber);
  return (
    <tr
      key={machine.serialNumber}
      className={classNames(
        machineIdx === 0 ? "border-gray-300" : "border-gray-200",
        "cursor-pointer border-t hover:bg-gray-50",
      )}
      onClick={() => {
        navigate(
          `/factories/${factoryId}/machine/${machine.serialNumber}/details`,
        );
      }}
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-8">
        {machine.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {machine.serialNumber}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {machine.type}
      </td>
      <td className="flex justify-center whitespace-nowrap px-3 py-4 text-sm  text-gray-500">
        {isFetching ? (
          <LoadingSpinner />
        ) : data?.data?.connection ? (
          <MdWifi className="text-green-500" size={25} />
        ) : (
          <MdWifiOff className="text-red-500" size={25} />
        )}
      </td>
      <td className="space-x-4 px-2 py-2 text-right sm:px-4 sm:py-4">
        <div className="hs-tooltip inline-block ">
          <button
            type="button"
            className="hs-tooltip-toggle  text-blue-500 hover:text-blue-300 "
            onClick={(event) => {
              event.stopPropagation();
              console.log("start navigator tool clicked");
            }}
          >
            <MdOutlineCast
              size={25}
              onClick={() =>
                navigate("/navigator/connect/" + machine.serialNumber)
              }
            />
            {/* <span
              className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-700 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
              role="tooltip"
            >
              Start Navigator Tool
            </span> */}
          </button>
        </div>
        <div className="hs-tooltip inline-block">
          <button
            type="button"
            className="hs-tooltip-toggle  text-blue-500 hover:text-blue-300 "
            onClick={(event) => {
              event.stopPropagation();
              console.log("edit machine clicked");
            }}
          >
            <MdEdit size={25} />
            {/* <span
              className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-700 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
              role="tooltip"
            >
              Edit Machine
            </span> */}
          </button>
        </div>
      </td>
    </tr>
  );
}

const LoadingSpinner = () => (
  <div
    className="inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
    role="status"
    aria-label="loading"
  >
    <span className="sr-only">Loading...</span>
  </div>
);
