import { Fragment, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import AddMachineDialog from "./AddMachineDialog";
import CreateRoomDialog from "./CreateRoomDialog";
import InviteUserDialog from "./InviteUserDialog";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropDown({ factoryDetails }) {
  const [addMachineOpen, setAddMachineOpen] = useState(false);
  const [createRoomOpen, setCreateRoomOpen] = useState(false);
  const [inviteUserOpen, setInviteUserOpen] = useState(false);
  return (
    <>
      <InviteUserDialog
        open={inviteUserOpen}
        setOpen={setInviteUserOpen}
        factoryId={factoryDetails?.id}
      />
      <CreateRoomDialog
        open={createRoomOpen}
        setOpen={setCreateRoomOpen}
        factoryId={factoryDetails?.id}
      />
      <AddMachineDialog
        open={addMachineOpen}
        setOpen={setAddMachineOpen}
        factoryId={factoryDetails?.id}
      />
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Options
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </MenuButton>
        </div>

        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <MenuItem>
                {({ focus }) => (
                  <a
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm cursor-pointer" ,
                    )}
                    onClick={() => setInviteUserOpen(true)}
                  >
                    Invite user
                  </a>
                )}
              </MenuItem>
            </div>
            <div className="py-1">
              <MenuItem>
                {({ focus }) => (
                  <a
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm cursor-pointer",
                    )}
                    onClick={() => setCreateRoomOpen(true)}
                  >
                    Create room
                  </a>
                )}
              </MenuItem>
            </div>
            <div className="py-1">
              <MenuItem>
                {({ focus }) => (
                  <a
                    className={classNames(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm cursor-pointer",
                    )}
                    onClick={() => setAddMachineOpen(true)}
                  >
                    Add machine
                  </a>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </>
  );
}
