import { CreateErrorEntries } from "./ErrorHandler";
import { ErrorLogEntry } from "./ErrorLogEntry";
import ErrorLogs from "./ErrorLogs";
export class ErrorPopUps extends ErrorLogs {
    errorShown: boolean
    ErrorJustShown: boolean

    constructor(errorLogsdata: Record<string, number>, errorConfig: Record<string, any>, prevstate?: ErrorPopUps) {
        super(CreateErrorEntries(errorLogsdata, errorConfig));
        this.errorShown = prevstate?.errorShown || false;

        this.ErrorJustShown = prevstate?.ErrorJustShown || false;


    }

    ErrorHasBeenShown() {
        setTimeout(() => {
            this.ErrorJustShown = false;
        }, 20000);
    }
    PopErrors() {
        this.errorLogs = [];
        this.errorShown = false;
        this.ErrorJustShown = true;
        this.ErrorHasBeenShown();
    }
    GetCurrentError(): ErrorLogEntry | null {
        if (!this.HasErrors())
            return null;
        this.errorShown = true;
        return super.GetCurrentError();
    }
    ErrorIsShown() {
        return this.errorShown;
    }
    HasJustShownErrors() {
        return this.ErrorJustShown;
    }
    HasErrors() {
        return this.errorLogs.length != 0;
    }

}