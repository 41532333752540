import { useMutation } from "@tanstack/react-query";
import { updateNotificationEmail } from "api/notification.service";

export const useUpdateNotificationEmail = () => {
  return useMutation({
    mutationFn: (data) => {
      return updateNotificationEmail(data);
    },
  });
};
