import { useQuery } from "@tanstack/react-query";
import { getConsumptionDataBySerialNumber, getConsumptionDataBySerialNumberOwn } from "api/machine.service";
import { useSelector } from "react-redux";

export const useMachineConsumptionDataQuery = (MachineId) => {

  const myAccount = useSelector((state) => state.user.user);

  const isAdmin = myAccount && (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useQuery({
    queryKey: ["machine", MachineId, "performance"],
    queryFn: () => (isAdmin ? getConsumptionDataBySerialNumber(MachineId) : getConsumptionDataBySerialNumberOwn(MachineId)),
    // enabled: !!serialNumber,
  });
};
