import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CircularProgress, Grid, IconButton, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { Get_Keys_Display_Name } from "../../Models/ServerCommuncation/Settings";
import { DatedIotData } from "../../Models/ServerCommuncation/serverInterFace";
import { TelemtricView as TelemetricViewer } from "../uiElements/noninteractive/TelemtryView.tsx/telemtricview";
import { HasNoDetailedView, MapNameToUnit, ParseTelemtryNames } from "./DashBoardParsing";
import "./dashboard.css";
import { Settings } from "@mui/icons-material";

type TelemetricViewType = "detailed" | "normal";

type TelemetricViews = ({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }) => JSX.Element;
const TelemetricViewsMap: Record<TelemetricViewType, TelemetricViews> = {
  detailed: DetailedView,
  normal: NormalView,
};

export default function TelemtryDataView() {
  const Server = GetServer();

  const [telemetryData, setTelemetryData] = React.useState<DatedIotData[]>([]);
  const [currentTelemetryData, SetCurrentTelemtryData] = React.useState<DatedIotData | null>(null);

  const [searchText, SetSearchText] = React.useState<string>();
  const [keys, SetKeys] = React.useState<string[]>([]);

  const setData = (data: DatedIotData[]) => {
    setTelemetryData((prevState) => {
      SetCurrentTelemtryData(data[data.length - 1]);

      return data;
    });
  };
  useEffect(() => {
    Server.on_telemtry_last_10_min(setData);

    return () => {
      Server.off_telemtry_last_10_min(setData);
    };
  });
  useEffect(() => {
    if (telemetryData.length != 0) {
      let keys = Get_Keys_Display_Name(telemetryData[0].data, searchText);
      SetKeys(keys);
    }
  }, [telemetryData, searchText]);
  if (currentTelemetryData === null) {
    return (
      <Grid item container direction={"column"} justifyContent={"center"} alignContent={"center"} height={"100%"} >

        <CircularProgress size={"5vmax"} />
      </Grid>
    );
  }
  return (
    <>
      <Grid item xs={1} container justifyContent="center" paddingTop={"1vh"}>
        <TextField
          sx={{ bgcolor: "white" }}
          label="Search"
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            SetSearchText(event.target.value);
          }}
        ></TextField>
      </Grid>
      <Paper sx={{ width: '100%', maxHeight: "71vh", marginTop: "0.5vmax" }}>
        <TableContainer sx={{ maxHeight: "71vh", overflowX: "hidden", }} className="scroll" >
          <Table stickyHeader>
            <TableHead >
              <TableRow >
                <TableCell >
                  <Typography variant="button" fontSize={"0.7vmax"}>Name</Typography>
                </TableCell>
                <TableCell  >
                <Typography variant="button" fontSize={"0.7vmax"}>Value</Typography>

                  
                </TableCell >
                <TableCell  >
                <Typography variant="button" fontSize={"0.7vmax"}>Unit</Typography>
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {keys.map((key) => {
                return <TelemetricView telemetryKey={key} telemetryData={telemetryData} backgroundColor="white"></TelemetricView>;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

    </>
  );
}
function IsFlag(name: string): boolean {
  return name === "FlowSw"
}
function FlagView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }) {
  let data = telemetryData[telemetryData.length - 1].data[telemetryKey];
  const displayName = ParseTelemtryNames(telemetryKey);

  const HasFlow = data >= 300;
  const unit = MapNameToUnit(telemetryKey);
  return (
    <>
      <TableCell>
        <Typography variant="button" alignItems={"center"} color="black" overflow="hidden" fontSize={"0.7vmax"}>
          {displayName}
        </Typography>
      </TableCell>
      <TableCell sx={{  backgroundColor : HasFlow ? "green" : "red" }}>
      </TableCell>
      <TableCell>
        <Grid item container xs={10} justifyContent="center" alignItems={"center"}>
          <Typography variant="button" alignItems={"center"} color="black" overflow="hidden"  fontSize={"0.7vmax"}>
            {unit}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell></TableCell>
    </>
  );
}

function GridNames(): JSX.Element {
  const [ShowInfo, SetShowInfo] = React.useState(false);

  return (
    <Grid container direction={"column"} rowGap={2}>
      <Grid item xs={12} container bgcolor={"white"} borderRadius={"10px"}>
        <Grid item xs={11} container bgcolor={"white"} borderRadius={"10px"} >
          <Grid item xs={7} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
            <Typography variant={"button"} color="black">
              Name
            </Typography>
          </Grid>
          <Grid item xs={3} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
            <Typography variant={"button"} color="black">
              Val
            </Typography>
          </Grid>
          <Grid item xs={2} container justifyContent={"center"} alignItems={"center"} borderRight={1}>
            <Typography variant={"button"} color="black">
              Unit
            </Typography>
          </Grid>

        </Grid>
        <Grid item xs={1} container alignItems={"center"} justifyContent={"center"}>
          <IconButton
            onClick={() => {
              SetShowInfo((prev) => !prev);
            }}
          >
            <HelpOutlineIcon></HelpOutlineIcon>
          </IconButton>
        </Grid>
      </Grid>

      {ShowInfo ? (
        <Grid item container bgcolor={"white"} borderRadius={"10px"}>
          <InfoMenu></InfoMenu>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}

function InfoMenu(): JSX.Element {
  return (
    <Grid container bgcolor={"white"} borderRadius={"10px"} direction={"column"}>
      <Grid item container justifyContent={"start"}>
        <Grid></Grid>

        <Typography variant={"button"} color="black">
          Name: The Name of the field.
        </Typography>
      </Grid>
      <Grid item container justifyContent={"start"}>
        <Typography variant={"button"} color="black">
          Val: The value for the given telemetry data field.
        </Typography>
      </Grid>
      <Grid item container justifyContent={"start"}>
        <Typography variant={"button"} color="black">
          Unit_: The unit for a given telemetry field.
        </Typography>
      </Grid>

    </Grid>
  );
}

function TelemetricView({ telemetryKey, telemetryData, backgroundColor }: { telemetryKey: string; telemetryData: DatedIotData[]; backgroundColor: string }) {
  const [ShowDetailed, SetShowDetailed] = React.useState<boolean>(false);
  const displayName = ParseTelemtryNames(telemetryKey);
  const HasNoDetailed = HasNoDetailedView(telemetryKey);

  const flag = IsFlag(telemetryKey);
  if (displayName === undefined) {
    return null;
  }
  if (flag) {
    return (
      <TableRow>

            <FlagView telemetryKey={telemetryKey} telemetryData={telemetryData}></FlagView>
      </TableRow>
    );
  }

  if (HasNoDetailed) {
    return (
      <TableRow>
       
            <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>
       
      </TableRow>
    );
  }
  if (!ShowDetailed) {
    return (
      <TableRow>
          <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>
        <TableCell sx={{maxWidth: "3vw"}}>
          <IconButton
            onClick={() => {
              SetShowDetailed((prev: boolean) => !prev);
            }}
          >
            <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  return (<>
    <TableRow>
      <NormalView telemetryKey={telemetryKey} telemetryData={telemetryData}></NormalView>
      <TableCell>
        <IconButton
          onClick={() => {
            SetShowDetailed((prev: boolean) => !prev);
          }}
        >
          <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
        </IconButton>
      </TableCell>
    </TableRow>
    <TableRow>
        <DetailedView telemetryKey={telemetryKey} telemetryData={telemetryData}></DetailedView>
    </TableRow>
  </>

  );
}

function NormalView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }) {
  let data = telemetryData[telemetryData.length - 1].data[telemetryKey];
  const displayName = ParseTelemtryNames(telemetryKey);

  const parsedValue = data;
  const unit = MapNameToUnit(telemetryKey);
  return (<>
    <TableCell sx={{maxWidth: "5vmax"}}>
        <Typography variant="button" alignItems={"center"} color="black" overflow="hidden"  fontSize={"0.7vmax"}>
          {displayName}
        </Typography>
      </TableCell>
      <TableCell>
      <Grid item container xs={10} justifyContent="center" alignItems={"center"}>
          <Typography variant="button" alignItems={"center"} color="black" overflow="hidden"  fontSize={"0.7vmax"}>
            {parsedValue}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid item container xs={10} justifyContent="center" alignItems={"center"}>
          <Typography variant="button" alignItems={"center"} color="black" overflow="hidden" fontSize={"0.7vmax"}>
            {unit}
          </Typography>
        </Grid>
      </TableCell>
      </>
  );
}

function DetailedView({ telemetryKey, telemetryData }: { telemetryKey: string; telemetryData: DatedIotData[] }): JSX.Element {
  return <TableCell colSpan={4}>
    <TelemetricViewer telemetryKey={telemetryKey} telemetryData={telemetryData}></TelemetricViewer></TableCell>;
}
