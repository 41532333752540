import apiClient from "./apiClient";

/* ----- Get Methods ----- */
export const getMyNotificationPreferences = () => {
  return apiClient.get("notification-machine-code");
};

/* ----- Put Methods ----- */
export const updateOwnNotificationMachineCode = ({ id, inUse }) => {
  console.log("method", id, inUse);
  return apiClient.put(`notification-machine-code/update/${id}`, { inUse });
};

export const updateAllOwnNotificationMachineCode = (data) => {
  return apiClient.put(`notification-machine-code/update-all`, data);
};

export const resetNotificationMachineCodes = (NotificationId) => {
  return apiClient.put(`notification-machine-code/reset/${NotificationId}`);
};

export const updateNotificationEmail = (updateSettings) => {
  return apiClient.put("notification/update", updateSettings);
};
