import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';


export default function Home()
{

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>

					<img src={require('shared/utils/images/NilfiskFood.png')} alt="NilfiskFood" />
		</div>
	);
}
