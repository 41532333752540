import { useQuery } from "@tanstack/react-query";
import { getErrorLogV2 } from "api/machine.service";

export const useMachineErrorLogQuery = (serialNumber) => {
  return useQuery({
    queryKey: ["machine", serialNumber, "ErrorLog"],
    queryFn: () => getErrorLogV2(serialNumber),
    // enabled: !!serialNumber,
  });
};
