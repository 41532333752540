import { useQuery } from "@tanstack/react-query";
import { getMachineBySN } from "api/machine.service";
import { useSelector } from "react-redux";
import {
  getMachinesByFactoryId,
  getOwnMachinesByFactoryId,
} from "api/machine.service";

export const useMachinesByFactoryIdQuery = (factoryId) => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useQuery({
    queryKey: ["machines", factoryId],
    queryFn: () =>
      isAdmin
        ? getMachinesByFactoryId(factoryId)
        : getOwnMachinesByFactoryId(factoryId),
    enabled: !!factoryId,
  });
};
