import { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useUpdateMyAccount } from "hooks/user";
import { useQueryClient } from "@tanstack/react-query";

export default function TextInputForm({
  name,
  autoComplete,
  defaultValue,
  type,
  required,
  isLoading,
  editDisabled = false,
  canEdit = true,
}) {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const myAccount = useSelector((state) => state.user.user);
  const [isEditing, setIsEditing] = useState(false);

  const { mutate: updateMyAccount, isPending, isError, isSuccess } = useUpdateMyAccount();

  const onSubmit = (data) => {
    clearErrors();
    if (required && (!data[name] || !data[name].trim())) {
      setError(name, { type: "required", message: name + " is required" });
      return;
    }

    let newData = {};

    switch (name) {
      case "Name":
        {
          const nameParts = data[name].trim().split(" ");
          const lastName = nameParts.pop();
          const firstName = nameParts.join(" ");

          newData = {
            firstName: firstName,
            lastName: lastName,
            email: myAccount.email,
            oldEmail: myAccount.email,
          };
        }
        break;
      case "Username":
        newData = {
          username: data[name],
          email: myAccount.email,
          oldEmail: myAccount.email,
        };
        break;
      case "Email":
        newData = {
          email: data[name],
          oldEmail: myAccount.email,
        };
        break;
      case "Position":
        newData = {
          position: data[name],
          email: myAccount.email,
          oldEmail: myAccount.email,
        };
        break;
      case "Phone":
        newData = {
          phone: data[name],
          email: myAccount.email,
          oldEmail: myAccount.email,
        };
        break;
      default:
        break;
    }
    updateMyAccount(newData, {
      onSuccess: () => {
        queryClient.setQueryData(["myAccount"], (oldData) => {
          console.log("oldData", oldData);
          console.log("newData", newData);
          return {
            ...oldData,
            data: {
              ...oldData.data,
              user: { ...oldData.data.user, ...newData },
            },
          };
        });
        queryClient.invalidateQueries({ queryKey: ["myAccount"] });
        setIsEditing(false);
      },
      onError: (error) => {
        setError(name, { type: "manual", message: "Please check your input" });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`py-4 sm:grid sm:grid-cols-3 sm:gap-4 ${!isEditing ? "sm:py-5" : "sm:py-3"}`}>
        <dt className="text-sm font-medium text-gray-500 self-center">{name}</dt>
        <dd className={`mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-x-4`}>
          <span className="flex-grow self-center">
            {isLoading ? (
              <div className="h-3 bg-slate-200 rounded animate-pulse w-1/2"></div>
            ) : !isEditing ? (
              <>{defaultValue || " "}</>
            ) : (
              <div className="relative sm:w-2/3">
                <input
                  id={name}
                  name={name}
                  type={type}
                  autoComplete={autoComplete}
                  required={required}
                  defaultValue={defaultValue}
                  disabled={isPending}
                  {...register(name, { required: required })}
                  className={`block w-full sm:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    errors[name] ? "focus:ring-red-500 ring-red-500" : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
                <p className="mt-2 text-sm text-red-600" id="email-error" hidden={!errors[name]}>
                  {errors[name]?.message}
                </p>
                {isPending && (
                  <div
                    className="absolute top-1.5  right-2 animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            )}
          </span>

          {name === "Email" && !isEditing && (
            <>
              {myAccount?.account_confirmed ? (
                <span className="self-center inline-flex items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  Verified
                </span>
              ) : (
                <span className="inline-flex items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                  Not verified
                </span>
              )}
              <span className="text-gray-300 self-center" aria-hidden="true">
                |
              </span>
            </>
          )}
          <span className="ml-4 flex-shrink-0 self-center">
            <button
              type="button"
              onClick={() => {
                setValue(name, defaultValue);
                setIsEditing(true);
              }}
              hidden={isEditing || isLoading || !canEdit}
              disabled={editDisabled}
              className="disabled:opacity-50 disabled:hover:text-blue-600 rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Update
            </button>
            <button
              hidden={!isEditing || isLoading || !canEdit}
              disabled={isPending}
              type="submit"
              className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save
            </button>
          </span>
        </dd>
      </div>
    </form>
  );
}
