import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BigInfofield as BigInfoFieldModel, UiElement } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";

export default function BigInfofield({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
  return <BigInfoFieldInner InfoField={element as BigInfoFieldModel}></BigInfoFieldInner>;
}

function BigInfoFieldInner({ InfoField }: { InfoField: BigInfoFieldModel }) {
  const Server = GetServer();
  let [val, setVal] = React.useState<number>(0);
  function SetTelemetry(data: DatedIotData) {
    setVal(data.data[InfoField.telemtryField]);
  }
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);
  return (
    <Grid container justifyContent={"center"} columnGap={2}>
      <Grid item container xs={3} justifyContent={"center"}>
        <Typography fontSize={"3vw"} fontWeight={"fontWeightBold"} color={"white"}>
          {val}
        </Typography>
      </Grid>
      <Grid item container xs={3} justifyContent={"start"}>
        <Typography fontSize={"3vw"} fontWeight={"fontWeightBold"} color={"white"}>
          {InfoField.fieldUnit}
        </Typography>
      </Grid>
    </Grid>
  );
}
