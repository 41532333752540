import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useUpdateMyAccount } from "hooks/user";
import { useQueryClient } from "@tanstack/react-query";
import { CountrySelector, FlagImage } from "react-international-phone";
import "react-international-phone/style.css";

export default function TextInputPhoneForm({
  name,
  autoComplete,
  defaultValue,
  type,
  required,
  isLoading,
  editDisabled = false,
  canEdit = true,
}) {
  const [prefix, setPrefix] = useState("+45 ");
  const [countryCode, setCountryCode] = useState("dk");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    let phoneTemp = defaultValue.split(" ");
    if (phoneTemp.length >= 3) {
      if (phoneTemp[0].includes("(")) {
        phoneTemp[0] = phoneTemp[0].replace("(", "");
        phoneTemp[0] = phoneTemp[0].replace(")", "");
        phoneTemp[0] = phoneTemp[0].toLowerCase();
        setCountryCode(phoneTemp[0]);
      }

      setPrefix(phoneTemp[1]);

      phoneTemp.shift();
      phoneTemp.shift();
      console.log("phoneTemp", phoneTemp);
      setPhone(phoneTemp.join(" "));
    }
  }, [defaultValue]);

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const myAccount = useSelector((state) => state.user.user);
  const [isEditing, setIsEditing] = useState(false);

  const { mutate: updateMyAccount, isPending, isError, isSuccess } = useUpdateMyAccount();

  const onSubmit = (data) => {
    clearErrors();
    if (required && (!data[name] || !data[name].trim())) {
      setError(name, { type: "required", message: name + " is required" });
      return;
    }
    let newPhone = data.Phone;
    newPhone = "(" + countryCode.toUpperCase() + ") " + prefix + " " + newPhone;

    let newData = {
      phone: newPhone,
      email: myAccount.email,
      oldEmail: myAccount.email,
    };

    updateMyAccount(newData, {
      onSuccess: () => {
        queryClient.setQueryData(["myAccount"], (oldData) => {
          console.log("oldData", oldData);
          console.log("newData", newData);
          return {
            ...oldData,
            data: {
              ...oldData.data,
              user: { ...oldData.data.user, ...newData },
            },
          };
        });
        queryClient.invalidateQueries({ queryKey: ["myAccount"] });
        setIsEditing(false);
      },
      onError: (error) => {
        setError(name, { type: "manual", message: "Please check your input" });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`py-4 sm:grid sm:grid-cols-3 sm:gap-4 ${!isEditing ? "sm:py-5" : "sm:py-3"}`}>
        <dt className="text-sm font-medium text-gray-500 self-center">{name}</dt>
        <dd className={`mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 space-x-4`}>
          <span className="flex-grow self-center">
            {isLoading ? (
              <div className="h-3 bg-slate-200 rounded animate-pulse w-1/2"></div>
            ) : !isEditing ? (
              <div className="flex items-center gap-2">
                <FlagImage iso2={countryCode} size="30px" /> {prefix + " " + phone || " "}
              </div>
            ) : (
              <div className="relative sm:w-2/3">
                <div className="relative mt-2 rounded-md shadow-sm">
                 
                  <div className="absolute inset-y-0 left-0 flex items-center">
                    <CountrySelector
                      selectedCountry={countryCode}
                      onSelect={(data) => {
                        setCountryCode(data.iso2);
                        setPrefix("+" + data.dialCode);
                      }}
                      buttonClassName="!outline-none !border-0 !bg-transparent !h-full !px-2 !flex !items-center"
                    />
                   
                  </div>
                  <div className="pointer-events-none absolute inset-y-0 left-12 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">{prefix}</span>
                  </div>
                  <input
                    id={name}
                    name={name}
                    type={type}
                    autoComplete={autoComplete}
                    required={required}
                    defaultValue={phone}
                    disabled={isPending}
                    {...register(name, { required: required })}
                    className={`
                    pl-
                    ${
                      prefix.length === 2
                        ? "pl-[83px]"
                        : prefix.length === 3
                        ? "pl-[94px]"
                        : prefix.length === 4
                        ? "pl-[101px]"
                        : "pl-[105px]"
                    }  block w-full sm:w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                      errors[name] ? "focus:ring-red-500 ring-red-500" : "focus:ring-blue-500 ring-gray-300"
                    }  sm:text-sm sm:leading-6`}
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error" hidden={!errors[name]}>
                  {errors[name]?.message}
                </p>
                {isPending && (
                  <div
                    className="absolute top-1.5  right-2 animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            )}
          </span>
          <span className="ml-4 flex-shrink-0 self-center">
            <button
              type="button"
              onClick={() => {
                setValue(name, phone);
                setIsEditing(true);
              }}
              hidden={isEditing || isLoading || !canEdit}
              disabled={editDisabled}
              className="disabled:opacity-50 disabled:hover:text-blue-600 rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Update
            </button>
            <button
              hidden={!isEditing || isLoading || !canEdit}
              disabled={isPending}
              type="submit"
              className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save
            </button>
          </span>
        </dd>
      </div>
    </form>
  );
}
