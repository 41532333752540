import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Diode as DiodeModel, UiElement } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";


export default function Diode({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }): JSX.Element {

    return <InnerDiode diode={element as DiodeModel}></InnerDiode>
}


function InnerDiode({ diode }: { diode: DiodeModel }) {
    const Server = GetServer();

    let [color, setColor] = React.useState<string>("");
    let [codeName, description] = diode.display_name.split("\n");
    function SetTelemetry(data: DatedIotData) {

        let val = data.data[diode.telemtryField];

        setColor(val ? "7CFC00" : "white");
    }

    useEffect(() => {


        Server.on_telemtry(SetTelemetry);

        return () => {

            Server.off_telemtry(SetTelemetry);
        }
    }, []);
    return (

        <Grid item container borderRadius={"0.5vw"} >
            <Grid item xs={6} container justifyContent="center" bottom={0} top="auto">
                <Grid item container direction="column" >
                    <Grid item xs={6}>
                        <Typography fontSize="0.75em" textAlign="center" bottom={0}>{codeName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontSize="0.75em" textAlign="center" bottom={0}>{description}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container xs={6} justifyContent={"center"}  >
                <Grid item> <Box height={"1.5vw"} width={"1.5vw"} bgcolor={color} borderRadius={"50%"} border={"0.2vw solid grey"}></Box></Grid>
            </Grid>

        </Grid >


    )


}