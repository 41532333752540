import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  deleteUserFromFactory,
  deleteUserFromOwnFactory,
} from "api/factory.service";

export const useRemoveUserFromFactory = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      if (isAdmin) return deleteUserFromFactory(data.UserId, data.FactoryId);
      else return deleteUserFromOwnFactory(data.UserId, data.FactoryId);
    },
  });
};
