import React from "react";
// import { userService } from "shared/services/authentication.service";
import { Link, useNavigate } from "react-router-dom";
export default function InternalServerErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {/* Sign in to your account */}
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[700px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
              <p className="text-2xl font-semibold text-red-500">500</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Internal Server Error
              </h1>
              <p className="mt-6 text-base leading-7 text-gray-600">
                We apologiese for the inconvenience. Please try again later.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Go back
                </Link>
                <Link to="/customer-support" className="text-sm font-semibold text-gray-900">
                  Contact support <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
