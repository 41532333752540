import { ParseTelemtryNames } from "../../Views/DashBoard/DashBoardParsing";
import { IotData, telemetryData } from "./serverInterFace";

export type Setting = { command: string; parameters?: string[] };
export type Performancedata = { metric_name: string; metrics: Record<string, Metric> };
export type Metric = { metric_type: string; unit: string; value: string };

export function ParseSettings(all_settings: string): Record<string, Setting> {
  const settings_map: Record<string, { command: string; parameters?: string[] }> = {};
  if (all_settings.length == 0) {
    return {};
  }
  let settings = all_settings.split("# ");

  for (const setting_str of settings) {
    let [command, ...parameter] = setting_str.split(" ");
    command = command.split("_").join(" ");
    let parameters = parameter;
    if (parameters.length === 0) {
      settings_map[command] = { command: command };
    }
    settings_map[command] = { command: command, parameters };
  }
  return settings_map;
}


export function Get_Keys(telemetryData: telemetryData, searchText?: string): string[] {
  
  if (searchText) {
    let values: string[] = Object.keys(telemetryData).filter((key: string) => {
      return key.includes(searchText) && typeof telemetryData[key] == "number";
    });
    return values;
  }
  return Object.keys(telemetryData).filter((key) => {
    return typeof telemetryData[key] == "number";
  });
}



export function Get_Keys_Display_Name(telemetryData: telemetryData, searchText?: string): string[] {
  
  if (!!searchText) {
    let values: string[] = Object.keys(telemetryData).filter((key: string) => {
      
      let display_key = ParseTelemtryNames(key);
      if (!display_key ) {
        return false;
      }

      return CheckStrings(searchText.toLowerCase(),display_key.toLowerCase()) && typeof telemetryData[key] == "number";
    });
    return values;
  }
  return Object.keys(telemetryData).filter((key) => {
    return typeof telemetryData[key] == "number";
  });
}

function CheckStrings(str1 : string, str2 : string) : boolean {

  if (str1.length > str2.length) {
    return false;
  }
  for (let i = 0;i < str1.length; i++) {
    if (str1[i] !== str2[i]) {
      return false;
    }
  }
  return true;
}

export function Get_Keys_No_Search(telemetryData: telemetryData): string[] {
  
  return Object.keys(telemetryData).filter((key) => {
    return typeof telemetryData[key] == "number";
  });
}
