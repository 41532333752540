
function dec2bin(dec)
    {
        return (dec >>> 0).toString(2);
    }

const decryptErrorCodes = (errorCode1, errorCode2, errorCode3) =>
{
  var arrayOfErrors = [];
  if (errorCode1 != 0)
  {
    const ErrorCode1 = dec2bin(errorCode1);
    // console.log("ErrorCode1ErrorCode1ErrorCode1",ErrorCode1)
    if (ErrorCode1 % 10 == 1)
    {
      var error = {type: 'error', name: 'Motor temperature high' , codeName: 'Pt1HighTempBit', description: '1.Insufficient cooling of motor \n2. The ambient temperature is above 40°C' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 10) % 10 == 1)
    {
      var error = { type: 'error', name: 'Water temperature high', codeName: 'Pt2HighTempBit', description: '1. Water pump top temperature is above 70°C\n2. Water consumption is too low (pump column has been overheated)' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 100) % 10 == 1)
    {
      var error = { type: 'error', name: 'Pt3HighTempBit', description: 'Default not used' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 1000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Pt1LowTempBit', description: 'Default not used' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 10000) % 10 == 1)
    {
      var error = { type: 'error', name:'Water temperature low', codeName: 'Pt2LowTempBit', description: '1. Water supply temperature is below limit' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 100000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Pt3LowTempBit', description: 'Default not used' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 1000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Tank empty (Inverter)', codeName: 'TankControlLow', description: '1. Insufficient water supply\n2. Water supply valve not open' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 10000000) % 10 == 1)
    {
      var error = { type: 'error', name:'Suction Lance 1, empty (Inverter)', codeName: 'SuctionLance1Empty', description: 'Level of product in chemistry canister for product 1 is insufficient' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 100000000) % 10 == 1)
    {
      var error = { type: 'error', name:'Suction Lance 2, empty (Inverter)', codeName: 'SuctionLance2Empty', description: 'Level of product in chemistry canister for product 2 is insufficient' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode1 / 1000000000) % 10 == 1)
    {
      var error = { type: 'error', name:'No Communication', codeName: 'MbAliveError', description: 'No Communication between Display and Inverter' }
      arrayOfErrors.push(error)
    }
  }
  if (errorCode2 != 0)
  {
    const ErrorCode2 = dec2bin(errorCode2);
    if (ErrorCode2 % 10 == 1)
    {
      var error = { type: 'error', name:'Low Supply pressure', codeName: 'P1_PresssureLowBit', description: 'Insufficient water supply' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 10) % 10 == 1)
    {
      var error = { type: 'error', name:'Low Sensor Signal inlet sensor', codeName: 'P1_SignalLowBit', description: '1. Pressure signal from inlet sensor, out of range' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 100) % 10 == 1)
    {
      var error = { type: 'error', name:'Sensor Load high Inlet Sensor', codeName: 'P1_HighLoad', description: 'Inlet sensor using too much current' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 1000) % 10 == 1)
    {
      var error = { type: 'error', name:'Inlet sensor load low', codeName: 'P1_LowLoad', description: 'Current consumption from inlet sensor low' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 10000) % 10 == 1)
    {
      var error = { type: 'error', name: 'P2_PresssureLowBit', description: '1. Water consumption too high\n2. Inverter is running at max' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 100000) % 10 == 1)
    {
      var error = { type: 'error', name:'Low Sensor Signal outlet sensor', codeName: 'P2_SignalLowBit', description: '1. Pressure signal from outlet sensor, out of range' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 1000000) % 10 == 1)
    {
      var error = { type: 'error', name:'Sensor Load high Outlet Sensor', codeName: 'P2_HighLoad', description: 'Outlet sensor using too much current' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 10000000) % 10 == 1)
    {
      var error = { type: 'error', name:'Outlet sensor load low', codeName: 'P2_LowLoad', description: 'Current consumption from Outlet sensor low' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 100000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'P3_PresssureLowBit', description: '-' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 1000000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'P3_SignalLowBit', description: '-' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 10000000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'P3_HighLoad', description: '-' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 100000000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'P3_LowLoad', description: '-' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 1000000000000) % 10 == 1)
    {
      var error = { type: 'error', name:'Flowswitch using too much current', codeName: 'Flow_HighLoad', description: 'Flowswitch using too much current' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode2 / 10000000000000) % 10 == 1)
    {
      var error = { type: 'error', name:'Flowswitch Load low', codeName: 'Flow_LowLoad', description: 'Flowswitch current consumption is under limit' }
      arrayOfErrors.push(error)
    }

  }
  if (errorCode3 != 0)
  {
    const ErrorCode3 = dec2bin(errorCode3);
    if (ErrorCode3 % 10 == 1)
    {
      var error = { type: 'error', name: 'Current phase 1', codeName: 'currErrPhase1', description: 'Overcurrent on motor phase 1' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 10) % 10 == 1)
    {
      var error = { type: 'error', name: 'Current phase 2', codeName: 'currErrPhase2', description: 'Overcurrent on motor phase 2' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 100) % 10 == 1)
    {
      var error = { type: 'error', name: 'Current phase 3', codeName: 'currErrPhase3', description: 'Overcurrent on motor phase 3' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 1000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Instantaneous motor sum current has been too high', codeName:'sumCurrErr', description: '1. In-balance between motor-currents\n2. Current leaking to Earth, Ground Fault.' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 10000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Supply low. Weak supply', codeName: 'dcLowVoltErr', description: 'Power to inverter too low' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 100000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Supply too high', codeName: 'HighVoltErr', description: 'Supply out of range' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 1000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Current too high', codeName: 'hwOverCurrErr', description: 'Short circuit between motor-current cable and earth or other motor-current cable.' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 10000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Safe Torque off', codeName: 'safeTorqueOff', description: 'Emergency circuit has been opened/broken' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 100000000) % 10 == 1)
    {
      var error = { type: 'error', name:'Inverter Module Temperature too high', codeName: 'moduleTempToHigh', description: '1. Water consumption too high\n2. Ambient temperature too high' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 1000000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'moduleTempHigh (not shown)', description: 'not used' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 10000000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Powerlimit (not shown)', description: 'not used' }
      arrayOfErrors.push(error)
    }
    if (Math.floor(ErrorCode3 / 100000000000) % 10 == 1)
    {
      var error = { type: 'error', name: 'Serial no. or Rev. no. not correct',  codeName: 'hwRevError', description: '1. No.\'s has not beed read correct\n2.No. not written to memory ' }
      arrayOfErrors.push(error)
    }
  }
  return arrayOfErrors
}


const decryptWarningCodes = (warningCode1, warningCode2) =>
{
  var arrayOfWarnings = [];
  if (warningCode1 != 0)
  {
    const WarningCode1 = dec2bin(warningCode1);
    if (WarningCode1 % 10 == 1)
    {
      var warning = { type: 'warning', name: 'Inlet Pressure Low', codeName: 'P1_LowWarningBit', description: 'Insufficient water supply. The machine will keep on working, but the maximum speed of the pump will be reduced until sufficient inlet pressure is present' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 10) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'P1_HighLimitUpBit', description: '-' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 100) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'P1_HighLimitLowBit', description: '-' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 1000) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'Not used', description: 'Not used' }
      // arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 10000) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'P2_HighLimitUpBit', description: '-' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 100000) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'P2_HighLimitLowBit', description: 'Outlet pressure low' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 1000000) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'P3_LowWarningBit', description: '-' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 10000000) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'P3_HighLimitUpBit', description: '-' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 100000000) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'P3_HighLimitLowBit', description: '-' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 1000000000) % 10 == 1)
    {
      var warning = { type: 'warning', name:'High Tank Level (Inverter)', codeName: 'TankControlHigh', description: 'Water supply valve not closed' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 10000000000) % 10 == 1)
    {
      var warning = { type: 'warning', name:'Suction Lance 1, Low (Inverter)', codeName: 'SuctionLance1Low', description: 'Level of product in chemistry canister for product 1 is low' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 100000000000) % 10 == 1)
    {
      var warning = { type: 'warning', name:'Suction Lance 2, low (Inverter)', codeName: 'SuctionLance2Low', description: 'Level of product in chemistry canister for product 2 is low' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 1000000000000) % 10 == 1)
    {
      var warning = { type: 'warning', name:'Inverter Module Temperature High', codeName: 'ModuleHighTempBit', description: '1. Water consumption too high\n2. Ambient temperature too high' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 10000000000000) % 10 == 1)
    {
      var warning = { type: 'warning', name:'Inverter Board Temperature High', codeName: 'BoardHighTempBit', description: '1. Water consumption too high\n2. Ambient temperature too high\n3.Load at Board high' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 100000000000000) % 10 == 1)
    {
      var warning = { type: 'warning', name: 'Powerlimit', description: 'Max power for inverter, power limit activated' }
      arrayOfWarnings.push(warning)
    }
    if (Math.floor(WarningCode1 / 1000000000000000) % 10 == 1)
    {
      var warning = { type: 'warning', name:'Leakage start', codeName: 'Leakage start', description: '1. Booster start condition changed to flow, due to leakage in pipe' }
      arrayOfWarnings.push(warning)
    }
  }
  if (warningCode2 != 0)
  {
    const WarningCode2 = dec2bin(warningCode2);
    if (WarningCode2 % 10 == 1)
    {
      var warning = { type: 'warning', name:'Critical inlet pressure', codeName: 'Dry_run_reset', description: '1. Insufficient water supply\n2. Booster will return to standby mode after 20 sec. If this error occurs 3 times within 20 min. Pump will stop.' }
      arrayOfWarnings.push(warning)
    }
    

  }
  
  return arrayOfWarnings
}

module.exports = {
  decryptErrorCodes,
  decryptWarningCodes
}