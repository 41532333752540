import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { createOwnRoom, createRoom } from "api/room.service";

export const useCreateRoom = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      if (isAdmin) return createRoom(data);
      else return createOwnRoom(data);
    },
  });
};
