import { createReducer } from '@reduxjs/toolkit';
import { addWidgets, removeWidgets, initWidgets } from '../actions/widgetsActions';

var state = [];

const widgetReducer = createReducer(state, (builder) => {
    builder
        .addCase(initWidgets, (state, action) => {
            // var widgets = action.payload
            state.length = 0;
            action.payload.forEach(widget =>{
                state.push(widget)
            })

        })
        .addCase(addWidgets, (state, action) => {
            state.push(action.payload.widget);
        })
        .addCase(removeWidgets, (state, action) => {
            // state.push(action.payload.widget);
            var givenWidget = action.payload.widget;
            var index = state.findIndex(widget => {
                return widget.id == givenWidget.id && widget.MainStation.id == givenWidget.MainStation.id;
            });

            state.splice(index, 1);
        })
})

export default widgetReducer;

