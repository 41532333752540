import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

/**
 * `Breadcrumbs` component is used for navigation purposes.
 * It accepts an array of objects as a prop named `pages`.
 * Each object in the `pages` array represents a breadcrumb link in the navigation chain.
 *
 * @component
 * @example
 * const pages = [
 *   { name: "Projects", url: "projects", current: false },
 *   { name: "Project Nero", url: "projects/nero", current: true },
 * ];
 *
 * return (
 *   <Breadcrumbs pages={pages} />
 * )
 *
 * @param {Object[]} pages - Array of page objects for each breadcrumb.
 * @param {string} pages[].name - The display name of the breadcrumb link.
 * @param {string} pages[].url - The URL that the breadcrumb link points to.
 * @param {boolean} pages[].current - A flag indicating whether this breadcrumb represents the current page.
 */
export default function Breadcrumbs({ pages }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4 mx-2">
        <li>
          <div>
            <Link to="/home" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={index}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {page.current ? (
                <span className="ml-4 text-sm font-medium text-gray-500">{page.name}</span>
              ) : (
                <Link
                  to={page.url}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
