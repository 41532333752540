import React from "react";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { useNavigate, useParams } from "react-router-dom";
//TODO make it so after the account is confirmed to redirect to mobile app
const RegisterSuccess = () => {
  const navigate = useNavigate();
  let params = useParams();

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-nilfisk">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img className="mx-auto mb-6 w-auto" src={nilfiskFoodLogo} alt="Nilfisk Food" />
          <div className="space-y-6">
           
            {/* <div className="bg-white p-6 max-w-sm mx-auto rounded-lg shadow-md text-s"> */}
            <h1 className="text-xl font-semibold text-gray-900">Verify your email</h1>
            <p className="text-gray-500 mb-4">
              We've sent a link to your email address:{" "}
              <span className="text-blue-600 font-semibold">{params.email}</span>
              <br />
              Please follow the link inside to confirm your account.
            </p>
            <button
              type="submit"
              onClick={() => navigate("/login")}
              className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
            >
              Go to login
            </button>
          </div>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
export default RegisterSuccess;
