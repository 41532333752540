import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { UiElement, ValueAdjuster as ValueAdjusterModel } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";

export default function ValueAdjuster({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
  return <InnerValueAdjuster element={element as ValueAdjusterModel}></InnerValueAdjuster>;
}
function InnerValueAdjuster({ element }: { element: ValueAdjusterModel }) {
  const Server = GetServer();
  let [val, setVal] = React.useState<number>(0);
  function SetTelemetry(data: DatedIotData) {
    setVal(data.data[element.telemtryField]);
  }
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);

  function SendSliderCommand(command: string) {
    // Server.SendCommandWithOutResponse(command);
  }

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid item container width="10vw" bgcolor={"white"} borderRadius="1vw">
        <Grid item xs container alignItems="center" justifyContent="center">
          <Typography color={"black"}>{val}</Typography>
        </Grid>
        <Grid item xs borderLeft={1}>
          <IconButton size="medium" style={{ height: "60px", borderRadius: "0%" }}>
            <KeyboardArrowDownIcon fontSize="large"></KeyboardArrowDownIcon>
          </IconButton>
        </Grid>
        <Grid item xs borderLeft={1}>
          <IconButton size="medium" style={{ height: "60px", borderRadius: "0%" }}>
            <KeyboardArrowUpIcon fontSize="large"></KeyboardArrowUpIcon>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
