import apiClient from "./apiClient";

/* ----- Get Methods ----- */

// invitation/invite/own/factory/:FactoryId

/* ----- Post Methods ----- */
export const inviteOwnUser = (FactoryId, data) => {
  return apiClient.post(`invitation/invite/own/factory/${FactoryId}`, data);
};

