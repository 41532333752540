import { useQuery } from "@tanstack/react-query";
import { getOwnFactoryById, getFactoryById } from "api/factory.service";
import { useSelector } from "react-redux";

export const useFactoryByIdQuery = (id) => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useQuery({
    queryKey: ["factory", id],
    queryFn: () => (isAdmin ? getFactoryById(id) : getOwnFactoryById(id)),
    enabled: !!id, // This prevents the query from automatically running if the userId is not provided
  });
};
