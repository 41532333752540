import { Grid } from "@mui/material";
import { ErrorLogEntry } from "../../../Models/Errorlog/ErrorLogEntry";
import GetErrors from "../../../Models/Errorlog/ErrorLogHandler";
import ErrorLogs from "../../../Models/Errorlog/ErrorLogs";
import ErrorLogView from "../../../Views/Errror/Errorlog";

import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { UiElement } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import LeftButton from "../interactive/LeftButton";
import RightButton from "../interactive/RightButton";

export function ErrorLog({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
  return <ErrorLogInner disabled={disabled}></ErrorLogInner>;
}

function ErrorLogInner({ disabled }: { disabled: boolean }) {
  const server = GetServer();

  const [ErrorLog, SetErrorLog] = React.useState<ErrorLogs>(new ErrorLogs([]));
  const [ErrorConfig, SetErrorConfig] = React.useState<Record<string, any>>({});
  const [ErrorEntry, SetErrorEntry] = React.useState<ErrorLogEntry | null>(null);
  useEffect(() => {
    async function getConfig() {
      const machineConfig = await GetServer().GetErrorFile();
      SetErrorConfig(machineConfig);
    }
    getConfig();
  }, []);
  useEffect(() => {
    if (ErrorConfig) {
      server.SendCommand("GetErrorLog", null, (data, err) => {
        if (!err) {
          SetErrorLog(new ErrorLogs(GetErrors(data as any, ErrorConfig)));
        }
      });
    }
  }, [ErrorConfig]);
  useEffect(() => {
    if (ErrorLog) {
      SetErrorEntry(ErrorLog.GetCurrentError());
    }
  }, [ErrorConfig]);
  return (
    <Grid item container direction="column">
      <Grid item xs={10} container direction="row" borderColor={"white"} alignContent="center" justifyContent="center">
        <Grid item container justifyContent="center" alignItems="center" xs={10}>
          <ErrorLogView error={ErrorEntry}></ErrorLogView>
        </Grid>
      </Grid>
      <Grid item container xs={1} direction={"row"}>
        <Grid item xs={3}>

          {ErrorLog.CanGoLeft() ? <LeftButton onclickHandler={() => SetErrorEntry(ErrorLog.GetPrevErrorLog())} disabled={disabled}></LeftButton> : null}
        </Grid>
        <Grid item xs={6}>

          <Typography>
            ErrorLog Index: {ErrorLog.index + 1}/{ErrorLog.errorLogs.length}
          </Typography>
        </Grid>
        <Grid item xs={3}>

          {ErrorLog.CanGoRight() ? <RightButton onclickHandler={() => SetErrorEntry(ErrorLog.GetNextErrorLog())} disabled={disabled}></RightButton> : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
