import { createReducer } from "@reduxjs/toolkit";
import {
  changeDashboard,
  setDashboards,
  addDashboard,
  deleteDashboard,
  renameDashboard,
} from "../actions/dashboardActions";

const initialState = [];

const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeDashboard, (state, action) => {
      state.dashboards = action.payload.dashboard;
    })
    .addCase(setDashboards, (state, action) => {
      console.log("inside redux", action.payload.dashboards);
      console.log("inside redux", action.payload);
      return action.payload.dashboards;
    })
    .addCase(addDashboard, (state, action) => {
      state.push(action.payload.dashboard);
    })
    .addCase(deleteDashboard, (state, action) => {
      const { dashboardIndex } = action.payload;
      state.splice(dashboardIndex, 1);
    })
    .addCase(renameDashboard, (state, action) => {
      const { dashboardIndex, dashboardName } = action.payload;
      console.log("actionpayload", action.payload)
      state[dashboardIndex].name = dashboardName;
    });
});

export default dashboardReducer;
