import { useQueryClient } from "@tanstack/react-query";
import TablePagination from "./TablePagination";
import UserRaw from "./UserRow";
import { LuRefreshCcw } from "react-icons/lu";
import React from "react";

export default function UsersTable({
  data,
  isLoading,
  isFetching,
  isPlaceholderData,
  page,
  setPage,
  pageSize,
  setPageSize,
  totalRows,
  factoryId,
}) {
  const queryClient = useQueryClient();
  const [isRefresh, setIsRefresh] = React.useState(false);

  React.useEffect(() => {
    if (isFetching === false) {
      setIsRefresh(false);
    }
  }, [isFetching]);

  return (
    <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
      <div className="px-4  sm:px-0">
        <div className="px-0">
          <div className=" flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Username
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-right text-sm font-semibold text-gray-900 sm:px-4"
                      >
                        <div className="hs-tooltip inline-block align-middle [--trigger:hover]">
                          <button
                            type="button"
                            className="hs-tooltip-toggle  text-blue-500 hover:text-blue-300 "
                            onClick={(event) => {
                              setIsRefresh(true);
                              queryClient.invalidateQueries([
                                "factory-users",
                                factoryId,
                              ]);
                            }}
                          >
                            <LuRefreshCcw size={25} />
                          </button>
                          <span
                            className="hs-tooltip-content invisible absolute z-10 inline-block rounded-lg border border-gray-700 bg-gray-900 px-4 py-3 text-sm text-white opacity-0 shadow-lg transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 "
                            role="tooltip"
                          >
                            Refresh
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="relative bg-white">
                    {isLoading ? (
                      <tr
                        key="loading row"
                        className="border-t border-gray-200"
                      >
                        <td key="loading row" colSpan={5}>
                          <div className="flex justify-center">
                            <div className="my-4 h-8 w-8 animate-spin rounded-full border-b-4 border-l-4 border-r-4 border-t-4 border-blue-500 border-t-transparent"></div>
                          </div>
                        </td>
                      </tr>
                    ) : data?.length > 0 ? (
                      <>
                        {(isFetching && isPlaceholderData) || isRefresh ? (
                          <div className="absolute inset-0 flex items-center justify-center bg-gray-300 opacity-50">
                            <div className="h-8 w-8 animate-spin rounded-full border-b-4 border-l-4 border-r-4 border-t-4 border-blue-500 border-t-transparent"></div>
                          </div>
                        ) : null}
                        {data.map((user, userIdx) => (
                          <UserRaw
                            key={user.uuid}
                            user={user}
                            userIdx={userIdx}
                          />
                        ))}
                      </>
                    ) : (
                      <tr className="border-t border-gray-200">
                        <td
                          colSpan={5}
                          className=" py-2 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          No users found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <TablePagination
                  page={page}
                  setPage={setPage}
                  totalRows={totalRows}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
