import React from "react";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "@mui/lab/Alert";
import { useForgotPassword } from "hooks/user";

//TODO make it so after the account is confirmed to redirect to mobile app
const ForgotPassword = () => {
  const navigate = useNavigate();
  let params = useParams();
  const [invalidEmailError, setInvalidEmailError] = React.useState(false);

  const { mutate: forgotPassword, isPending, isError, isSuccess, error, status } = useForgotPassword();
  console.log("isLoading", status);
  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const email = formData.get("email");

    const data = { email: email };

    forgotPassword(data);
  };

  if(isSuccess){
	document.getElementById("myform").reset();
  }

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-nilfisk">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">Reset your password</h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img className="mx-auto mb-6 w-auto" src={nilfiskFoodLogo} alt="Nilfisk Food" />
          <form className="space-y-6" id="myform" onSubmit={onSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    invalidEmailError ? "focus:ring-red-500 ring-red-500" : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>
            {isSuccess ? (
              <Alert severity="success" style={{ marginTop: 10 }}>
                We've sent you an email with a link to reset your password.
              </Alert>
            ) : (
              ""
            )}

            {isError || error ? (
              <Alert severity="error" style={{ marginTop: 10 }}>
                Error sending email
              </Alert>
            ) : (
              ""
            )}

            <div>
              <button
                type="submit"
                disabled={isPending}
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isPending && (
                  <div className="">
                    <div className="mr-4 animate-spin rounded-full w-6 h-6 border-t-4 border-r-4 border-b-4 border-l-4 border-blue-300 border-t-transparent"></div>
                  </div>
                )}
                Send reset instructions
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
