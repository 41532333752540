import { useMutation } from '@tanstack/react-query';
import { confirmAccount } from 'api/user.service'; // Adjust the import path as necessary

export const useConfirmAccount = () => {
  console.log("useConfirmAccount")
  return useMutation({
    mutationFn: (token) => {
      return confirmAccount(token)
    },
  })
};