import { Fragment } from "react";
import MachineRow from "./MachineRow";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

export default function RoomRow({ room, machines }) {
  return (
    <Fragment key={room.name}>
      <tr key={room.name} className="border-t border-gray-200">
        <td
          colSpan={5}
          scope="colgroup"
          className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
        >
          {room.name} Room
        </td>
        {/* <td colSpan={2} className="bg-gray-50 text-right">
          <DropDownMenu />
        </td> */}
      </tr>
      {machines.map((machine, machineIdx) => (
        <MachineRow
          key={machineIdx}
          machine={machine}
          machineIdx={machineIdx}
        />
      ))}
    </Fragment>
  );
}

// function DropDownMenu() {
//   return (
//     <Menu as="div" className="relative inline-block text-left">
//       <div>
//         <MenuButton className="flex items-center rounded-full  text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
//           <span className="sr-only">Open options</span>
//           <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
//         </MenuButton>
//       </div>

//       <MenuItems
//         transition
//         className="absolute right-0 sm:right-14 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
//       >
//         <div className="py-1">
//           <MenuItem>
//             <a
//               href="#"
//               className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
//             >
//               Delete
//             </a>
//           </MenuItem>
//           <MenuItem>
//             <a
//               href="#"
//               className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
//             >
//               Edit
//             </a>
//           </MenuItem>
//         </div>
//       </MenuItems>
//     </Menu>
//   );
// }
