import React, { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { UiElement, InfoField as infoFieldModel } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";


export default function InfoField({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
    return <InnerInfoField infoField={element}></InnerInfoField>
}

function InnerInfoField({ infoField }: { infoField: infoFieldModel }) {
    if (infoField.telemtryField) {
        return <TelemetryInfoField infoField={infoField} telemtryField={infoField.telemtryField}></TelemetryInfoField>
    }


    return (
        <Grid item container >
            <Grid item xs={6} container justifyContent="center" bgcolor="white" bottom={0} top="auto" sx={{ borderTopLeftRadius: "2vw", borderBottomLeftRadius: "2vw" }}> <Typography fontSize="0.75em" color="black" textAlign="center" bottom={0}>{infoField.display_name}</Typography>  </Grid>
            <Grid item xs={6} bgcolor="white" borderLeft={1} borderColor="black" sx={{ borderBottomRightRadius: "2vw", borderTopRightRadius: "2vw" }}>  <Typography fontSize="0.75em" color="black" textAlign="center" bottom={0}>{infoField.fieldVal}</Typography> </Grid>

        </Grid >);
    //default case
}



function TelemetryInfoField({ infoField, telemtryField }: { infoField: infoFieldModel, telemtryField: string }) {
    const Server = GetServer();
    let [val, setVal] = React.useState<number | null >(0);
    function SetTelemetry(data: DatedIotData) {

        setVal(data.data[telemtryField]);
    }
    useEffect(() => {
        Server.on_telemtry(SetTelemetry);

        return () => {

            Server.off_telemtry(SetTelemetry);
        }
    }, []);
    if (infoField.fieldUnit) {
        return (<Grid item container >
            <Grid item xs={6} container justifyContent="center" alignContent={"center"} bottom={0} > <Typography fontSize="0.75em" color="white" textAlign="center" variant="button" bottom={0}>{infoField.display_name}</Typography>  </Grid>

            <Grid item xs={3} container height={"1.5vmax"} width={"1.5vmax"} justifyContent="center" alignContent={"center"} bgcolor="white" bottom={0} top="auto"> <Typography fontSize="0.75em" color="darkblue" variant="button" textAlign="center" bottom={0}>{val}</Typography>  </Grid>
            <Grid item xs={3} container justifyContent="start" sx={{ paddingLeft: "0.5vmax" }} alignContent={"center"} bottom={0} top="auto"> <Typography fontSize="0.75em" color="white" textAlign="center" variant="button" bottom={0}>{infoField.fieldUnit}</Typography>  </Grid>

        </Grid >);
    }
    return (
        <Grid item container >
            <Grid item xs={6} container justifyContent="center" bgcolor="white" bottom={0} top="auto" sx={{ borderTopLeftRadius: "2vw", borderBottomLeftRadius: "2vw" }}> <Typography fontSize="0.75em" color="black" textAlign="center" bottom={0}>{infoField.display_name}</Typography>  </Grid>
            <Grid item xs={6} bgcolor="white" borderLeft={1} borderColor="black" sx={{ borderBottomRightRadius: "2vw", borderTopRightRadius: "2vw" }}>  <Typography fontSize="0.75em" color="black" textAlign="center" bottom={0}>{val}</Typography> </Grid>

        </Grid >);


}




