import { ErrorLogEntry } from "./ErrorLogEntry";



export default class ErrorLogs {
    index: number;
    errorLogs: ErrorLogEntry[];
    public constructor(errorLogsdata: ErrorLogEntry[]) {





        this.errorLogs = errorLogsdata
        this.index = 0;


    }



    HasErrors(): boolean {
        return this.errorLogs.length > 0;
    }
    CanGoLeft() {
        return this.index > 0;
    }
    CanGoRight() {
        return this.index < this.errorLogs.length - 1;
    }
    GetCurrentError(): ErrorLogEntry | null {
        return this.errorLogs[this.index];
    }
    public GetNextErrorLog(): ErrorLogEntry {
        if (!this.CanGoRight())
            return this.errorLogs[this.index];
        return this.errorLogs[++this.index];
    }

    GetPrevErrorLog(): ErrorLogEntry {

        if (!this.CanGoLeft())
            return this.errorLogs[this.index];
        return this.errorLogs[--this.index];
    }
    RemoveErrors() {
        this.errorLogs = [];
    }

    GetAllErrors(): ErrorLogEntry[] {
        return this.errorLogs;
    }

    toString() {
        return `${this.index + 1}/${this.errorLogs.length}`;
    }

}