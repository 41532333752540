import { Button, Grid, Typography } from "@mui/material";
import { UiElement } from "../../Models/Menus";

import React from "react";


export default function KeyPad({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
    return (
        <InnerKeyPad keypad={element}></InnerKeyPad>
    )
}


function InnerKeyPad({ keypad }: { keypad: UiElement }) {
    const [Pin, SetPin] = React.useState<number[]>([]);
    function EnterKey(val: number) {
        SetPin((prev) => {
            return [...prev, val];
        })
    }

    function DeleteKey() {
        SetPin((prev) => {
            return prev.slice(0, -1);
        })
    }

    function Ok() {
        //??
    }

    return (
        <Grid item xs container direction={"column"}>
            <Grid item bottom={0} top="auto" sx={{ borderTopLeftRadius: "2vw", b: "2vw", borderBottomLeftRadius: "2vw" }}>
                <Typography fontSize="0.75em" textAlign="center" bottom={0}>{Pin.join("")}</Typography>
            </Grid>
            <KeyPadRow keys={[1, 2, 3]} onClickHandler={EnterKey} ></KeyPadRow>
            <KeyPadRow keys={[4, 5, 6]} onClickHandler={EnterKey} ></KeyPadRow>
            <KeyPadRow keys={[7, 8, 9]} onClickHandler={EnterKey} ></KeyPadRow>
            <Grid item xs container direction={"row"}>
                <Grid item xs> <Button fullWidth variant="outlined" onClick={() => { DeleteKey(); }}>Delete</Button></Grid>
                <Grid item xs> <Button fullWidth variant="outlined" onClick={() => { Ok(); }}>Ok</Button></Grid>
            </Grid>
        </Grid>
    )
}


function KeyPadRow({ keys, onClickHandler }: { keys: [number, number, number], onClickHandler: (val: number) => void }) {
    return (
        <Grid item xs container justifyContent={"center"} direction={"row"}>
            <Grid item xs >
                <Button fullWidth variant="outlined" onClick={() => { onClickHandler(keys[0]); }} >{keys[0]}</Button>
            </Grid>
            <Grid item xs >
                <Button fullWidth variant="outlined" onClick={() => { onClickHandler(keys[1]); }} >{keys[1]}</Button>
            </Grid>
            <Grid item xs >
                <Button fullWidth variant="outlined" onClick={() => { onClickHandler(keys[2]); }}  >{keys[2]}</Button>
            </Grid>
        </Grid>
    )
}