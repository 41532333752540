import React, { useEffect } from "react";
// import { userService } from "shared/services/authentication.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// @ts-ignore
// @ts-ignore

import Alert from "@mui/material/Alert";

import { LiaQuestionCircleSolid } from "react-icons/lia";
import { SendCommand } from "./Models/ServerCommuncation/Server";

export default function SNConnectPage() {
  let { serialNumber } = useParams();
  const navigate = useNavigate();

  const state: {
    errorMessage?: string;
    serviceCode?: string;
    userInactive?: boolean;
  } = useLocation().state;

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(state?.errorMessage || null);

  const warningMessage = null;
  const infoMessage = state?.userInactive
    ? "User has been inactive and got disconnected"
    : null;

  // const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  // const [warningMessage, setWarningMessage] = React.useState<string | null>(
  //   null,
  // );
  // const [infoMessage, setInfoMessage] = React.useState<string | null>(null);

  // useEffect(() => { //!try to not use useeffect if you navigate to this page from the navigator tool together with the error state messages
  //   if (state?.errorMessage) {
  //     setErrorMessage(state.errorMessage);
  //   }
  //   if (state?.userInactive) {
  //     setInfoMessage("User has been inactive and got disconnected");
  //   }
  // }, [state]);

  function IsValidControllerVersion(controller_version: string) {
    let [version, rest] = controller_version.slice(1).split("b");

    let [major, minor, patch] = version.split(".");
    if (parseInt(major) >= 2) {
      return true;
    }
    return false;
  }
  async function checkMachine(
    machineId: string,
    serviceCode: string,
    connectHandler: (machineId: string, serviceCode: string) => void,
  ) {
    console.log(serviceCode);
    try {
      SendCommand(
        "GetSwVersionsJSON",
        machineId,
        serviceCode,
        "",
        async (data, err) => {
          if (!err) {
            if (!IsValidControllerVersion(data.Controller)) {
              setErrorMessage(
                "Machine not supported. Please upgrade machine to version 2.0.0 or later.",
              );
              return;
            }
            connectHandler(machineId, serviceCode);
            return;
          }
          setErrorMessage(err.err);
          setLoading(false);
        },
      );
    } catch (err: any) {
      setErrorMessage("server is offline");
      setLoading(false);
    }
  }

  function getMachineCode(formData: FormData): string | null {
    const codeNumber1 = formData.get("code-number-1");
    const codeNumber2 = formData.get("code-number-2");
    const codeNumber3 = formData.get("code-number-3");
    const codeNumber4 = formData.get("code-number-4");
    if (codeNumber1 && codeNumber2 && codeNumber3 && codeNumber4) {
      return (
        codeNumber1.toString() +
        codeNumber2.toString() +
        codeNumber3.toString() +
        codeNumber4.toString()
      );
    }
    return null;
  }

  function getServiceCode(formData: FormData): string | null {
    const codeNumber1 = formData.get("service-code-number-1");
    const codeNumber2 = formData.get("service-code-number-2");
    const codeNumber3 = formData.get("service-code-number-3");
    const codeNumber4 = formData.get("service-code-number-4");
    if (codeNumber1 && codeNumber2 && codeNumber3 && codeNumber4) {
      return (
        codeNumber1.toString() +
        codeNumber2.toString() +
        codeNumber3.toString() +
        codeNumber4.toString()
      );
    }
    return null;
  }

  const onSubmit = (e: any) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const serialNumber = formData.get("serial-number");
    const serviceCode = getServiceCode(formData);

    const authCode = getMachineCode(formData);
    if (authCode === null) {
      setErrorMessage("Please enter a 4 digit authentication code.");
      return;
    }
    if (serviceCode === null) {
      setErrorMessage("Please enter a 4 digit service code.");
      return;
    }
    setLoading(true);
    setErrorMessage(null);
    checkMachine(
      serialNumber!.toString(),
      serviceCode!.toString(),
      (machineId, serviceCode) => {
        SendCommand(
          "SetNavigatorMode",
          machineId,
          serviceCode,
          authCode,
          (ok: any, err: any) => {
            setLoading(false);
            if (!err) {
              navigate("/navigator/control-panel", {
                state: { serialNumber: machineId, serviceCode: serviceCode },
              });

              return;
            }
            if (err === "Wrong service Code") {
              setErrorMessage("Service code is wrong");
              return;
            }

            setErrorMessage("Authentication code is wrong");
          },
        );
      },
    );
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center sm:px-6 sm:py-12 lg:px-8">
      <div className="sm:mx-auto sm:mt-10 sm:w-full sm:max-w-[450px]">
        <form
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl "
          onSubmit={onSubmit}
        >
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Navigator Tool
            </h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              Enter the machine's serial number and the authentication code
              shown on the display, to connect and navigate remotely.
            </p>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:p-8">
            {/* <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"> */}
            <div className="">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Serial Number
              </label>
              <div className="mt-2">
                <input
                  id="serial-number"
                  name="serial-number"
                  type="number"
                  defaultValue={serialNumber}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mt-6 ">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Authentication code
                {/* <!-- Popover --> */}
                <div className="hs-tooltip inline-block align-middle [--trigger:hover]">
                  <LiaQuestionCircleSolid
                    className={" ml-2 text-blue-500"}
                    size={19}
                  />

                  <span
                    //@ts-ignore
                    class="hs-tooltip-content invisible absolute z-10 inline-block rounded-lg border bg-white px-4 py-3 text-sm text-gray-600 opacity-0 shadow-md transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-400"
                    role="tooltip"
                  >
                    Explanation
                  </span>
                </div>
                {/* <!-- End Popover --> */}
              </label>
              <div className="mt-2 ">
                <div
                  className="flex justify-center space-x-3"
                  data-hs-pin-input='{"availableCharsRE": "^[0-9]+$"}'
                >
                  <input
                    id="code-number-1"
                    name="code-number-1"
                    type="number"
                    className="block w-[38px] rounded-md border-gray-200 text-center text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none "
                    placeholder="⚬"
                    data-hs-pin-input-item
                    defaultValue={""}
                  />
                  <input
                    name="code-number-2"
                    id="code-number-2"
                    type="number"
                    className="block w-[38px] rounded-md border-gray-200 text-center text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                    defaultValue={""}
                  />
                  <input
                    name="code-number-3"
                    id="code-number-3"
                    type="number"
                    className="block w-[38px] rounded-md border-gray-200 text-center text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                    defaultValue={""}
                  />
                  <input
                    name="code-number-4"
                    id="code-number-4"
                    type="number"
                    className="block w-[38px] rounded-md border-gray-200 text-center text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 ">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Service code
                {/* <!-- Popover --> */}
                <div className="hs-tooltip inline-block align-middle [--trigger:hover]">
                  <LiaQuestionCircleSolid
                    className={" ml-2 text-blue-500"}
                    size={19}
                  />

                  <span
                    //@ts-ignore

                    class="hs-tooltip-content invisible absolute z-10 inline-block rounded-lg border bg-white px-4 py-3 text-sm text-gray-600 opacity-0 shadow-md transition-opacity hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-400"
                    role="tooltip"
                  >
                    Explanation
                  </span>
                </div>
                {/* <!-- End Popover --> */}
              </label>
              <div className="mt-2 ">
                <div
                  className="flex justify-center space-x-3"
                  data-hs-pin-input='{"availableCharsRE": "^[0-9]+$"}'
                >
                  <input
                    id="service-code-number-1"
                    name="service-code-number-1"
                    type="number"
                    className="block w-[38px] rounded-md border-gray-200 text-center text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none "
                    placeholder="⚬"
                    data-hs-pin-input-item
                    defaultValue={""}
                  />
                  <input
                    name="service-code-number-2"
                    id="service-code-number-2"
                    type="number"
                    className="block w-[38px] rounded-md border-gray-200 text-center text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                    defaultValue={""}
                  />
                  <input
                    name="service-code-number-3"
                    id="service-code-number-3"
                    type="number"
                    className="block w-[38px] rounded-md border-gray-200 text-center text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                    defaultValue={""}
                  />
                  <input
                    name="service-code-number-4"
                    id="service-code-number-4"
                    type="number"
                    className="block w-[38px] rounded-md border-gray-200 text-center text-sm focus:border-blue-500 focus:ring-blue-500 disabled:pointer-events-none disabled:opacity-50 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    placeholder="⚬"
                    data-hs-pin-input-item
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
            <div className="pt-6">
              {errorMessage ? (
                <Alert severity="error">{errorMessage}</Alert>
              ) : null}
            </div>
            <div className="pt-6">
              {warningMessage ? (
                <Alert severity="info"> {infoMessage}</Alert>
              ) : null}
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            {loading ? (
              <div
                className="inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : null}
            <button
              type="submit"
              disabled={loading}
              className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:pointer-events-none disabled:opacity-50"
            >
              Connect
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
