import { useQuery } from "@tanstack/react-query";
import { getMachineBySN } from "api/machine.service";
import { useSelector } from "react-redux";
import { getOwnMachineBySN } from "../../api/machine.service";

export const useMachineBySNQuery = (serialNumber) => {
  const myAccount = useSelector((state) => state.user.user);
  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useQuery({
    queryKey: ["machine", serialNumber, "details"],
    queryFn: () =>
      isAdmin ? getMachineBySN(serialNumber) : getOwnMachineBySN(serialNumber),
    enabled: !!serialNumber,
  });
};
