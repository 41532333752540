import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";

export default function TablePagination({ page, setPage, pageSize, setPageSize, totalRows }) {
  const totalPages = Math.ceil(totalRows / pageSize);
  const [visiblePages, setVisiblePages] = useState([]);

  useEffect(() => {
    const calculateVisiblePages = () => {
      const maxVisibleButtons = 3; // Adjust as needed
      const pages = [];

      let startPage = Math.max(1, page - Math.floor(maxVisibleButtons / 2));
      let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

      if (endPage - startPage < maxVisibleButtons - 1) {
        startPage = Math.max(1, endPage - maxVisibleButtons + 1);
      }

      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push("...");
        }
        pages.push(totalPages);
      }

      setVisiblePages(pages);
    };

    calculateVisiblePages();
  }, [page, pageSize, totalRows, totalPages]);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setPage(pageNumber);
    }
  };

  const nextPage = () => {
    goToPage(page + 1);
  };

  const prevPage = () => {
    goToPage(page - 1);
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={prevPage}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
            page === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
          }`}
          disabled={page === 1}
        >
          Previous
        </button>
        <button
          onClick={nextPage}
          className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
            page === totalPages ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
          }`}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{Math.min((page - 1) * pageSize + 1, totalRows)}</span> to{" "}
            <span className="font-medium">{Math.min(page * pageSize, totalRows)}</span> of{" "}
            <span className="font-medium">{totalRows}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={prevPage}
              className={`w-9 relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300  ${
                page === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
              }`}
              disabled={page === 1}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {visiblePages.map((pageNumber, index) => (
              <button
                key={index}
                onClick={() => goToPage(pageNumber)}
                className={`w-10 relative inline-flex items-center justify-center px-4 py-2 text-sm font-semibold ${
                  pageNumber === page
                    ? "bg-blue-500 text-white focus:outline-none"
                    : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                }`}
              >
                {pageNumber}
              </button>
            ))}
            <button
              onClick={nextPage}
              className={`w-9 relative inline-flex items-center justify-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                page === totalPages ? "cursor-not-allowed opacity-50" : "hover:bg-gray-50"
              }`}
              disabled={page === totalPages}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
