import { Interface } from "readline";
//@ts-ignore
import { getSocket, initiateSocket, leaveRoom } from "shared/services/socket.service";
import { DatedHistoricIotData, DatedIotData, IotData } from "./serverInterFace";
//@ts-ignore  
import { Socket, io } from "socket.io-client";
import { ThirtyFpsSharp } from "@mui/icons-material";
import { FactorTelemetryValue, FactorValue } from "../../Views/DashBoard/DashBoardParsing";
type Events = {
  eventName: string;
  listerners: Listener[];
};

class Listener {
  callBack: (parsed_data: DatedIotData) => void;
  constructor(callback: (parsed_data: DatedIotData) => void) {
    this.callBack = callback;
  }
}

type EventsLast10Min = {
  eventName: string;
  listerners: ListenerLast10Min[];
};

class ListenerLast10Min {
  callBack: (parsed_data: DatedIotData[]) => void;
  constructor(callback: (parsed_data: DatedIotData[]) => void) {
    this.callBack = callback;
  }
}

export interface ISocket {
  getSocket(): WebSocket | Socket;
  on(eventName: string, cb: (parsed_data: DatedIotData) => void): void;
  on_last_10_min(eventName: string, cb: (parsed_data: DatedIotData[]) => void): void;
  off(eventName: string, cb: (parsed_data: DatedIotData) => void): void;
  send(command: string): void;
  ListenForDisconnect(disconnect_handler: (err: DisconnectErrorType) => void): void;
  off_last_10_min(eventName: string, cb: (parsed_data: DatedIotData[]) => void): void;
  setLast10Min(data: DatedIotData[]): void;
  disconnect(): void;
  disconnect_no_error(): void
  toogleListeningForDisconnect(): void;
  isRebooting(): boolean;
}

export class WsSocket implements ISocket {
  socket: WebSocket;
  cbs: Record<string, Events>;
  cbs10Min: Record<string, EventsLast10Min>;
  machineId: string;
  dataOverTime: DatedIotData[];
  latestData: DatedIotData | null;

  constructor(machineId: string) {
    this.socket = new WebSocket("ws://127.0.0.1:8000");
    this.dataOverTime = [];
    this.set_socket_on_open(machineId);
    this.machineId = machineId ?? "";
    this.cbs = {};
    this.cbs10Min = {};
    this.latestData = null;
  }
  disconnect(): void {
  }
  isRebooting(): boolean {
    return false;
  }
  setLast10Min(data: DatedIotData[]): void {
    this.dataOverTime = data;
  }

  set_socket_on_open(machineId: string) {
    this.socket.addEventListener("open", () => {
      this.socket.send("subscribe:" + machineId);

      this.initiateEventListener(machineId);
    });
  }

  initiateEventListener(machineId: string) {
    this.socket.addEventListener("message", (event: MessageEvent<string>) => {
      let [event_name, data] = event.data.split("##", 2);
      let parsed_data: DatedIotData = JSON.parse(data);
      this.dataOverTime.push(parsed_data);
      this.dataOverTime = filterData(this.dataOverTime);
      if (machineId != machineId) {
        return;
      }
      if (this.cbs[event_name]) {
        for (const listener of this.cbs[event_name]?.listerners) {
          listener.callBack(parsed_data);
        }
      }
      if (this.cbs10Min[event_name]) {
        for (const listener of this.cbs10Min[event_name]?.listerners) {
          listener.callBack(this.dataOverTime);
        }
      }
    });
  }


  ListenForDisconnect(disconnect_handler: (err: DisconnectErrorType) => void) {

    this.socket.addEventListener("close", () => {
      disconnect_handler("Server");
    });
    this.socket.addEventListener("error", () => {
      disconnect_handler("Server");
    });
  }
  getSocket(): WebSocket {
    return this.socket;
  }
  disconnect_no_error(): void {

  }

  on(eventName: string, cb: (parsed_data: DatedIotData) => void) {
    if (eventName in this.cbs) {
      this.cbs[eventName].listerners.push(new Listener(cb));
      return;
    }
    this.cbs[eventName] = {
      eventName: eventName,
      listerners: [new Listener(cb)],
    };
  }
  on_last_10_min(eventName: string, cb: (parsed_data: DatedIotData[]) => void): void {
    if (eventName in this.cbs10Min) {
      this.cbs10Min[eventName].listerners.push(new ListenerLast10Min(cb));
      return;
    }
    this.cbs10Min[eventName] = {
      eventName: eventName,
      listerners: [new ListenerLast10Min(cb)],
    };
  }

  off_last_10_min(eventName: string, cb: (parsed_data: DatedIotData[]) => void): void {
    let idx = this.cbs10Min[eventName].listerners.findIndex((listener) => listener.callBack == cb);
    if (idx == -1) {
      return;
    }

    this.cbs[eventName].listerners.splice(idx, 1);
    if (this.cbs[eventName].listerners.length == 0) {
      delete this.cbs[eventName];
    }
  }
  off(eventName: string, cb: (parsed_data: any) => void) {
    let idx = this.cbs[eventName].listerners.findIndex((listener) => listener.callBack == cb);
    if (idx == -1) {
      return;
    }

    this.cbs[eventName].listerners.splice(idx, 1);
    if (this.cbs[eventName].listerners.length == 0) {
      delete this.cbs[eventName];
    }
  }
  toogleListeningForDisconnect() {

  }

  send(command: string): void {
    while (this.socket.readyState != 1) { }
    this.socket.send(command);
  }
}

export class IoSocket implements ISocket {
  socket: Socket;
  dataOverTime: DatedIotData[];
  cbs: Record<string, Events>;
  machineId: string;
  cbs10Min: Record<string, EventsLast10Min>;
  latestData: DatedIotData | null;
  disconnect_handler: ((err: DisconnectErrorType) => void) | null
  isRebootingFlag: boolean
  interval: ReturnType<typeof setTimeout> | null

  constructor(machineId: string) {
    initiateSocket(machineId);
    this.machineId = machineId;
    this.socket = getSocket();
    this.dataOverTime = [];
    this.cbs = {};
    this.cbs10Min = {};
    this.latestData = null;
    this.disconnect_handler = null;
    this.isRebootingFlag = false;
    this.initiateEventListener(`telemetry-${machineId}`);
    this.interval = null;

    this.listenForNoConnection();
  }
  getSocket(): Socket {
    return this.socket;
  }
  disconnect_no_error(): void {
    this.disconnect_handler?.("NoError");
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    leaveRoom(this.machineId);
  }
  disconnect(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    leaveRoom(this.machineId);
    
  }
  toogleListeningForDisconnect() {
    this.isRebootingFlag = !this.isRebootingFlag;
  }
  listenForNoConnection() {
    var self = this;
    let interval = setInterval(() => {
      let now = Date.now();


      if (self.latestData !== null && ((self.latestData.date + (30 * 1000)) < now)) {

        self.disconnect_handler?.("Machine");
        clearInterval(interval);

      }

    }, 1000);

    this.interval = interval;

  }
  initiateEventListener(machineId: string) {
    this.socket.on(machineId, (data: any) => {
      console.log(data);

      let iot_data: any = JSON.parse(data);
      let parsed_data: DatedIotData = { data: iot_data.IotData, date: Date.now() };

      parsed_data = Object.keys(parsed_data.data).reduce((factored_data, key) => {
        factored_data.data[key] = FactorTelemetryValue(key, parsed_data.data[key]);

        return factored_data;
      }, { data: parsed_data.data, date: parsed_data.date });
      console.log(parsed_data);

      this.dataOverTime.push(parsed_data);
      this.dataOverTime = filterData(this.dataOverTime);
      this.latestData = parsed_data;
      if (machineId != machineId) {
        return;
      }

      if (this.cbs[machineId]) {
        for (const listener of this.cbs[machineId]?.listerners) {
          console.log(this.latestData);

          listener.callBack(parsed_data);
        }
      }
      if (this.cbs10Min[machineId]) {
        for (const listener of this.cbs10Min[machineId]?.listerners) {
          listener.callBack(this.dataOverTime);
        }
      }
    });
  }

  setLast10Min(data: DatedIotData[]): void {
    this.dataOverTime = data;
  }

  on(eventName: string, cb: (parsed_data: DatedIotData) => void) {
    console.log("in here");
    if (eventName in this.cbs) {
      this.cbs[eventName].listerners.push(new Listener(cb));
      return;
    }
    this.cbs[eventName] = {
      eventName: eventName,
      listerners: [new Listener(cb)],
    };
  }
  on_last_10_min(eventName: string, cb: (parsed_data: DatedIotData[]) => void): void {
    if (eventName in this.cbs10Min) {
      this.cbs10Min[eventName].listerners.push(new ListenerLast10Min(cb));
      return;
    }
    this.cbs10Min[eventName] = {
      eventName: eventName,
      listerners: [new ListenerLast10Min(cb)],
    };
  }

  off_last_10_min(eventName: string, cb: (parsed_data: DatedIotData[]) => void): void {
    if (!this.cbs10Min[eventName]) {
      return;
    }
    let idx = this.cbs10Min[eventName].listerners.findIndex((listener) => listener.callBack == cb);

    if (idx == -1) {
      return;
    }

    this.cbs10Min[eventName].listerners.splice(idx, 1);
    if (this.cbs10Min[eventName].listerners.length == 0) {
      delete this.cbs10Min[eventName];
    }
  }
  off(eventName: string, cb: (parsed_data: any) => void) {
    if (!this.cbs[eventName]) {
      return;
    }
    let idx = this.cbs[eventName].listerners.findIndex((listener) => listener.callBack == cb);
    if (idx == -1) {
      return;
    }

    this.cbs[eventName].listerners.splice(idx, 1);
    if (this.cbs[eventName].listerners.length == 0) {
      delete this.cbs[eventName];
    }
  }

  ListenForDisconnect(disconnect_handler: (err: DisconnectErrorType) => void): void {
    this.disconnect_handler = disconnect_handler;
    this.socket.on("disconnect", () => {
      disconnect_handler("Server");
    });
  }
  send(command: string): void { }
  isRebooting(): boolean {
    return this.isRebootingFlag;
  }
}

export function filterData(iotData: { date: number }[]): any[] {
  let TenMinago = Date.now() - 10 * 1000 * 60;

  return iotData.filter((data) => {
    return data.date > TenMinago;
  });
}

export type DisconnectErrorType = "Server" | "Machine" | "NoError";
