import React from "react";
import {
  EmptyField as EmptyFieldModel,
  UiElement,
} from "../../../Models/Menus";

export default function EmptyField({
  element,
}: {
  element: UiElement;
}): JSX.Element {
  return <InnerEmptyField emptyField={element}></InnerEmptyField>;
}

function InnerEmptyField({ emptyField }: { emptyField: EmptyFieldModel }) {
  return <></>;
}
