import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { getOwnUsersByFactoryId, getUsersByFactoryId } from "api/user.service";
import { useSelector } from "react-redux";

export const useUsersByFactoryIdQuery = (factoryId, data) => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin = myAccount && (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");
  return useQuery({
    queryKey: ["factory-users", factoryId, data?.page, data?.pageSize],
    queryFn: () => (isAdmin ? getUsersByFactoryId(factoryId, data) : getOwnUsersByFactoryId(factoryId, data)),
    staleTime: 1000,
    placeholderData: keepPreviousData,
  });
};
