import { useQuery } from "@tanstack/react-query";
import { pingMachine } from "api/machine.service";

export const usePingMachine = (serialNumber) => {
  return useQuery({
    queryKey: ["machine", serialNumber, "status"],
    queryFn: () => pingMachine(serialNumber),
    enabled: !!serialNumber,
  });
};
