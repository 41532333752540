import { useMutation } from "@tanstack/react-query";
import { inviteOwnUser } from "api/invitation.service";

export const useSendInvitation = () => {
  return useMutation({
    mutationFn: (data) => {
      return inviteOwnUser(data.FactoryId, data);
    },
  });
};
