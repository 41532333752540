import { useState } from "react";
import moment from "moment";
import TextInputForm from "./TextInputForm";
import { useMyAccountQuery } from "hooks/user";
import TextInputPhoneForm from "./TextInputPhoneForm ";
import DeleteAccountDialog from "components/DeleteAccountDialog";
import { enqueueSnackbar } from "notistack";

export default function General() {
  const [openDialog, setOpenDialog] = useState(false);
  const { data, isLoading } = useMyAccountQuery();
  let myAccount = data?.data.user;

  return (
    <>
      <DeleteAccountDialog open={openDialog} setOpen={setOpenDialog} />
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Profile
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <TextInputForm
              name="Name"
              autoComplete="name"
              defaultValue={`${myAccount?.firstName || ""} ${myAccount?.lastName || ""}`}
              type="text"
              required={true}
              isLoading={isLoading}
            />
            {/* ------- username ------- */}
            <TextInputForm
              name="Username"
              autoComplete="username"
              defaultValue={`${myAccount?.username || ""}`}
              type="text"
              required={true}
              isLoading={isLoading}
            />
            {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                              <dt className="text-sm font-medium text-gray-500">Photo</dt>
                              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                  <img
                                    className="h-8 w-8 rounded-full"
                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                    alt=""
                                  />
                                </span>
                                <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                                  <button
                                    type="button"
                                    className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                                  <span className="text-gray-300" aria-hidden="true">
                                    |
                                  </span>
                                  <button
                                    type="button"
                                    className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Remove
                                  </button>
                                </span>
                              </dd>
                            </div> */}
            {/* ------- email ------- */}
            {/* //TODO this method must be improved, when the email is changed the user must confirm the new email with a code sent to the new email */}
            <TextInputForm
              name="Email"
              autoComplete="email"
              defaultValue={`${myAccount?.email || ""}`}
              type="email"
              required={true}
              isLoading={isLoading}
            />
            {/* ------- position ------- */}
            {/* <TextInputForm
              name="Position"
              autoComplete="position"
              defaultValue={`${myAccount?.position || ""}`}
              type="text"
              required={false}
            /> */}
            {/* ------- phone ------- */}
            {/* //TODO finish this phone number validation */}
            <TextInputPhoneForm
              name="Phone"
              autoComplete="phone"
              defaultValue={`${myAccount?.phone || ""}`}
              type="tel"
              required={false}
              isLoading={isLoading}
              editDisabled={false}
            />
            <TextInputForm
              name="Joined"
              autoComplete="joined"
              defaultValue={`${moment(myAccount?.createdAt || "2000-1-1").format("DD MMM YYYY")}`}
              type="text"
              required={false}
              isLoading={isLoading}
              editDisabled={true}
              canEdit={false}
            />
            {myAccount?.RoleId !== 3 && (
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5">
                <dt className="text-sm font-medium text-gray-500">
                  Access Level
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">{myAccount?.Role?.name}</span>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>

      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Account
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Manage how information is displayed on your account.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <TextInputForm
              name="Language"
              autoComplete="language"
              defaultValue={`${myAccount?.language || ""}`}
              type="text"
              required={false}
              isLoading={isLoading}
              editDisabled={true}
            />
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <dt className="self-center text-sm font-medium text-gray-500">
                Delete account
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  No longer want to use our service? You can delete your account
                  here. This action is not reversible. All information related
                  to this account will be deleted permanently.
                </p>
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <div className="flex-1 items-start md:col-span-2" o>
                  <div className="flex items-center justify-start gap-x-6 ">
                    <button
                      type="submit"
                      onClick={(e) => {
                        setOpenDialog(true);
                        // enqueueSnackbar('Your report is ready', {
                        //   variant: 'success',
                        //   // persist: true,
                        // })
                      }}
                      className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:opacity-50 disabled:hover:bg-red-500"
                    >
                      Yes, delete my account
                    </button>
                  </div>
                </div>
              </dd>
            </div>
            {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500">Date format</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="flex-grow">DD-MM-YYYY</span>
                <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                  <button
                    type="button"
                    className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Update
                  </button>
                  <span className="text-gray-300" aria-hidden="true">
                    |
                  </span>
                  <button
                    type="button"
                    className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Remove
                  </button>
                </span>
              </dd>
            </div>
            <Switch.Group as="div" className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                Automatic timezone
              </Switch.Label>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <Switch
                  checked={automaticTimezoneEnabled}
                  onChange={setAutomaticTimezoneEnabled}
                  className={classNames(
                    automaticTimezoneEnabled ? "bg-blue-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-auto"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      automaticTimezoneEnabled ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </dd>
            </Switch.Group>
            <Switch.Group
              as="div"
              className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200 sm:py-5"
            >
              <Switch.Label as="dt" className="text-sm font-medium text-gray-500" passive>
                Auto-update applicant data
              </Switch.Label>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <Switch
                  checked={autoUpdateApplicantDataEnabled}
                  onChange={setAutoUpdateApplicantDataEnabled}
                  className={classNames(
                    autoUpdateApplicantDataEnabled ? "bg-blue-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-auto"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      autoUpdateApplicantDataEnabled ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </dd>
            </Switch.Group> */}
          </dl>
        </div>
      </div>
    </>
  );
}
