export default {
    'Delete User': {
        en: 'Delete User',
        // dk: 'Handlinger',
    },
    'A factory with this address already exist': {
        en: 'A factory with this address already exist'
    },
    'Actions': {
        en: 'Actions',
        dk: 'Handlinger',
    },
    'Add New Company': {           //Set in user/TableList and company/List when adding new language 
        en: 'Add New Company',
        dk: 'Tilf' + String.fromCharCode(248) + 'j Ny Fabrik',
    },
    'Add New Factory': {            //Set in factory/ListofFactories when adding new language 
        en: 'Add New Factory',
        dk: 'Tilf' + String.fromCharCode(248) + 'j Ny Fabrik',
    },
    'Add New User': {
        en: 'Add New User',
        dk: 'Tilf' + String.fromCharCode(248) + 'j Ny Bruger',
    },
    'Add New Dashboard': {           //Set in user/TableList and company/List when adding new language 
        en: 'Add New Dashboard',
        dk: 'Tilf' + String.fromCharCode(248) + 'j Tavle',   //Instrumentbr' + String.fromCharCode(0x00E6) +'t'
    },
    'Add Widget': {
        en: 'Add Widget',
        dk: 'Tilf' + String.fromCharCode(248) + 'j Instrument',
    },
    'Address': {
        en: 'Address',
        dk: 'Adresse',
    },
    'address': {
        en: 'address',
        dk: 'adresse',
    },
    'Administrator': {
        en: 'Administrator',
        dk: 'Administrator',
    },
    'Are you sure you want to delete this company?': {
        en: 'Are you sure you want to delete this company?',
        dk: 'Er du sikker p� at du vil slette dette firma',
    },
    'Area Chart': {
        en: 'Area Chart',
        dk: 'Omr' + String.fromCharCode(229) + 'dediagram',
    },
    'Authentication': {
        en: 'Authentication',
        dk: 'Autenticitet',
    },
    'Authentication information': {
        en: 'Authentication information',
        dk: 'Autenticitetsinformation',
    },
    'Authentication_information': {
        en: 'Authentication information',
        dk: 'Autenticitetsinformation',
    },
    'Back': {
        en: 'Back',
        dk: 'Tilbage',
    },
    'Bar Chart': {
        en: 'Bar Chart',
        dk: 'S' + String.fromCharCode(248) + 'jlediagram',
    },
    'Cancel': {
        en: 'Cancel',
        dk: 'Afbryd',
    },
    'Change the name of the dashboard or delete it.': {
        en: 'Change the name of the dashboard or delete it.',
        dk: 'Skift navnet p' + String.fromCharCode(229) + ' tavlen eller slet den.',
    },
    'Chart': {
        en: 'Chart',
        dk: 'Graf',
    },
    'Chemical Status': {
        en: 'Chemical Status',
        dk: 'Kemisk Status',
    },
    'Choose a chart to represent the data with:': {
        en: 'Choose a chart to represent the data with:',
        dk: 'V' + String.fromCharCode(230) + 'lg en graf til at repr' + String.fromCharCode(230) + 'sentere data med:',
    },
    'Choose a country code': {
        en: 'Choose a country code',
        dk: 'V' + String.fromCharCode(230) + 'lg en landekode',
    },
    'Choose a factory from the table': {
        en: 'Choose a factory from the table.',
        dk: 'V' + String.fromCharCode(230) + 'lg en fabrik fra tabellen.',
    },
    'City': {
        en: 'City',
        dk: 'By',
    },
    'Cleaning Status': {
        en: 'Cleaning Status',
        dk: 'Reng�ringsstatus',
    },
    '{companyName} User': {
        en: '{companyName} User',
        dk: '{companyName} Bruger',
    },
    '{companyName} Users': {
        en: '{companyName} Users',
        dk: '{companyName} Brugere',
    },
    'Companies': {
        en: 'Companies',
        dk: 'Firmaer',
    },
    'Company': {
        en: 'Company',
        dk: 'Firma',
    },
    'Company Name': {
        en: 'Company Name',
        dk: 'Firmanavn',
    },
    'Confirm password': {
        en: 'Confirm password',
        dk: 'Bekr' + String.fromCharCode(230) + 'ft kodeord',
    },
    'Country': {
        en: 'Country',
        dk: 'Land',
    },
    'Create': {
        en: 'Create',
        dk: 'Byg',
    },
    'Create Dashboard': {
        en: 'Create Dashboard',
        dk: 'Byg Tavle,'        //'Byg Instrumentbr + String.fromCharCode(0x00E6) + 't'
    },
    'Create New Dashboard': {
        en: 'Create New Dashboard',
        dk: 'Byg Ny Tavle',   //tidligere Nyt Instrumentbr' + String.fromCharCode(0x00E6) +'t'
    },
    'Create Widget': {
        en: 'Create Widget',
        dk: 'Byg Instrument',   //tidligere Nyt Instrumentbr' + String.fromCharCode(0x00E6) +'t'
    },
    'Current Factory': {
        en: 'Current Factory',
        dk: 'Nuv' + String.fromCharCode(230) + 'rende arbejdsplads',
    },
    'Dashboard': {
        en: 'Dashboard',
        dk: 'Tavle',
    },
    'Delete': {
        en: 'Delete',
        dk: 'Slet',
    },
    'Dial Gauge': {
        en: 'Dial Gauge',
        dk: 'M' + String.fromCharCode(229) + 'leur',
    },
    'Duration of last cleaning': {
        en: 'Duration of last cleaning',
        dk: 'Sidste reng' + String.fromCharCode(248) + 'rings varighed',
    },
    'e.g Widget1': {
        en: 'e.g Widget1',
        dk: 'f.eks. Widget1',
    },
    'Edit': {
        en: 'Edit',
        dk: 'Rediger',
    },
    'Email': {
        en: 'Email',
        dk: 'E-mail',
    },
    'Email already exists': {
        en: 'E-mail already exists',
        dk: 'Denne e-mailadresse eksistere allerede',
    },
    'Email_already_exists': {
        en: 'E-mail already exists',
        dk: 'Denne e-mailadresse eksistere allerede',
    },
    'Edit Dashboard': {
        en: 'Edit Dashboard',
        dk: 'Rediger Tavle',
    },
    'Factory': {
        en: 'Factory',
        dk: 'Fabrik',
    },
    'Factories': {
        en: 'Factories',
        dk: 'Fabrikker',
    },
    'Factory address': {
        en: 'Factory address',
        dk: 'Fabriksadresse',
    },
    'First Name': {
        en: 'First Name',
        dk: 'Fornavn',
    },
    'History and Analysis': {
        en: 'History and Analysis',
        dk: 'Historie og Analyse',
    },
    'Identity': {
        en: 'Identity',
        dk: 'Identitet',
    },
    'Home': {
        en: 'Home',
        dk: 'Hjem',
    },
    'Language': {
        en: 'Language',
        dk: 'Sprog',
    },
    'Last Name': {
        en: 'Last Name',
        dk: 'Efternavn',
    },
    'Line Chart': {
        en: 'Line Chart',
        dk: 'Linjediagram',
    },
    'Line Chart Hz': {
        en: 'Line Chart Frequency & Power',
        dk: 'Linjediagram Hz',
    },
    'Machine Status': {
        en: 'Machine Status',
        dk: 'Maskinestatus',
    },
    'Nilfisk Connect': {
        en: 'Nilfisk Connect',
        dk: 'Nilfisk Tavle',
    },
    'Name': {
        en: 'Name',
        dk: 'Navn',
    },
    'Name of the widget:': {
        en: 'Name of the widget:',
        dk: 'Instrumentets navn',
    },
    'One or more fields have an error': {
        en: 'One or more fields have an error. Please check and try again.',
        dk: 'Et eller flere felter har en fejl. Tjek felterne og pr' + String.fromCharCode(248) + 'v igen.',
    },
    'One_or_more_fields_have_an_error_etc...': {
        en: 'One or more fields have an error. Please check and try again.',
        dk: 'Et eller flere felter har en fejl. Tjek felterne og pr�v igen.',
    },
    'Next': {
        en: 'Next',
        dk: 'N' + String.fromCharCode(230) + 'ste',
    },
    'Password': {
        en: 'Password',
        dk: 'Kodeord',
    },
    'Phone': {
        en: 'Phone',
        dk: 'Telefon',
    },
    'Please_add_one_or_more_kinds_of_machine_data_to_display': {        //Set in addWidget file when adding new language
        en: 'Please add one or more kinds of machine data to display.',
        dk: 'Tilf' + String.fromCharCode(248) + 'j en eller flere typer af maskinedata at vise.',
    },
    'Please_give_your_widget_a_name': {        //Set in addWidget file when adding new language
        en: 'Please give your widget a name.',
        dk: 'Giv instrumentet et navn.',
    },
    'Please_select_a_machine': {        //Set in addWidget file when adding new language
        en: 'Please select a machine.',
        dk: 'V' + String.fromCharCode(230) + 'lg en maskine.',
    },
    'Please_select_a_type_of_graph_to_represent_the_data': {        //Set in addWidget file when adding new language
        en: 'Please select a type of graph to represent the data.',
        dk: 'V' + String.fromCharCode(230) + 'lg hvilken slags graf dataene skal repr' + String.fromCharCode(230) + 'senteres i.',
    },
    'Please select the role': {
        en: 'Please select the role',
        dk: 'V' + String.fromCharCode(230) + 'lg en rolle',
    },
    'Please select the role': {
        en: 'Please select the role',
        dk: 'V' + String.fromCharCode(230) + 'lg en rolle',
    },
    'Position': {
        en: 'Position',
        dk: 'Position',
    },
    'Production Start': {
        en: 'Production Start',
        dk: 'Produktionsstart',
    },
    'Register': {
        en: 'Register',
        dk: 'Registrer',
    },
    'Register New Factory': {
        en: 'Register New Factory',
        dk: 'Registrer Ny Fabrik',
    },
    'Refresh Data': {           //Set in user/TableList and company/List when adding new language
        en: 'Refresh Data',
        dk: 'Opdater Data',
    },
    'Remote Device': {
        en: 'Remote Device',
        dk: 'Fjern Enhed',      //Dette giver ingen brugervenlig mening [CHECK]
    },
    'Role': {
        en: 'Role',
        dk: 'Rolle'
    },
    'Rows': {                   //Set in user/TableList and company/List when adding new language
        en: 'Rows',
        dk: 'R' + String.fromCharCode(230) + 'kker'
    },
    'Save': {
        en: 'Save',
        dk: 'Gem',
    },
    'Search': {                 //Set in user/TableList and company/List when adding new language
        en: 'Search',
        dk: 'S' + String.fromCharCode(248) + 'g',
    },
    'Search for the company': {                 //Set in user/TableList and company/List when adding new language
        en: '* Search for the company.',
        dk: '* S' + String.fromCharCode(248) + 'g efter firmaet.',
    },
    'See Machines Status': {                 //Set in user/TableList and company/List when adding new language
        en: 'See Machines Status',
        dk: 'Se maskinestatus',
    },
    'Select a factory from the table etc...': {
        en: 'Select a factory from the table if you wish to change the factory for this user',
        dk: 'V' + String.fromCharCode(230) + 'lg en fabrik fra tabellen hvis du ' + String.fromCharCode(248) + 'nsker at ' + String.fromCharCode(230) + 'ndre denne brugers arbejdsplads',
    },
    'Select a machine': {
        en: 'Select a machine',
        dk: 'V' + String.fromCharCode(230) + 'lg en maskine',
    },
    'Super Administrator': {
        en: 'Super Administrator',
        dk: 'Superadministrator',
    },
    'Manager': {
        en: 'Manager',
        dk: 'Superbruger',
    },
    'Test': {
        en: 'Translation works',
        dk: 'overs' + String.fromCharCode(230) + 'ttelse virker',
    },
    'The dashboard was successfully saved': {
        en: 'The dashboard was successfully saved',
        dk: 'Tavlen er blevet gemt',
    },
    'The factory was successfully saved': {
        en: 'The factory was successfully saved',
        dk: 'Fabrikken er blevet gemt',
    },
    'The company was successfully saved': {
        en: 'The company was successfully saved',
        dk: 'Firmaet er blevet gemt',
    },
    'The user was successfully saved': {
        en: 'The user was successfully saved',
        dk: 'Brugeren er blevet gemt',
    },
    'The company was successfully updated': {
        en: 'The company was successfully updated',
        dk: 'Firmaet er blevet opdateret',
    },
    'The user will be saved in your factory.': {
        en: 'The user will be saved in your factory.',
        dk: 'Brugeren vil blive gemt i din fabrik',
    },
    'This field is required': {
        en: 'This field is required',
        dk: 'udfyld dette felt',
    },
    'Manager': {
        en: 'Manager',
        dk: 'Superbruger',
    },
    'Super Administrator': {
        en: 'Super Administrator',
        dk: 'Superadministrator',
    },
    'Super_Administrator': {
        en: 'Super_Administrator',
        dk: 'Superadministrator',
    },
    'Operator': {
        en: 'Operator',
        dk: 'Bruger',
    },
    'User Information': {
        en: 'User Information',
        dk: 'Brugerinformation',
    },
    'User Information': {
        en: 'User Information',
        dk: 'Brugerinformation',
    },
    'Username': {
        en: 'Username',
        dk: 'Brugernavn',
    },
    'Users': {
        en: 'Users',
        dk: 'Brugere',
    },
    'Your widget has been saved!': {
        en: 'Your widget has been saved!',
        dk: 'Dit instrument er blevet gemt!',
    },
    'Zip': {
        en: 'Zip',
        dk: 'Postkode',
    },
    'zip': {
        en: 'zip',
        dk: 'postkode',
    },

}


//English = en
//Danish = dk
//Romanian == ro

