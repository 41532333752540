
export const convertToErrorType: Record<string, string> = {
  E1: "ErrorCode1",
  E2: "ErrorCode2",
  E3: "ErrorCode3",
  W1: "WarningCode1",
  W2: "WarningCode2",
};
export const ErrorTypes: Record<string, Record<string, number>> = {
  //Error codes here are bit fields - so a errorcode can mean mutliple different errors, each bit needs to be checked.

  ErrorCode1: {
    None: 0x000,
    Pt1HighTempBit: 0x001,
    Pt2HighTempBit: 0x002,
    Pt3HighTempBit: 0x004,
    Pt1LowTempBit: 0x008,
    Pt2LowTempBit: 0x010,
    Pt3LowTempBit: 0x020,
    TankControlLow: 0x040,
    SuctionLance1Empty: 0x080,
    SuctionLance2Empty: 0x100,
    MbAliveError: 0x200,
  },
  ErrorCode2: {
    None: 0x0000,
    P1_PressureLowBit: 0x0001,
    P1_SignalLowBit: 0x0002,
    P1_HighLoad: 0x0004,
    P1_LowLoad: 0x0008,
    P2_PressureLowBit: 0x0010,
    P2_SignalLowBit: 0x0020,
    P2_HighLoad: 0x0040,
    P2_LowLoad: 0x0080,
    P3_PressureLowBit: 0x0100,
    P3_SignalLowBit: 0x0200,
    P3_HighLoad: 0x0400,
    P3_LowLoad: 0x0800,
    Flow_HighLoad: 0x1000,
    Flow_LoadLoad: 0x2000,
  },
  ErrorCode3: {
    None: 0x000,
    currErrPhase1: 0x001,
    currErrPhase2: 0x002,
    sumCurrErr: 0x004,
    dcLowVoltErr: 0x008,
    HighVoltErr: 0x010,
    hwOverCurrErr: 0x020,
    safeTorqueOff: 0x040,
    moduleTempToHigh: 0x080,
    moduleTempHigh: 0x100,
    Powerlimit: 0x200,
  },
  WarningCode1: {
    None: 0x0000,
    P1_LowWarningBit: 0x0001,
    P1_HighLimitUpBit: 0x0002,
    P1_HighLimitLowBit: 0x0004,
    P2_LowWarningBit: 0x0008,
    P2_HighLimitUpBit: 0x0010,
    P2_HighLimitLowBit: 0x0020,
    P3_LowWarningBit: 0x0040,
    P3_HighLimitUpBit: 0x0080,
    P3_HighLimitLowBit: 0x0100,
    TankControlHigh: 0x0200,
    SuctionLance1Low: 0x0400,
    SuctionLance2Low: 0x0800,
    ModuleHighTempBit: 0x1000,
    BoardHighTempBit: 0x2000,
    Powerlimit: 0x4000,
    Leakagestart: 0x8000,
  },
  WarningCode2: {
    None: 0x0000,
  },
};
