import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import ReactSpeedometer, { Transition } from 'react-d3-speedometer';
import { Gauge as GaugeModel, UiElement } from '../../../Models/Menus';
import { GetServer } from '../../../Models/ServerCommuncation/Server';
import { DatedIotData } from '../../../Models/ServerCommuncation/serverInterFace';
const BarArr = [0, 10, 20, 30, 40, 50];
const BarArrColor = { 0: "limegreen" }
let TemperaturesArr = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

type GaugeView = ({ value, screenName }: { value: number, screenName: string }) => JSX.Element;


const gaugeViewMap: Record<string, GaugeView> = {
    bargauge: BarGauage,
    tempgauge: TempGauge,

}



export default function Gauge({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {

    return <InnerGauge Gauge={element as GaugeModel}></InnerGauge>
}


function InnerGauge({ Gauge }: { Gauge: GaugeModel }) {

    const Server = GetServer();

    let [val, setVal] = React.useState<number>(0);


    function SetTelemetry(data: DatedIotData) {
        setVal(data.data[Gauge.telemtryField] as number);
    }

    useEffect(() => {


        Server.on_telemtry(SetTelemetry);

        return () => {

            Server.off_telemtry(SetTelemetry);
        }
    }, []);



    const GaugeView = gaugeViewMap[Gauge.gauge_type];

    return <GaugeView value={val} screenName={Gauge.display_name} ></GaugeView>
}


function BarGauage({ value, screenName }: { value: number, screenName: string }) {
    function GetColorForBar(bar: number) {
        if (bar <= 25)
            return "limegreen";
        if (bar > 25 && bar <= 40)
            return "gold";
        return "firebrick";
    }


    return <Grid container justifyContent="center"><ReactSpeedometer
        width={250}
        height={175}
        ringWidth={8}
        minValue={0}
        maxValue={50}
        value={value}
        currentValueText={`${value} ${screenName}`}
        customSegmentStops={BarArr}
        startColor=''
        segmentColors={BarArr.slice(1).map(GetColorForBar)}
    /></Grid>
}


function TempGauge({ value, screenName }: { value: number, screenName: string }) {

    function GetColorForTemp(temp: number): string {
        if (temp <= 50)
            return "limegreen";
        if (temp > 50 && temp < 70)
            return "gold";
        return "firebrick";
    }
    return <Grid container justifyContent="center"><ReactSpeedometer
        width={250}
        height={175}
        ringWidth={8}
        minValue={0}
        maxValue={100}
        value={value}
        needleTransition={Transition.easeQuadIn}
        currentValueText={`${value} ${screenName}`}
        customSegmentStops={TemperaturesArr}
        segmentColors={TemperaturesArr.slice(1).map(GetColorForTemp)}
    /></Grid>
}