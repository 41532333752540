import React from "react";
import { useOutletContext } from "react-router-dom";
import moment from "moment";

export default function ViewMachineAlerts() {
  const { machineData } = useOutletContext();

  return (
    <>
      <div className="justify-between gap-x-4 sm:grid sm:grid-cols-2">
        <div className="w-full ">
          <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Alerts</h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            {/* <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  Put here the chart with the real time data
                </div>
              </dl>
            </div> */}
          </div>
          {/* <div className="bg-white shadow-sm ring-1 ring-gray-900/5  sm:rounded-2xl my-4">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">last 7 day performance data</h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>
                    put here the information that you can find in the mobile app under the drop down information panel
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div className="w-full mt-4 sm:mt-0">
          <div className="overflow-hidden bg-white shadow-sm  ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">Running Hours</h3>
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  put here the chart with the running hours
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-white shadow-sm ring-1 ring-gray-900/5  sm:rounded-2xl my-4">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">Power Consumption</h3>
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>put here the chart with the power consumption from the last 14 days</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
