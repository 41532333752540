import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BoosterType, ParseBoosterType } from "../../Models/ParsingOfBoosterType";
import { GetServer } from "../../Models/ServerCommuncation/Server";


type InfoElement = { name: string; value: string | number };
export default function InfoBox(): JSX.Element {
  return (
    <>
      <Grid item container xs={11} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" minHeight={"20vh"}>
        <InfoElements></InfoElements>
      </Grid>
    </>
  );
}

function CreateInfoElements(data: Record<string, string>): InfoElement[] {
  return Object.keys(data)
    .filter((key) => {
      return data[key] !== "NA";
    })
    .map((key) => {
      return { name: key, value: data[key] as string };
    });
}

function parseSwVersions(str: String): Record<string, string> {
  let [_, data] = str.split("'Response'           :");
  let data_obj: Record<string, string> = {};
  for (const ele of data.split("#")) {
    let [key, val] = ele.split(":");
    if (val) {
      data_obj[key] = val;
    }
  }
  return data_obj;
}
function InfoElements(): JSX.Element {
  const Server = GetServer();
  const [infoElements, SetinfoElements] = React.useState<InfoElement[]>([]);
  const [BoosterType, SetBoosterType] = React.useState<BoosterType | null>(null);
  const server = GetServer();
  useEffect(() => {
    server.SendCommand("GetSwVersionsJSON", null, (data, err) => {
      if (!err) {
        let infoElements = CreateInfoElements(data!);
        SetinfoElements(infoElements);
      }
    });
    server.GetEepromSettings((mabye_data, err) => {
      
      if (!err) {
        const data = mabye_data!;
        SetBoosterType(ParseBoosterType(data));
      }
    });
  }, []);
  if (infoElements.length === 0) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"} height={"10vmax"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  if (BoosterType) {
    return (    <Paper sx={{ width: '100%', maxHeight: "20vh",'& .MuiTableCell-head':{ lineHeight: "0.5vh"}   }}   >
      <TableContainer sx={{ maxHeight: "100%", overflowX: "hidden", }} className="scroll" >
        <Table stickyHeader>
          <TableHead sx={{lineHeight: "20px"}}>
            <TableRow  >
              <TableCell sx={{maxWidth: "5vw"}} >
                Name
              </TableCell>
              <TableCell  >
                Value
              </TableCell >
     
            </TableRow>
          </TableHead>
  
          <TableBody>
          <TableRow><InfoElement name="Serial Number" value={Server.getmahineId()}></InfoElement></TableRow>
          <TableRow> <InfoElement name="Booster Type" value={BoosterType.boosterType}></InfoElement></TableRow>
          <TableRow>  <InfoElement name="Machine Code" value={BoosterType.boosterCode}></InfoElement></TableRow>
          {infoElements.map((infoElement,idx) => {
            if (idx === infoElements.length-1) {
              return         <TableRow sx={{"& td": { border: 0 } }}><InfoElement name={infoElement.name} value={infoElement.value.toString()}></InfoElement> </TableRow>;

            }
          return         <TableRow><InfoElement name={infoElement.name} value={infoElement.value.toString()}></InfoElement> </TableRow>;

          })}
         
          </TableBody>
        </Table>
      </TableContainer>
    </Paper> )
 
  }
  return (
    <>
      <InfoElement name="DeviceId" value={Server.getmahineId()}></InfoElement>
      {infoElements.map((infoElement) => {
        return <InfoElement name={infoElement.name} value={infoElement.value.toString()}></InfoElement>;
      })}
    </>
  );
}

function InfoElement({ name, value }: { name: string; value: string }): JSX.Element {
  return (
    <>
      <TableCell  sx={{ paddingLeft: "0.5vw", }}>
        <Typography variant="button" color={"black"} fontSize={"0.6vmax"}  sx={{whiteSpace: "nowrap"}}>
          {name}
        </Typography>
      </TableCell>

      <TableCell  sx={{  paddingLeft: "0.5vw", }}>
        <Typography variant="button" color={"black"} fontSize={"0.6vmax"} sx={{whiteSpace: "nowrap"}}>
          {value}
        </Typography>
      </TableCell>
      </> 
       );
}