import apiClient from "./apiClient";

/* ----- Get Methods ----- */

export const pingMachine = async (serialNumber) => {
  return apiClient.get(`ctd/Ping/${serialNumber}`);
};

export const checkServiceCode = async (serviceCode) => {
  return apiClient.get(`check-service-code/${serviceCode}`);
};

export const getOwnMachineBySN = async (serialNumber) => {
  return apiClient.get(`machines/own/serial-number/${serialNumber}`);
};
export const getMachineBySN = async (serialNumber) => {
  return apiClient.get(`machines/serial-number/${serialNumber}`);
};

export const getLastMachineTelemetry = async (serialNumber) => {
  return apiClient.get(`device/${serialNumber}`);
};

export const getPerformanceDataByMachineIdOwn = async (MachineId) => {
  return apiClient.get(`machines/performance/own/${MachineId}`);
};

export const getConsumptionDataBySerialNumberOwn = async (serialNumber) => {
  return apiClient.get(`machines/consumption/own/${serialNumber}`);
};

export const getConsumptionDataBySerialNumber = async (serialNumber) => {
  return apiClient.get(`machines/consumption/${serialNumber}`);
};

export const getErrorLogV2 = async (serialNumber) => {
  return apiClient.get(`ctd/GetErrorLogV2/${serialNumber}`);
};

export const getMachineSWVersion = async (serialNumber) => {
  return apiClient.get(`ctd/GetSwVersionsJSON/${serialNumber}`);
};
export const getMachinesByFactoryId = async (FactoryId) => {
  return apiClient.get(`machines/factory/${FactoryId}`);
};

export const getOwnMachinesByFactoryId = async (FactoryId) => {
  return apiClient.get(`machines/factory/own/${FactoryId}`);
};

/* ----- Post Methods ----- */

export const addOwnMachine = async (data) => {
  return apiClient.put(`machines/own/add-to-room`, data);
};

export const addMachine = async (data) => {
  return apiClient.put(`machines/admin/add-to-room`, data);
};

/* ----- Put Methods ----- */
export const removeOwnMachineFromFactory = async (MachineId) => {
  return apiClient.put(`machines/own/remove-from-factory/${MachineId}`, {});
};
export const removeMachineFromFactory = async (MachineId) => {
  return apiClient.put(`machines/remove-from-factory/${MachineId}`, {});
};
