import { useMutation } from "@tanstack/react-query";
import { resetPassword } from "api/user.service";

export const useResetPassword = () => {
  return useMutation({
    mutationFn: (data) => {
      return resetPassword(data);
    },
  });
};
