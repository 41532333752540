import {  useMutation, useQueryClient } from "@tanstack/react-query";
import { updateOwnNotificationMachineCode } from "api/notification.service";

export const useUpdateNotificationMachineCode = () => {
const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data) => {
      return updateOwnNotificationMachineCode(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notification-machine-codes"] });
    },
  });
};
