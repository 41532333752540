import { useMachineErrorLogQuery } from "hooks/machine";
import { useOutletContext } from "react-router-dom";
import ErrorLogList from "./components/ErrorLogList";
import ErrorLogCalendar from "./components/ErrorLogCalendar";
import {
  decryptErrorCodes,
  decryptWarningCodes,
} from "shared/utils/decriptErrors";
import ErrorLogListSkeleton from "./components/ErrorLogListSkeleton";
import { useState } from "react";
import { parseISO } from "date-fns";
export default function ViewMachineErrors() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { machineData } = useOutletContext();
  const { data, isLoading, error } = useMachineErrorLogQuery(
    machineData?.serialNumber,
  );
  var logList = [];
  if (data?.data !== undefined) {
    logList = convertLogList(data?.data);
  }
  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleDateChange = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (startDate && !endDate) {
      if (parseISO(date) < parseISO(startDate)) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    }
  };
  return (
    <div >
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
        <ErrorLogCalendar
          logList={logList}
          handleDateChange={handleDateChange}
          handleReset={handleReset}
        />
        <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
          {isLoading ? (
            <ErrorLogListSkeleton logList={logList} />
          ) : (
            <ErrorLogList
              logList={logList}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </ol>
      </div>
    </div>
  );
}

const convertLogList = (logList) => {
  var errors = [];
  var warnings = [];
  for (let i = 0; i < logList.length; i++) {
    const dates = logList[i].date;
    const ErrorCode1 = logList[i].ErrorCode1;
    const ErrorCode2 = logList[i].ErrorCode2;
    const ErrorCode3 = logList[i].ErrorCode3;
    const WarningCode1 = logList[i].WarningCode1;
    const WarningCode2 = logList[i].WarningCode2;
    const SlaveId = logList[i]?.SlaveId || "0";
    const errorsList = decryptErrorCodes(ErrorCode1, ErrorCode2, ErrorCode3);
    errorsList.map((error) => {
      error.SlaveId = SlaveId;
      error.date = dates;
    });
    const warningsList = decryptWarningCodes(WarningCode1, WarningCode2);
    warningsList.map((warning) => {
      warning.SlaveId = SlaveId;
      warning.date = dates;
    });
    errors = errors.concat(errorsList);
    warnings = warnings.concat(warningsList);
  }
  logList = errors.concat(warnings);
  //sort by date
  logList.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });
  return logList;
};
