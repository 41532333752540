import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
// import { CheckIcon } from "@heroicons/react/24/outline";
import { useAddMachine } from "hooks/machine/useAddMachine";
import { useRoomsByFactoryIdQuery } from "hooks/factory";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Controller, useForm } from "react-hook-form";

export default function AddMachineDialog({ open, setOpen, factoryId }) {
  const { mutate: addMachine, isPending, isError, isSuccess } = useAddMachine();
  const { data, isLoading } = useRoomsByFactoryIdQuery(factoryId);

  useEffect(() => {
    reset();
  }, [open]);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    
    addMachine(
      {
        serialNumber: data["serial-number"],
        authenticateCode: data["authentication-code"],
        name: data["machine-alias"],
        RoomId: data["room"].id,
      },
      {
        onError: (error) => {
          console.log("error", error.response);
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mt-3  sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-center text-base font-semibold leading-6 text-gray-900 "
                >
                  Add machine
                </DialogTitle>
                <div className="mt-2">
                  <form
                    className="md:col-span-1"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="px-4 py-6 sm:p-8">
                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 ">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="serial-number"
                            className="block text-left text-sm font-medium leading-6 text-gray-900"
                          >
                            Serial number
                          </label>
                          <div className="mt-2">
                            <input
                              id="serial-number"
                              name="serial-number"
                              {...register("serial-number", {
                                required: true,
                              })}
                              type="number"
                              //   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              className={`${
                                errors["serial-number"]
                                  ? "ring-red-500 focus:ring-red-500"
                                  : "ring-gray-300 focus:ring-blue-500"
                              } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                            />
                            {console.log("errrorrr", errors)}
                            <p
                              className="mt-2 text-sm text-red-600"
                              hidden={!errors["serial-number"]}
                            >
                              {errors["serial-number"]?.message}
                            </p>
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="authentication-code"
                            className="block text-left text-sm font-medium leading-6 text-gray-900"
                          >
                            Authentication code
                          </label>
                          <div className="mt-2">
                            <input
                              id="authentication-code"
                              name="authentication-code"
                              {...register("authentication-code", {
                                required: true,
                              })}
                              type="number"
                              //   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              className={`${
                                errors["authentication-code"]
                                  ? "ring-red-500 focus:ring-red-500"
                                  : "ring-gray-300 focus:ring-blue-500"
                              } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                            />
                            <p
                              className="mt-2 text-sm text-red-600"
                              hidden={!errors["authentication-code"]}
                            >
                              {errors["authentication-code"]?.message}
                            </p>
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <label
                            htmlFor="machine-alias"
                            className="block text-left text-sm font-medium leading-6 text-gray-900"
                          >
                            Machine alias
                          </label>
                          <div className="mt-2">
                            <input
                              id="machine-alias"
                              name="machine-alias"
                              {...register("machine-alias", { required: true })}
                              type="text"
                              //   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              className={`${
                                errors["machine-alias"]
                                  ? "ring-red-500 focus:ring-red-500"
                                  : "ring-gray-300 focus:ring-blue-500"
                              } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                            />
                            <p
                              className="mt-2 text-sm text-red-600"
                              hidden={!errors["machine-alias"]}
                            >
                              {errors["machine-alias"]?.message}
                            </p>
                          </div>
                        </div>

                        <div className="sm:col-span-4">
                          <Controller
                            name="room"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <RoomsCombobox
                                {...field}
                                rooms={data?.data?.rooms}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        // data-autofocus

                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                      >
                        Add machine
                      </button>
                      <button
                        type="button"
                        data-autofocus
                        onClick={() => setOpen(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

function RoomsCombobox({ rooms, value, onChange }) {
  const [query, setQuery] = useState("");

  const filteredRooms =
    query === ""
      ? rooms
      : rooms.filter((room) => {
          return room.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={value}
      onChange={(room) => {
        setQuery("");
        onChange(room);
      }}
    >
      <Label className="block text-sm font-medium leading-6 text-gray-900">
        Room
      </Label>
      <div className="relative mt-2">
        <ComboboxInput
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery("")}
          displayValue={(room) => room?.name}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </ComboboxButton>

        {filteredRooms.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredRooms.map((room) => (
              <ComboboxOption
                key={room.id}
                value={room}
                className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-blue-600 data-[focus]:text-white"
              >
                <span className="block truncate group-data-[selected]:font-semibold">
                  {room.name}
                </span>

                <span className="absolute inset-y-0 left-0 hidden items-center pl-1.5 text-blue-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
