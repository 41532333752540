import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { changeOwnUserFactoryRole, changeUserFactoryRole } from "../../api/user.service";

export const useChangeFactoryRole = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      if (isAdmin) return changeUserFactoryRole(data);
      else return changeOwnUserFactoryRole(data);
    },
  });
};
