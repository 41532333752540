import { useEffect, useState } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function get_tick(start: number, end: number, times: number): number[] {
  let step = (end - start) / (times - 1);


  let base = start;
  let ticks = [];
  for (let i = 0; i < times; i++) {
    ticks.push(base);
    base += step;
  }
  return ticks;
}
