import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useCreateRoom } from "hooks/room";
import { useQueryClient } from "@tanstack/react-query";

export default function CreateRoomDialog({ open, setOpen, factoryId }) {
  const queryClient = useQueryClient();
  const { mutate: createRoom, isPending, isError, isSuccess } = useCreateRoom();

  useEffect(() => {
    reset();
  }, [open]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    createRoom(
      {
        name: data["room-name"],
        FactoryId: factoryId,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ["factory", factoryId, "rooms"],
          });
          setOpen(false);
        },
        onError: (error) => {
          console.log("error", error.response);
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mt-3  sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-center text-base font-semibold leading-6 text-gray-900 "
                >
                  Create room
                </DialogTitle>
                <div className="mt-2">
                  <form
                    className="md:col-span-1"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="px-4 py-6 sm:p-8">
                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 ">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="room-name"
                            className="block text-left text-sm font-medium leading-6 text-gray-900"
                          >
                            Name
                          </label>
                          <div className="mt-2">
                            <input
                              id="room-name"
                              name="room-name"
                              {...register("room-name", { required: true })}
                              type="text"
                              //   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              className={`${
                                errors["room-name"]
                                  ? "ring-red-500 focus:ring-red-500"
                                  : "ring-gray-300 focus:ring-blue-500"
                              } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                            />
                            <p
                              className="mt-2 text-sm text-red-600"
                              hidden={!errors["room-name"]}
                            >
                              {errors["room-name"]?.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        // data-autofocus

                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                      >
                        Create room
                      </button>
                      <button
                        type="button"
                        data-autofocus
                        onClick={() => setOpen(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
