import React from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { CircularProgress, Backdrop } from "@mui/material";

import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { LuFactory, LuUsers } from "react-icons/lu";
import { MdOutlineHeatPump } from "react-icons/md";

import Breadcrumbs from "components/Breadcrumbs";
import { useFactoryByIdQuery } from "hooks/factory";
import DropDown from "../Components/DropDown";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewFactory(props) {
  const myAccount = useSelector((state) => state.user.user);
  let { factoryId } = useParams();
  factoryId = parseInt(factoryId);

  const location = useLocation();
  let navigate = useNavigate();

  let factoryRole = myAccount?.Factories.find(
    (factory) => factory.id === factoryId,
  )?.FactoryUser?.factoryRole;

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  const tabs = [
    { name: "Factory", url: "details", icon: LuFactory, current: true },
    { name: "Users", url: "users", icon: LuUsers, current: false },
    {
      name: "Machines",
      url: "machines",
      icon: MdOutlineHeatPump,
      current: false,
    },
    //   { name: "Rooms", href: "#", icon: CreditCardIcon, current: false },
  ].map((item) => ({
    ...item,
    current: location.pathname.includes(item.url),
  }));

  const { data, isLoading, error } = useFactoryByIdQuery(factoryId);

  console.log("FactoryDetails", data);

  if (error) {
    navigate("/internal-server-error", { replace: true });
  }

  const pages = [
    { name: "Factories", url: "/factories", current: false },
    // { name: "Factory", url: "factory", current: true },
    {
      name: data?.data?.factory?.name,
      url: `/Factories/${factoryId}/view`,
      current: true,
    },
  ];
  return (
    <>
      <Breadcrumbs pages={pages} />
      <div className="mx-2 mb-4 mt-4 flex items-center justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight ">
            {data?.data?.factory?.name}
          </h2>
        </div>
        <div className="flex flex-shrink-0">
          {isAdmin || factoryRole === "Manager" ? (
            <DropDown factoryDetails={data?.data?.factory} />
          ) : null}
        </div>

        {/* <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0 mb-4">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PencilSquareIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Edit Factory
          </button>
        </div> */}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mb-4">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-blue-400 focus:ring-blue-400"
            defaultValue={tabs.find((tab) => tab.current)?.name || tabs[0].name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200 ">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.url}
                  className={classNames(
                    tab.current
                      ? "border-blue-400 text-blue-500"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium",
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <tab.icon
                    className={classNames(
                      tab.current
                        ? "text-blue-400"
                        : "text-gray-400 group-hover:text-gray-500",
                      "-ml-0.5 mr-2 h-5 w-5",
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.name}</span>
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <Outlet
        context={{ factoryDetails: data?.data?.factory, loading: isLoading }}
      />
    </>
  );
}
