import { Grid } from "@mui/material";
import React from "react";
import { UiElement, UiElementSlider as UiElementSliderModel } from "../../Models/Menus";
import UiElementChooser from "./UiElementChooser";
import LeftButton from "./interactive/LeftButton";
import RightButton from "./interactive/RightButton";

export function UiElementSlider({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }): JSX.Element {
  return <UiElementsSliderInner element={element as UiElementSliderModel} ScreenUpdate={ScreenUpdate} disabled={disabled}></UiElementsSliderInner>;
}

function UiElementsSliderInner({ element, ScreenUpdate, disabled }: { element: UiElementSliderModel, ScreenUpdate?: () => void, disabled: boolean }): JSX.Element {
  return (
    <Grid item container direction={"row"}>
      <Grid item xs={1} container justifyContent="end" alignContent={"center"}>

        {element.index != 0 ? <LeftButton disabled={disabled} /> : null}
      </Grid>

      <Grid item xs={10} container direction="row" rowGap={4} alignContent="center" justifyContent="center">
        {element.uiElements.slice(element.index, element.index + 4).map((element) => {

          return (
            <Grid item xs>
              <UiElementChooser element={element} ScreenUpdate={ScreenUpdate} disabled={disabled}></UiElementChooser>
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={1} container justifyContent="end" alignContent={"center"}>

        {element?.uiElements.length !== element.index - 1 ? <RightButton disabled={disabled} /> : null}
      </Grid>
    </Grid>
  );
}
