import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  subMonths,
  addMonths,
  isToday,
  isSameMonth,
  isWithinInterval,
  parseISO,
} from "date-fns";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ErrorLogCalendar({
  logList: events,
  handleDateChange,
  handleReset,
}) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const uniqueEvents = [];
  const dateMap = {};

  events.forEach((event) => {
    const eventDate = event.date.split(" ")[1];
    if (!dateMap[eventDate]) {
      dateMap[eventDate] = event.type;
    } else if (dateMap[eventDate] === "warning" && event.type === "error") {
      dateMap[eventDate] = "error";
    }
  });

  for (const [date, type] of Object.entries(dateMap)) {
    uniqueEvents.push({ date, type });
  }

  const generateDays = (month) => {
    const start = startOfWeek(startOfMonth(month), { weekStartsOn: 1 }); // Start week on Monday
    const end = endOfWeek(endOfMonth(month), { weekStartsOn: 1 }); // End week on Sunday
    return eachDayOfInterval({ start, end }).map((date) => {
      const formattedDate = format(date, "dd.MM.yyyy");
      const event = uniqueEvents.find((event) => event.date === formattedDate);
      const isSelected =
        startDate &&
        endDate &&
        isWithinInterval(date, {
          start: parseISO(startDate),
          end: parseISO(endDate),
        });
      const isStartOrEndDate = [startDate, endDate].includes(
        format(date, "yyyy-MM-dd"),
      );
      return {
        date: format(date, "yyyy-MM-dd"),
        isCurrentMonth: isSameMonth(date, month),
        isToday: isToday(date),
        isSelected,
        isStartOrEndDate,
        marked: !!event,
        eventType: event ? event.type : null,
      };
    });
  };

  const daysCurrentMonth = generateDays(currentMonth);

  const previousMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (startDate && !endDate) {
      if (parseISO(date) < parseISO(startDate)) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    }
    handleDateChange(date);
  };

  const renderCalendar = (days) => (
    <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
      {days.map((day, dayIdx) => (
        <button
          key={day.date}
          type="button"
          onClick={() => handleDateClick(day.date)}
          className={classNames(
            "group  relative focus:z-10",
            day.isCurrentMonth ? "bg-white" : "bg-gray-50",
            day.isSelected || day.isToday || day.marked || day.isStartOrEndDate,
            day.isStartOrEndDate && "text-white",
            !day.isSelected &&
              !day.isStartOrEndDate &&
              day.isCurrentMonth &&
              !day.isToday &&
              "text-gray-900",
            !day.isSelected &&
              !day.isStartOrEndDate &&
              !day.isCurrentMonth &&
              !day.isToday &&
              "text-gray-400",
            day.isToday &&
              !day.isSelected &&
              !day.isStartOrEndDate &&
              "text-blue-600",
            dayIdx === 0 && "rounded-tl-lg",
            dayIdx === 6 && "rounded-tr-lg",
            dayIdx === days.length - 7 && "rounded-bl-lg",
            dayIdx === days.length - 1 && "rounded-br-lg",
            day.isSelected && !day.isStartOrEndDate && "!bg-blue-200",
          )}
        >
          {day.isStartOrEndDate && (
            <>
              {day.date === startDate && (
                <span className="absolute inset-y-0 right-0 w-1/2 bg-blue-200 "></span>
              )}
              {day.date === endDate && (
                <span className="absolute inset-y-0 left-0 w-1/2 bg-blue-200 "></span>
              )}
            </>
          )}
          <time
            dateTime={day.date}
            className={classNames(
              "relative mx-auto flex h-11 w-11 items-center justify-center",
              day.isStartOrEndDate && "rounded-full bg-blue-500 text-white",
              day.marked &&
                day.eventType === "error" &&
                "rounded-full bg-red-500 text-white",
              day.marked &&
                day.eventType === "warning" &&
                "rounded-full bg-orange-400 text-white",
              "group-hover:rounded-full group-hover:text-blue-600 group-hover:ring-2 group-hover:ring-inset group-hover:ring-blue-600",
            )}
          >
            {day.date.split("-").pop().replace(/^0/, "")}
          </time>
        </button>
      ))}
    </div>
  );

  return (
    <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          onClick={previousMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">
          {format(currentMonth, "MMMM yyyy")}
        </div>
        <button
          type="button"
          onClick={nextMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
        <div>S</div>
      </div>
      {renderCalendar(daysCurrentMonth)}
      <button
        className="mt-4 inline-flex w-32 rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:opacity-50 disabled:hover:bg-blue-500"
        onClick={() => {
          setStartDate(null);
          setEndDate(null);
          handleReset();
          // handleDateChange(null);
        }}
        disabled={!startDate && !endDate}
      >
        Clear selection
      </button>
    </div>
  );
}
