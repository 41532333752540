import { useQuery } from "@tanstack/react-query";
import { getLastMachineTelemetry } from "api/machine.service";

export const useTelemetryDataQuery = (serialNumber) => {
  return useQuery({
    queryKey: ["machine", serialNumber, "telemetry"],
    queryFn: () => getLastMachineTelemetry(serialNumber),
    enabled: !!serialNumber,
    staleTime: Infinity
  });
};
