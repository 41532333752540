import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CircularProgress, Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { ErrorLogEntry } from "../../Models/Errorlog/ErrorLogEntry";
import GetErrors from "../../Models/Errorlog/ErrorLogHandler";
import ErrorLogs from "../../Models/Errorlog/ErrorLogs";
import { GetPopUpBarListener } from "../../Models/HistoricViewlistener";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { WarningAndErrorIconView } from "../uiElements/ErrorAndWarningIcons";

export default function ErrorLogView() {
  const server = GetServer();
  const listener = GetPopUpBarListener();

  const [ErrorLog, SetErrorLog] = React.useState<ErrorLogs>(new ErrorLogs([]));
  const [ErrorConfig, SetErrorConfig] = React.useState<Record<string, any>>({});
  const [ErrorLogsList, SetErrorLogsList] = React.useState<ErrorLogEntry[] | null>(null);
  const [ErrorChosen, SetErrorChosen] = React.useState<ErrorLogEntry | null>(null);
  useEffect(() => {
    async function getConfig() {
      const machineConfig = await GetServer().GetErrorFile();
      SetErrorConfig(machineConfig);
    }
    getConfig();
  }, []);

  useEffect(() => {
    if (!!ErrorConfig && Object.keys(ErrorConfig).length !== 0) {
      server.SendCommand("GetErrorLogJSON", null, (data, err) => {
        if (!err) {

          let new_error_log = new ErrorLogs(GetErrors(data as any, ErrorConfig));
          let error_logs = new_error_log.GetAllErrors();
          SetErrorLog(new_error_log);
          error_logs.sort((a, b) => (b.date as Date).valueOf() - (a.date as Date).valueOf());

          SetErrorLogsList(error_logs);
          listener.callListeners("ErrorTime", ["all", ""], error_logs);

        }
      });
    }
  }, [ErrorConfig]);

  useEffect(() => {
    if (ErrorChosen && ErrorLogsList) {
      listener.callListeners("ErrorTime", [ErrorChosen.errorMessage, ErrorChosen.ErrorName], ErrorLogsList);
    } else if (ErrorLogsList) {
      listener.callListeners("ErrorTime", ["all", ""], ErrorLogsList);

    }
  }, [ErrorChosen, ErrorLogsList]);
  if (!ErrorLogsList) {
    return <Grid item xs container>
      <Grid item xs container direction={"column"} justifyContent={"center"} alignContent={"center"}>

        <CircularProgress size={"5vmax"} />
      </Grid>
    </Grid>

  }
  return (
    <Grid item xs container>
      <CreateErorsByYearByMonthByDay setChosenError={SetErrorChosen} Errorlogs={ErrorLogsList}></CreateErorsByYearByMonthByDay>
    </Grid>
  );
}

function ErrorView({ error, setError }: { error: ErrorLogEntry; setError: (val: ErrorLogEntry | null) => void }): JSX.Element {
  const [ShowDetailed, SetShowDetailed] = React.useState<boolean>(false);
  if (!ShowDetailed) {
    return (
      <Grid container className="shadow" justifyContent="center" bgcolor={"white"} borderRadius={"0.5vw"} minWidth={"100%"} borderColor={"blue"}>
        <ShowError error={error}></ShowError>
        <Grid item container xs={2} justifyContent={"end"}>
          <IconButton
            onClick={() => {
              SetShowDetailed((prev: boolean) => !prev);
              setError(error);
            }}
          >
            <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
          </IconButton>
        </Grid>
      </Grid>
    );
  }
  if (ShowDetailed) {
    return (
      <Grid container direction={"column"} rowGap={2} justifyContent="center" minWidth={"100%"}>
        <Grid item className="shadow" container bgcolor={"white"} borderRadius={"0.5vw"}>
          <ShowError error={error}></ShowError>
          <Grid item container xs={2} justifyContent={"end"}>
            <IconButton
              onClick={() => {
                SetShowDetailed((prev: boolean) => !prev);
                setError(null);
              }}
            >
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            </IconButton>
          </Grid>
        </Grid>

        <ShowCauses error={error}></ShowCauses>
        <ShowRemedies error={error}></ShowRemedies>
      </Grid>
    );
  }
  return <></>
}
function parseMessage(val: string): JSX.Element {
  let values = val.split(/(?=\()/g);

  if (values.length === 0) {
    return (
      <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
        {val}
      </Typography>
    );
  }
  return (
    <>
      {values.map((val, idx) => {
        if (idx === values.length - 1) {
          return (
            <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
              {val}
            </Typography>
          );
        }
        return (
          <>
            <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
              {val}
            </Typography>
            <br></br>
          </>
        );
      })}
    </>
  );
}
function ShowCauses({ error }: { error: ErrorLogEntry }): JSX.Element | null {
  if (error.causes.length === 0) {
    return null;
  }
  return (
    <Grid item className="shadow" container direction={"column"} justifyContent="center" bgcolor={"white"} borderRadius={"0.5vw"} minWidth={"100%"}>
      <Grid item container justifyContent={"center"}>
        <Typography variant="button" color={"black"}>
          Causes
        </Typography>
      </Grid>

      {error.causes.map((cause, index) => {

        if (cause === "") {
          return <></>;
        }
        return (
          <Grid item container borderTop={1} borderColor={"grey"} sx={{ borderWidth: "0.15vh", paddingLeft: "0.5vw" }}>
            {parseMessage(cause)}
          </Grid>
        );
      })}
    </Grid>
  );
}

function ShowRemedies({ error }: { error: ErrorLogEntry }): JSX.Element | null {
  if (error.remedies.length === 0) {
    return null;
  }
  return (
    <Grid item className="shadow" container direction={"column"} justifyContent="center" bgcolor={"white"} borderRadius={"0.5vw"} minWidth={"100%"}>
      <Grid item container justifyContent={"center"}>
        <Typography variant="button" color={"black"}>
          Remedies
        </Typography>
      </Grid>

      {error.remedies.map((remedy, index) => {
        if (remedy === "") {
          return <></>;
        }
        return (
          <Grid item container borderTop={1} borderColor={"grey"} sx={{ borderWidth: "0.15vh", paddingLeft: "0.5vw" }}>
            {parseMessage(remedy)}
          </Grid>
        );
      })}
    </Grid>
  );
}

function ShowError({ error }: { error: ErrorLogEntry }): JSX.Element {
  let date = error.date as Date;
  return (
    <>
      <Grid container item alignContent={"center"} justifyContent={"start"} xs={5} sx={{ paddingLeft: "0.5vw" }}>
        <Typography variant="button" alignItems={"center"} color="black" overflow="hidden">
          {`${moment(date).format("HH:mm:ss")}`}
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="start" alignContent={"center"}>
        <Typography variant="button" alignItems={"center"} textAlign={"end"} color="black" overflow="hidden">
          {`  ${error.errorMessage} `}
        </Typography>
      </Grid>
      <Grid item xs={1} container justifyContent="end">
        <WarningAndErrorIconView type={error.ErrorType}></WarningAndErrorIconView>
      </Grid>
    </>
  );
}
function ErrorsByYearByMonthByDayModel(Errorlogs: ErrorLogEntry[]): Map<number, Map<number, Map<number, ErrorLogEntry[]>>> {
  let val = Errorlogs.reduce((result: Map<number, Map<number, Map<number, ErrorLogEntry[]>>>, currentObject) => {
    const date = new Date(currentObject.date);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    if (!result.get(year)) {
      result.set(year, new Map());
    }

    if (!result.get(year)?.get(month)) {
      result.get(year)?.set(month, new Map());
    }

    if (!result.get(year)?.get(month)?.get(day)) {
      result.get(year)?.get(month)?.set(day, []);
    }

    result.get(year)?.get(month)?.get(day)?.push(currentObject);

    return result;
  }, new Map());

  return val;
}

function CreateErorsByYearByMonthByDay({ Errorlogs, setChosenError }: { Errorlogs: ErrorLogEntry[]; setChosenError: (val: ErrorLogEntry | null) => void }): JSX.Element {
  if (Errorlogs.length === 0) {
    return <Grid item xs container direction={"column"} justifyContent={"center"} alignContent={"center"}>
      <Typography color={"black"} variant="button">The machine has no recorded errors</Typography>
    </Grid>
  }
  let OrderedErrors = ErrorsByYearByMonthByDayModel(Errorlogs);

  return (
    <List sx={{ overflow: "auto", minWidth: "100%", height: "75vh" }} className="scroll">
      <Grid container direction={"column"} rowGap={1}>
        {Array.from(OrderedErrors).map(([Year, ByMonth]) => {
          return (
            <ListItem>
              <Grid item container direction={"column"} rowGap={1} justifyContent={"center"} alignItems={"center"}>
                <Typography variant="button" color={"black"}>
                  {Year}
                </Typography>
                {Array.from(ByMonth).map(([month, ByDay]) => {
                  return (
                    <Grid item container direction={"column"} rowGap={1} justifyContent={"center"} alignItems={"center"}>
                      <Typography variant="button" color={"black"}>
                        {moment(0).add(month, "months").format("MMMM")}
                      </Typography>
                      {Array.from(ByDay).map(([Day, Days]) => {
                        return (
                          <Grid item container direction={"column"} rowGap={1} justifyContent={"center"} alignItems={"center"}>
                            <Grid item container style={{ backgroundColor: "blue", borderRadius: "50%", width: "2vw", height: "2vw" }} alignItems="center" justifyContent={"center"}>
                              <Typography>{Day}</Typography>
                            </Grid>
                            {Days.map((day) => {
                              return <ErrorView setError={setChosenError} error={day}></ErrorView>;
                            })}
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })}
              </Grid>
            </ListItem>
          );
        })}
      </Grid>
    </List>
  );
}
