import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { IsAdminRoute, IsAuthenticatedRoute } from "./routes/PrivateRoute";
import { userService } from "shared/services/authentication.service";
import { SnackbarProvider, useSnackbar } from "notistack";

import { getMyAccount } from "api/user.service";

import Layout from "./components/Layout";

// ---------- Pages ---------- //
import Login from "./pages/Login/Login";
import ConfirmingAccount from "./pages/Login/ConfirmingAccount";

import Register from "./pages/Register/Register";
import RegisterSuccess from "pages/Register/RegisterSuccess";

import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ForgotPassword/ResetPassword";
import ResetPasswordSuccessfully from "./pages/ForgotPassword/ResetPasswordSuccessfully";

import NotFoundPage from "components/NoFoundPage";
import UnauthorizedAccess from "components/UnauthorizedAccess";
import InternalServerErrorPage from "components/InternalServerErrorPage";

import Home from "./pages/Home/Home";

// ######### Factories ######### //
import Factories from "./pages/Factory/View/ViewFactories";
import Factory from "./pages/Factory/View/ViewFactory";
// import Rooms from "./pages/Factory/Room/View/ViewRooms";
import ViewFactoryDetails from "pages/Factory/View/ViewFactoryDetails";
import ViewFactoryUsers from "pages/Factory/View/ViewFactoryUsers";
import ViewFactoryMachines from "pages/Factory/View/ViewFactoryMachines";

// ######### Machines ######### //
import ViewMachine from "./pages/Machine/ViewMachine";
import ViewMachineDetails from "pages/Machine/ViewMachineDetails";

// ######### Settings ######### //
import Settings from "./pages/Settings/Settings.js";
import General from "pages/Settings/General";
import Password from "pages/Settings/Password";
import Notifications from "pages/Settings/Notifications";

// ######### Navigator ######### //
import SNConnectPage from "./pages/Navigator/SNConnectPage.tsx";

// ---------- Translation ---------- //
import { Provider } from "react-translated";
import translation from "./shared/utils/translation";

// ---------- Redux ---------- //
import { useDispatch } from "react-redux";
import { setUser } from "shared/store/user/actions/userActions";
import { useSelector } from "react-redux";

import "preline";
import ViewMachineTelemetry from "pages/Machine/ViewMachineTelemetry";
import ViewMachineAlerts from "pages/Machine/ViewMachineAlerts";
import ViewMachineErrors from "pages/Machine/ViewMachineErrors";
import ViewMachineSettings from "pages/Machine/ViewMachineSettings";
import {
  CheckCircleIcon,
  XMarkIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import AddMachine from "pages/Factory/Components/AddMachine";
import NavigatorLayout from "pages/Navigator/NavigatorLayout.tsx";
import NavigatorApp from "pages/Navigator/Views/NavigatorApp.tsx";
import { initiateSocket } from "shared/services/socket.service";

window.HSStaticMethods = window.HSStaticMethods || {};

const queryClient = new QueryClient();
initiateSocket();
//TODO implement driverjs for tutorials
function App() {
  const location = useLocation();
  const [showDevtools, setShowDevtools] = React.useState(false);
  const dispatch = useDispatch();
  const myAccount = useSelector((state) => state.user.user);

  const [language, setLanguage] = useState("en");
  useEffect(() => {
    if (window.HSStaticMethods.autoInit) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      if (userService.loggedIn() === true) {
        getMyAccount().then((res) => {
          console.log("res", res);
          const acc = res.data.user;
          console.log(myAccount);
          dispatch(setUser({ user: acc }));
          setLanguage(acc.language);
        });
      }
    }
  }, []);

  const LoginRoute = () => {
    const myAccount = useSelector((state) => state.user.user);
    const isAuthenticated = !!myAccount;
    const isAllowed = userService.loggedIn();

    // Redirect to home if user is already authenticated
    return isAuthenticated && isAllowed ? (
      <Navigate to="/home" replace />
    ) : (
      <Login />
    );
  };

  //TODO Implement lazy loading for the routes that are not used that often so the initial loading is faster
  return (
    <QueryClientProvider client={queryClient}>
      <Provider language={language} translation={translation}>
        <SnackbarProvider
          maxSnack={4}
          autoHideDuration={30000}
          Components={{
            success: SuccessCustom,
            error: ErrorCustom,
          }}
        >
          <Routes>
            <Route path="/login" element={<LoginRoute />} />
            <Route
              path="/confirm-account/:token"
              element={<ConfirmingAccount />}
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/register-successfully/:email"
              element={<RegisterSuccess />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/reset_password/:token"
              element={<ResetPassword />}
            />{" "}
            {/* //TODO deprecate this one */}
            <Route
              path="/reset-password-successfully"
              element={<ResetPasswordSuccessfully />}
            />
            <Route element={<IsAuthenticatedRoute />}>
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/unauthorized" element={<UnauthorizedAccess />} />
                <Route path="/settings" element={<Settings />} />
                <Route element={<IsAdminRoute />}>
                  {/* Admin routes */}
                  {/* Factories routes */}
                  <Route path="factories" element={<Factories />} />
                  <Route path="factories/:factoryId/view" element={<Factory />}>
                    <Route path="details" element={<ViewFactoryDetails />} />
                    <Route path="users" element={<ViewFactoryUsers />} />
                    <Route
                      path="users/:userId"
                      element={<ViewFactoryUsers />}
                    />
                    <Route path="machines" element={<ViewFactoryMachines />} />
                  </Route>
                  {/* <Route
                  path="factories/:factoryId/view/room/management"
                  element={<Rooms />}
                /> */}
                  <Route
                    path="factories/:factoryId/machine/:serialNumber"
                    element={<ViewMachine />}
                  >
                    <Route path="details" element={<ViewMachineDetails />} />
                    <Route
                      path="telemetry"
                      element={<ViewMachineTelemetry />}
                    />
                    <Route path="alerts" element={<ViewMachineAlerts />} />
                    <Route path="error-log" element={<ViewMachineErrors />} />
                    <Route path="settings" element={<ViewMachineSettings />} />
                  </Route>
                  {/* Machines routes */}
                  <Route
                    path="machine/:serialNumber"
                    element={<ViewMachine />}
                  />
                  <Route path="machine/add" element={<AddMachine />} />
                </Route>

                {/* Settings routes */}
                <Route path="settings" element={<Settings />}>
                  <Route path="general" element={<General />} />
                  <Route path="password" element={<Password />} />
                  <Route path="notifications" element={<Notifications />} />
                </Route>
                {/* --------------- */}
                <Route path="/navigator/connect" element={<SNConnectPage />} />
                <Route
                  path="/navigator/connect/:serialNumber"
                  element={<SNConnectPage />}
                />
                <Route path="*" element={<NotFoundPage />} />
                <Route
                  path="/internal-server-error"
                  element={<InternalServerErrorPage />}
                />
              </Route>
              {/* <Route element={<Layout />}>
                <Route path="/navigator/connect" element={<SNConnectPage />} />
              </Route> */}
              <Route element={<NavigatorLayout />}>
                <Route
                  path="/navigator/control-panel"
                  element={<NavigatorApp />}
                />
              </Route>
            </Route>
          </Routes>
        </SnackbarProvider>
      </Provider>

      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}

export default App;

const SuccessCustom = React.forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <div ref={ref} {...props}>
      <div className="flex w-80 flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                {props.message}
                {/* <p className="text-sm font-medium text-gray-900">
                  Successfully saved!
                </p>
                <p className="mt-1 text-sm text-gray-500">
                  Anyone with a link can now view this file.
                </p> */}
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={() => {
                    closeSnackbar(props.id);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const ErrorCustom = React.forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <div ref={ref} {...props}>
      <div className="z-60 flex w-80 flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg border border-red-200 bg-red-50 text-red-800 shadow-lg ">
          <div className="p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <XCircleIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-red-400"
                />
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                {props.message}
                {/* <p className="text-sm font-medium text-gray-900">
                Successfully saved!
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Anyone with a link can now view this file.
              </p> */}
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className=" inline-flex rounded-full bg-red-200 text-white hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  onClick={() => {
                    closeSnackbar(props.id);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
