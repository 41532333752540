import React, { useState } from "react";
import NavigatorHeaderBar from "./NavigatorHeaderBar";
import { Outlet } from "react-router-dom";
const NavigatorLayout = () => {
    return (
        <>
            {/* <SidebarNavTesting sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
            {/* <SidebarNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
            <NavigatorHeaderBar />
            <div className="lg:pl-0 overflow-hidden self-stretch">
                <main className="px-0 overflow-hidden">
                    <Outlet />
                </main>
            </div>
        </>
    );
};

export default NavigatorLayout;
