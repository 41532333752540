import { Box, Typography } from "@mui/material";
import { TextField as TextFieldModel, UiElement } from "../../../Models/Menus"

import React from "react";

export default function TextField({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
    return <InnerTextField textField={element}></InnerTextField>
}

function InnerTextField({ textField }: { textField: TextFieldModel }) {

    return <Box sx={{ backgroundColor: "white", borderTopLeftRadius: "2vw", b: "2vw", borderBottomLeftRadius: "2vw", borderBottomRightRadius: "2vw", borderTopRightRadius: "2vw" }} ><Typography >{textField.display_name}</Typography></Box>
}