import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteFactory, deleteOwnFactory } from "../../api/factory.service";

export const useDeleteFactory = (factoryId) => {
  const navigate = useNavigate();
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (factoryId) => {
      if (isAdmin) return deleteFactory(factoryId);
      else return deleteOwnFactory(factoryId);
    },
    onSuccess: () => {
      navigate("/factories");
    },
    onError: (error) => {
      console.log("errror", error);
    },
  });
};
