import apiClient from "./apiClient";

/* ----- Get Methods ----- */

// export const getFactoryRooms = (id) => {
//   return apiClient.get(`rooms/only/factory/${id}`);
// }
// export const getOwnFactoryRooms = (id) => {
//   return apiClient.get(`rooms/only/own/factory/${id}`);
// }

/* ----- Post Methods ----- */
export const createRoom = (data) => {
  return apiClient.post(`room/create`, data);
};

export const createOwnRoom = (data) => {
  return apiClient.post(`room/own/create`, data);
};
