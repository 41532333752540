import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Button as ButtonModel, UiElement } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";

export default function Button({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }): JSX.Element {

  return <InnerButton button={element as ButtonModel} ScreenUpdate={ScreenUpdate} disabled={disabled}></InnerButton>;
}

function InnerButton({ button, ScreenUpdate, disabled }: { button: ButtonModel, ScreenUpdate?: () => void, disabled: boolean }) {
  if (button.imageFile) return <ImgButton button={button}></ImgButton>;
  if (button.telemtryField) return <TelemtryButton button={button}></TelemtryButton>;
  if (button.command) return <CommandButton button={button} ScreenUpdate={ScreenUpdate} disabled={disabled}></CommandButton>;
  //default case

  return (
    <Grid container justifyContent="center">
      <button style={{ height: "4.5vw", width: "4.5vw", color: "white", backgroundColor: "darkblue", border: "solid ", borderRadius: "10%" }}>
        <Typography fontSize={"1.5cqmin"}>{button.display_name}</Typography>
      </button>
    </Grid>
  );
}

function ImgButton({ button }: { button: ButtonModel }) {
  return (
    <button>
      <img src={"shared/uitls/images/" + button!.imageFile} />
      {button.display_name}
    </button>
  );
}
function TelemtryButton({ button }: { button: ButtonModel }) {
  const Server = GetServer();

  let [val, setVal] = React.useState<number>(0);
  function SetTelemetry(data: DatedIotData) {
    let val = data.data[button.telemtryField!] ? data.data[button.telemtryField!] : 0;
    setVal(val);
  }
  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);

  // if (button.highlightable) {

  // }

  return (
    <Grid container justifyContent="center">
      <button style={{ height: "4.5vw", width: "4.5vw", color: "white", backgroundColor: "darkblue", border: "solid ", borderRadius: "10%" }}>
        <Typography fontSize={"1.5cqmin"}>{val + button.display_name}</Typography>
      </button>
    </Grid>
  );
}

function CommandButton({ button, ScreenUpdate, disabled }: { button: ButtonModel, ScreenUpdate?: () => void, disabled: boolean }) {
  const server = GetServer();

  function sendCommand() {
    if (disabled) {
      return;
    }
    let name = button.command!.name;
    let arg_val = button.command!.arg_val;

    if (arg_val) {
      server.SendCommand(`${name}`, `${arg_val}`, () => { });

      return;
    }

    server.SendCommand(name, null, () => { });
  }
  return (
    <Grid container justifyContent="center">
      <button onClick={() => {
        if (disabled) {
          return;
        }
        sendCommand();
        ScreenUpdate?.();
      }} style={{ cursor: disabled ? "default" : "pointer", height: "4.5vw", width: "4.5vw", color: "white", backgroundColor: "darkblue", border: "solid ", borderRadius: "10%" }}>
        <Typography fontSize={"1.5cqmin"}>{button.display_name}</Typography>
      </button>
    </Grid>
  );
}

// function TelemtrySelectedButton({button} : {button : ButtonModel})  {
//     <button style={ (val ? {backgroundColor:"white", color: "darkblue"} : {})}
//     > {button.display_name} </button>
// }

function TelemtryValButton({ button, val }: { button: ButtonModel; val: number }) {
  return <button>{button.display_name + ": " + val} </button>;
}
