import { ErrorLogEntry } from "./Errorlog/ErrorLogEntry";

export type PopUpBarType = "ErrorTime" | "UpTime";

type Listener = (type: PopUpBarType, arg?: string[], errologEntries?: ErrorLogEntry[]) => void;
let listener: PopUpBarListener;
export function InstantiatePopUpBarListener(): PopUpBarListener {
  listener = new PopUpBarListener();
  return listener;
}

export function GetPopUpBarListener(): PopUpBarListener {
  return listener;
}
class PopUpBarListener {
  listeners: Listener[];
  constructor() {
    this.listeners = [];
  }

  addListener(listener: Listener) {
    this.listeners.push(listener);
  }

  removeListener(listener: Listener) {
    let idx = this.listeners.findIndex((listenerInArray) => {
      return listenerInArray == listener;
    });
    if (idx === -1) {
      return;
    }
    this.listeners.splice(idx, 1);
  }

  callListeners(type: PopUpBarType, arg?: string[], errologEntries?: ErrorLogEntry[]) {
    for (const listener of this.listeners) {
      listener(type, arg, errologEntries);
    }
  }
}
