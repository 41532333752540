import { Grid } from "@mui/material";
import React from "react";
import { UiElement, UiElementsTelemtry } from "../../Models/Menus";
import UiElementChooser from "./UiElementChooser";
import LeftButton from "./interactive/LeftButton";
import RightButton from "./interactive/RightButton";



export default function TelemtryUiElement({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
  return (<TelemtryUiElementInner uielement={element as UiElementsTelemtry} disabled={disabled} />);
}





function TelemtryUiElementInner({ uielement, disabled }: { uielement: UiElementsTelemtry, disabled: boolean }) {
  let [uiElements, setUielements] = React.useState(uielement);
  let [startIndex, SetStartIndex] = React.useState(0);
  let [endindex, SetEndIndex] = React.useState(4);
  function SetUiElements() {
    let getElementList = async () => {
      let list = await uiElements.initTelemtryList();
      setUielements(list);
    }
    getElementList();
  }
  SetUiElements();
  if (uiElements.elements.length == 0) {
    return null;
  }

  return (
    <Grid item container >
      <Grid item xs={1}><LeftButton disabled={disabled} onclickHandler={() => { SetStartIndex((val) => val + 1); SetEndIndex((val) => val + 1) }}></LeftButton></Grid>
      <Grid
        item
        xs={10}
        container
        columnGap={2}
        direction="row"
        alignItems="center"
        justifyContent={"center"}
      >
        <Grid item xs={1} />
        {uiElements.elements.slice(startIndex, endindex).map((element) => {
          return <Grid item xs>
            <UiElementChooser element={element} disabled={disabled}></UiElementChooser>
          </Grid>
        })}
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={1}> <RightButton disabled={disabled} onclickHandler={() => { SetStartIndex((val) => val - 1); SetEndIndex((val) => val - 1) }} ></RightButton></Grid>

    </Grid>
  );

}

