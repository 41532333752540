import moment from "moment";
import { ErrorDescription } from "./ErrorHandler";

export interface IErrorLogEntry {
  causes: string[];
  remedies: string[];
  errorMessage: string;
  date: Date | string;
  Equals(entry: ErrorLogEntry): boolean;
}
export class ErrorLogEntry implements IErrorLogEntry {
  causes: string[];
  remedies: string[];
  errorMessage: string;
  date: Date | string;
  ErrorCode: number;
  ErrorName: string;
  ErrorType: string;
  constructor(date: number | string, errorDescripton: ErrorDescription, errorEntry: [string, number],errorType : string) {
    this.causes = this.parseArray(errorDescripton.Causes);

    this.remedies = this.parseArray(errorDescripton.Remedies);
    this.errorMessage = errorDescripton.ErrorMessage;
    this.ErrorType = errorType;
    let parsed_date = moment(date, "HH:mm:ss DD.MM.YYYY").toDate();

    this.date = parsed_date.toString() != "Invalid Date" ? parsed_date : (date as string);

    this.ErrorName = errorEntry[0];
    this.ErrorCode = errorEntry[1];
  }

  parseArray(arr: string[]): string[] {
    if (arr.length === 1 && arr[0] === "") {
      return [];
    }
    return arr;
  }

  public Equals(entry: ErrorLogEntry) {
    if (entry == null) return false;
    return entry.ErrorCode == this.ErrorCode && entry.ErrorName == this.ErrorName;
  }
}
