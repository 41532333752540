import { Grid } from "@mui/material";

import React from "react";
import { UiElement, UiElementGroups } from "../Models/Menus";
import UiElementChooser from "../Views/uiElements/UiElementChooser";



export function SubScreen({ Subscreen, ScreenUpdate, disabled }: { Subscreen: any, ScreenUpdate?: () => void, disabled: boolean }): JSX.Element {
  let elements = Subscreen.UiElementGroups;
  return <RenderSubScreen uielement={elements} ScreenUpdate={ScreenUpdate} disabled={disabled}></RenderSubScreen>;
}

function RenderSubScreen({ uielement, ScreenUpdate, disabled }: { uielement: UiElementGroups, ScreenUpdate?: () => void, disabled: boolean }): any {
  return uielement.elements?.map((element) => {
    let elements: UiElement[] = Object.values(element);
    return (
      <Grid
        item
        xs
        container
        columnGap={2}
        direction="row"
        alignItems="center"
        justifyContent={"center"}
      >
        <Grid item xs={1} />
        {elements.map((element) => {

          return <Grid item xs>
            <UiElementChooser element={element} ScreenUpdate={ScreenUpdate} disabled={disabled}></UiElementChooser>
          </Grid>
        })}
        <Grid item xs={1} />
      </Grid>
    );
  });
}
