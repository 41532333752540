import { useEffect, useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useCreateRoom } from "hooks/room";
import { useQueryClient } from "@tanstack/react-query";
import { useSendInvitation } from "hooks/invitation";
import { enqueueSnackbar } from "notistack";

const factoryRoles = [
  { id: "Manager", title: "Manager" },
  { id: "Technician", title: "Technician" },
  { id: "Operator", title: "Operator" },
];

export default function InviteUserDialog({ open, setOpen, factoryId }) {
  const queryClient = useQueryClient();
  const {
    mutate: sendInvitation,
    isPending,
    isError,
    isSuccess,
  } = useSendInvitation();

  useEffect(() => {
    reset();
  }, [open]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("factoryId", factoryId);
    console.log("data", data);
    sendInvitation(
      {
        email: data["email"],
        factoryRole: data["factory-role"],
        FactoryId: factoryId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["factory-users", factoryId],
          });
          setOpen(false);
          enqueueSnackbar(
            <div>
              <p className="text-sm font-medium text-gray-900">
                Invitation sent!
              </p>
              <p className="mt-1 text-sm text-gray-500">
                The invitation has been sent to {data.email}
              </p>
            </div>,
            { variant: "success" },
          );
        },
        onError: (error) => {
          console.log("error", error.response);

          if (
            error?.response?.data?.msg ===
            "An invitation has already been sent to this user."
          ) {
            setError("email", {
              type: "manual",
              message: "An invitation has already been sent to this user.",
            });
          }
          if (
            error?.response?.data?.msg ===
            "You are not connected to this factory."
          ) {
            setError("info", {
              type: "manual",
              message:
                "You are not part of this factory or the factory does not exist.",
            });
          }
          if (error?.response?.data?.msg === "You are not a manager.") {
            setError("info", {
              type: "manual",
              message: "You are not a manager.",
            });
          }
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mt-3  sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-center text-base font-semibold leading-6 text-gray-900 "
                >
                  Invite user
                </DialogTitle>
                <div className="mt-2">
                  <form
                    className="md:col-span-1"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="px-4 py-6 sm:p-8">
                      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 ">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="email"
                            className="block text-left text-sm font-medium leading-6 text-gray-900"
                          >
                            Email
                          </label>
                          <div className="mt-2">
                            <input
                              id="email"
                              name="email"
                              {...register("email", { required: true })}
                              type="text"
                              //   className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              className={`${
                                errors["email"]
                                  ? "ring-red-500 focus:ring-red-500"
                                  : "ring-gray-300 focus:ring-blue-500"
                              } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                            />
                            <p
                              className="mt-2 text-sm text-red-600"
                              hidden={!errors["email"]}
                            >
                              {errors["email"]?.message}
                            </p>
                          </div>
                        </div>
                        <fieldset>
                          <legend className="text-sm font-semibold leading-6 text-gray-900">
                            Role
                          </legend>
                          <p className="mt-1 text-sm leading-6 text-gray-600">
                            What access level will this user have?
                          </p>
                          <div className="mt-6 space-y-6">
                            {factoryRoles.map((factoryRole) => (
                              <div
                                key={factoryRole.id}
                                className="flex items-center"
                              >
                                <input
                                  defaultChecked={factoryRole.id === "Operator"}
                                  id={factoryRole.id}
                                  value={factoryRole.id}
                                  name="factory-role"
                                  type="radio"
                                  className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                  {...register("factory-role")}
                                />
                                <label
                                  htmlFor={factoryRole.id}
                                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {factoryRole.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <p
                      className="mt-2 text-sm text-red-600"
                      hidden={!errors["info"]}
                    >
                      {errors["info"]?.message}
                    </p>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        // data-autofocus
                        disabled={isPending}
                        className=" inline-flex w-full items-center justify-center gap-x-2 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 sm:col-start-2"
                      >
                        {isPending ? (
                          <span
                            class="inline-block size-5 animate-spin rounded-full border-[3px] border-current border-t-transparent text-white"
                            role="status"
                            aria-label="loading"
                          ></span>
                        ) : null}
                        Invite user
                      </button>
                      <button
                        type="button"
                        data-autofocus
                        onClick={() => setOpen(false)}
                        disabled={isPending}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 sm:col-start-1 sm:mt-0 "
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
