
type BoosterNameType =  "single booster" | "multi booster" | "Foamatic" | "Main station" | "unknown";
export type BoosterType = { boosterType : BoosterNameType,boosterCode : string , boosterSubType : string | null,  }
type BoosterTypeParser = (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => BoosterType;
export function ParseBoosterType(eepromData : Record<string,string>) : BoosterType {
    let [AppBoosterType, booster5kW,FoamaticType] = [parseInt(eepromData["AppBoosterType"]),parseInt(eepromData["booster5kW"]),parseInt(eepromData["FoamaticType"])];
    
    const BoosterTypeParser : Record<number,BoosterTypeParser> = {
        0 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseSingleBooster(AppBoosterType, booster5kW),
        1 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseBasedOnBooster5KW(AppBoosterType, booster5kW),
        2 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseSingleBooster(AppBoosterType, booster5kW),
        3 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseSingleBooster(AppBoosterType, booster5kW),
        4 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseSingleBooster(AppBoosterType, booster5kW),
        5 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseSingleBooster(AppBoosterType, booster5kW),
        6 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMultiBooster(AppBoosterType),
        7 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMultiBooster(AppBoosterType),
        8 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMultiBooster(AppBoosterType),
        9 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMultiBooster(AppBoosterType),
        10 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMultiBooster(AppBoosterType),
        11 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMultiBooster(AppBoosterType),
        12 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMultiBooster(AppBoosterType),
        13 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMainStation(AppBoosterType),
        14 :(AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseMainStation(AppBoosterType),
        15 : (AppBoosterType : number,booster5kW :  number, FoamaticType : number ) => ParseFoaMatic(AppBoosterType,FoamaticType),


        
    }; 
    return BoosterTypeParser[AppBoosterType](AppBoosterType,booster5kW,FoamaticType);   
}
function ParseBasedOnBooster5KW(AppBoosterType : number, booster5kW :  number) : BoosterType {
    let {boosterType,boosterCode} = GetMU4XXBoosterType(booster5kW);
    return {boosterType : boosterType, boosterCode : boosterCode,boosterSubType : null};
}


function ParseSingleBooster(AppBoosterType : number, booster5kW :  number) : BoosterType {
    const boosterCodeMap : Record<number,string> = {
        0: "PRO",
        2: "BF8",
        3: "BH7",
        4: "DP",
        5: "",
        

    };
    return {boosterType: "single booster", boosterCode : boosterCodeMap[AppBoosterType], boosterSubType : null};
}


function ParseMultiBooster(AppBoosterType : number) : BoosterType {
    const boosterCodeMap : Record<number,string> = {
        6: "BF16",
        7: "BF24",
        8: "BF32",
        9: "BF40",
        10: "BF48",
        11: "BF56",
        12: "BF64"
    };
    return {boosterType: "multi booster", boosterCode : boosterCodeMap[AppBoosterType], boosterSubType : null};
}

function ParseFoamatic(AppBoosterType : number, FoamaticType : number) : BoosterType {
    const boosterCodeMap : Record<number,string> = {
        0: "None",
        1: "MA2iM",
        2: "MA3iM",
        3: "SA2iM",
        4: "SA3iM",
        5: "MA2i",
        6 : "MA3i",
        7: "SA2i",
        8 : "SA3i",
        9 : "MA2",
        10 : "MA2M",
        11 : "MA3",
        12 : "MA3M",

    };
    return {boosterType: "Foamatic", boosterCode : boosterCodeMap[AppBoosterType], boosterSubType : null};
}

function ParseFoaMatic(AppBoosterType : number, FoamaticType : number) : BoosterType {
    const boosterCodeMap : Record<number,string> = {
        0: "None",
        1: "MA2iM",
        2: "MA3iM",
        3: "SA2iM",
        4: "SA3iM",
        5: "MA2i",
        6 : "MA3i",
        7: "SA2i",
        8 : "SA3i",
        9 : "MA2",
        10 : "MA2M",
        11 : "MA3",
        12 : "MA3M",

    };
    return {boosterType: "Foamatic", boosterCode : boosterCodeMap[AppBoosterType], boosterSubType : null};
}


function ParseMainStation(AppBoosterType : number) : BoosterType {
    const mainStationCodeMap : Record<number,string> = {
        5 : "MP42",
        13 : "MIX10",
        14 : "MIX25"
    };
    return {boosterType : "Main station", boosterCode  : mainStationCodeMap[AppBoosterType], boosterSubType : null};

}



function GetMU4XXBoosterType(booster5kW :  number) : {boosterType : BoosterNameType ,boosterCode: string  } {
    const Booster5KWMap : Record<number,{boosterType : BoosterNameType ,boosterCode: string  }> = {
        0 : { boosterType : "unknown",boosterCode: "unknown" },
        1 : { boosterType : "Main station",boosterCode: "MH42" },
        2 : { boosterType : "single booster",boosterCode: "BF4" },
        3 : { boosterType : "single booster",boosterCode: "BH4" },
        4 : { boosterType : "single booster",boosterCode: "MD" },
        5 : { boosterType : "single booster",boosterCode: "BP4" },
    };


    return Booster5KWMap[booster5kW];
}