import { createReducer } from '@reduxjs/toolkit';
import { setUser, deleteUser } from '../actions/userActions';
const initialState = {
    user: {}
}

const userReducer = createReducer(initialState, (builder) =>
{
    builder
        .addCase(setUser, (state, action) =>
        {
            state.user = action.payload.user;
        })
        .addCase(deleteUser, (state, action) =>
        {
            state.user = {};
        })
})

export default userReducer;