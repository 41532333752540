import { useMutation } from "@tanstack/react-query";
import { deletePermanentlyMyAccount } from "api/user.service";
import { userService } from "shared/services/authentication.service";

export const useDeleteMyAccount = () => {
  return useMutation({
    mutationFn: () => {
      return deletePermanentlyMyAccount();
    },
    onSuccess: () => {
      userService.logout();
    },
    onError: (error) => {
      console.log("errror", error)
    },
  });
};
