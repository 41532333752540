import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  removeMachineFromFactory,
  removeOwnMachineFromFactory,
} from "../../api/machine.service";

export const useRemoveMachine = () => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useMutation({
    mutationFn: (data) => {
      if (false) return removeMachineFromFactory(data?.MachineId);
      else return removeOwnMachineFromFactory(data?.MachineId);
    },
  });
};
