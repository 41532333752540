import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, CircularProgress, Grid, IconButton, List, ListItem, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { ConvertEepromData, GetComponentName, GetDisplayNameForEepromKey, GetUnit, ParseKeyToInterval, componentNames } from "./DashBoardParsing";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
type MachineResponseType = "OK" | "Error";

type componentView = ({ setting, value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) => JSX.Element;
const SettingValueViews: Record<componentNames, componentView> = {
  Default: ValueSettingView,
  FlagButton: FlagButton,
  ValueAdjuster: ValueAdjusterSettingsView,
};
export default function SettingsView() {
  const server = GetServer();

  const [Settings, SetSettings] = React.useState<Map<string, string>>(new Map());
  const [CurrentSettings, SetCurrentSettings] = React.useState<Map<string, string>>(new Map());
  const [DefualtSettings, SetDefaultSetings] = React.useState<Map<string, string>>(new Map());
  const [ChangeStatus, SetChangeStatus] = React.useState<boolean>(false);
  const [MachineResponse, SetMachineResponse] = React.useState<MachineResponseType | null>(null);
  const [Err, SetErr] = React.useState(false);
  const [Sending, SetSending] = React.useState<{ sending: boolean, status: string }>({ sending: false, status: "" });
  useEffect(() => {

    server.GetEepromSettings((mabye_data, err) => {

      if (!err) {
        if (Err) {
          SetErr(false);
        }
        const data = mabye_data!;

        let settings = Object.keys(data)
          .sort((a, b) => {
            const nameA = GetUnit(a);
            const nameB = GetUnit(b);
            const typeA = GetComponentName(a);
            const typeB = GetComponentName(b);
            let typeSort = (typeB as string).localeCompare(typeA as string);
            if (typeSort === 0) {
              return nameB.localeCompare(nameA);
            }
            return typeSort;
          })
          .reduce((obj: Map<string, string>, key) => {
            obj.set(key, data[key]);
            return obj;
          }, new Map());


        server.GetMachineDefaultSettingsMemJSON((mabye_data, err) => {
          if (!err) {
            SetDefaultSetings(new Map(Object.entries(mabye_data!)));
            SetSettings(new Map(settings));
            SetCurrentSettings(new Map(settings));
            return;
          }
          SetErr(true);
        })


        return;
      }

      SetErr(true);

    });
  }, []);




  if (Err) {
    return <Grid item xs container justifyContent={"center"} direction={"column"} >
      <Typography fontSize={"0.6vmax"} color={"black"} variant="button">Couldnt fetch eeprom settings.</Typography>
    </Grid>
  }

  if (Sending.sending) {
    return (<Grid item xs container justifyContent={"center"} direction={"column"} >

      <Grid item xs><Grid item xs container justifyContent={"center"}><Typography fontSize={"0.6vmax"} color={"black"} variant="button">{Sending.status}</Typography></Grid></Grid>
      <Grid item xs> <Grid item xs container justifyContent={"center"} alignContent={"center"} height={"100%"}><CircularProgress size={"5vmax"} ></CircularProgress></Grid></Grid>
    </Grid>);
  }

  if (MachineResponse !== null) {
    return (

      <Grid item xs container direction={"column"} justifyContent={"center"} justifyItems={"center"} height={"100%"}>
        <ListItem><MachineResponseView MachineResponse={MachineResponse} SetMachineResponse={SetMachineResponse}></MachineResponseView></ListItem>
      </Grid>
    );
  }
  console.log(Settings);
  if (Settings.size === 0) {
    return <Grid container xs={12} justifyContent={"center"} alignContent={"center"} borderRadius={"0.5vw"} >
      <Grid item> <CircularProgress size={"5vmax"}></CircularProgress></Grid>
    </Grid>;
  }

  if (ChangeStatus) {

    return (
      <Grid container xs direction={"column"}>

            <Grid item xs container paddingTop={"0.5vmax"}>
                <Grid item xs={12} justifyContent={"center"} alignContent={"center"} maxHeight={"10vh"} borderRadius={"0.5vw"}>
                  <Button
                    fullWidth={true}
                    onClick={() => {
                      server.SetEepromSettings(Object.fromEntries(Settings), (ok, err) => {
                        SetSending({ sending: true, status: "fetching new settings from machine" });

                        server.GetEepromSettings((mabye_data, err) => {
                          console.log(err);
                          console.log(mabye_data);

                          if (!err) {
                            const data = mabye_data!;

                            let settings = Object.keys(mabye_data!)
                              .sort((a, b) => {
                                const nameA = GetUnit(a);
                                const nameB = GetUnit(b);
                                const typeA = GetComponentName(a);
                                const typeB = GetComponentName(b);
                                let typeSort = (typeB as string).localeCompare(typeA as string);
                                if (typeSort === 0) {
                                  return nameB.localeCompare(nameA);
                                }
                                return typeSort;
                              })

                              .reduce((obj: Map<string, string>, key) => {
                                obj.set(key, data[key]);
                                return obj;
                              }, new Map());
                            SetSettings(new Map(settings));
                            SetCurrentSettings(new Map(settings));
                            if (ok) {


                              SetMachineResponse("OK");
                              SetSending({ sending: false, status: "" });
                              return;


                            }

                            SetMachineResponse("Error");
                            SetSending({ sending: false, status: "" });

                          }
                        });


                      });
                      SetSending({ sending: true, status: "setting new settings on machine" });


                      SetChangeStatus(false);


                    }}
                  >
                    <Typography fontSize={"0.6vmax"} variant="button" >Send Settings to  machine </Typography>
                  </Button>
                </Grid>
              </Grid>
        <Grid item xs container>

      <Paper sx={{ width: '100%', maxHeight: "71vh", marginTop: "0.5vmax" }}>
        <TableContainer sx={{ maxHeight: "71vh", overflowX: "hidden", }} className="scroll">
          <Table stickyHeader>
            <TableHead >
              <TableRow >
                <TableCell sx={{ width: "5vw" }} >
                  Name
                </TableCell>
                <TableCell sx={{ maxWidth: "4vw" }} size="small">
                  Factory
                </TableCell >
                <TableCell sx={{ maxWidth: "4vw" }} size="small">
                  Current
                </TableCell>
                <TableCell sx={{ maxWidth: "4vw" }} size="small">
                  New
                </TableCell>
                <TableCell sx={{ maxWidth: "4vw" }} size="small">
                  unit
                </TableCell>
                <TableCell sx={{ maxWidth: "6vw" }}>
                  change
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
             
              <EepromSettingsView Settings={Settings} changeStatus={ChangeStatus} DefaultSettings={DefualtSettings} CurrentSettings={CurrentSettings} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></EepromSettingsView>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      </Grid>
      </Grid>

    );
  }
  return (

    <>
      <Paper sx={{ width: '100%', maxHeight: "71vh", marginTop: "1vmax" }}>
        <TableContainer sx={{ maxHeight: "71vh", overflowX: "hidden", }} className="scroll">
          <Table stickyHeader>
            <TableHead >
              <TableRow >
                <TableCell sx={{ width: "5vw" }} >
                <Typography variant="caption" fontSize={"0.7vmax"}><strong>Name</strong> </Typography>
                </TableCell>
                <TableCell sx={{ maxWidth: "3.5vw" }} size="small">
                <Typography variant="caption" fontSize={"0.7vmax"}><strong>Factory</strong> </Typography>

                  
                </TableCell >
                <TableCell sx={{ maxWidth: "3.5vw" }} size="small">
                <Typography variant="caption" fontSize={"0.7vmax"}><strong>Current</strong> </Typography>
                </TableCell>
                <TableCell sx={{ maxWidth: "3.5vw" }} size="small">
                  
                  <Typography variant="caption" fontSize={"0.7vmax"}><strong>New</strong> </Typography>

                </TableCell>
                <TableCell sx={{ maxWidth: "4vw" }} size="small">
                <Typography variant="caption" fontSize={"0.7vmax"}><strong>Unit</strong> </Typography>
                </TableCell>
                <TableCell sx={{ maxWidth: "6vw" }}>
                <Typography variant="caption" fontSize={"0.7vmax"}><strong>Change</strong> </Typography>

                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <EepromSettingsView Settings={Settings} changeStatus={ChangeStatus} DefaultSettings={DefualtSettings} CurrentSettings={CurrentSettings} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></EepromSettingsView>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

function MachineResponseView({ MachineResponse, SetMachineResponse }: { MachineResponse: MachineResponseType; SetMachineResponse: React.Dispatch<React.SetStateAction<MachineResponseType | null>> }): JSX.Element {
  const server = GetServer();
  switch (MachineResponse) {
    case "Error":
      return (
        <Grid item xs direction={"column"}>

          <Grid item xs={12} justifyContent={"center"} border={1} borderColor={"darkblue"} borderRadius={"0.5vw"}>
            <Typography fontSize={"0.6vmax"} variant="button" color={"black"}>Machine couldnt update status</Typography>
          </Grid>
          <Grid item xs={12} justifyContent={"center"} border={1} borderColor={"darkblue"} borderRadius={"0.5vw"}>
            <Button onClick={() => {
              SetMachineResponse(null);
            }}>
              <Typography fontSize={"0.6vmax"} variant="button" color={"black"} >Go back to navigator mode.</Typography>
            </Button>
          </Grid>
        </Grid>

      );
    case "OK": {
      return (
        <Grid item container height={"70vh"} direction={"column"} rowGap={3} >
          <Grid item container xs={1} justifyContent={"center"} alignContent={"center"} borderRadius={"0.5vw"}>
            <Grid item xs={6}><Typography fontSize={"0.6vmax"} variant="button" color={"black"} >Machine Updated succesfully</Typography></Grid>
          </Grid>
          <Grid item xs={2} container direction={"column"} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"}>
            <Button sx={{ height: "100%" }} fullWidth={true} onClick={() => {
              server.SendCommand("RebootSystem", null, () => { });
              server.disconnectNoError();
            }}>
              <Grid item container xs={12} direction={"column"} >
                <Grid item xs><Typography fontSize={"0.6vmax"} variant="button" color={"black"}  >Reboot the machine to update settings</Typography></Grid>
                <Grid item xs><Typography fontSize={"0.6vmax"} variant="button" color={"black"}  > Machine will leave navigator mode.</Typography></Grid>

              </Grid>
            </Button>
          </Grid>
          <Grid item xs={2} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"}>
            <Button sx={{ height: "100%" }} fullWidth={true} onClick={() => {
              SetMachineResponse(null);
            }}>
              <Grid container xs direction={"column"} >

                <Grid item xs><Typography fontSize={"0.6vmax"} variant="button" color={"black"}  >Go Back To Navigator Mode</Typography></Grid>
                <Grid item xs ><Typography fontSize={"0.6vmax"} variant="button" color={"black"}  > Machine will need to be rebooted to update all settings.</Typography></Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      );
    }
  }
}

function EepromSettingsView({
  Settings,
  CurrentSettings,
  SetSettings,
  DefaultSettings,
  changeStatus,
  SetChangeStatus,
}: {
  Settings: Map<string, string>;
  CurrentSettings: Map<string, string>;
  DefaultSettings: Map<string, string>;
  changeStatus: boolean;
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      {Array.from(Settings.keys())
        .filter((key) => {
          return GetDisplayNameForEepromKey(key) !== undefined;
        })
        .map((key) => {
          let setting = Settings.get(key)!;
          let currSetting = CurrentSettings.get(key)!;
          let defaultSetting = DefaultSettings.get(key)!;
          return (
            <SettingValueView changeStatus={changeStatus} setting={[key, setting]} DefaultSetting={[key, defaultSetting]} CurrentSettings={[key, currSetting]} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus}></SettingValueView>
          );
        })}
    </>
  );
}



function SettingValueView({
  setting,
  SetSettings,
  CurrentSettings,
  DefaultSetting,
  changeStatus,
  SetChangeStatus,
}: {
  setting: [string, string];
  CurrentSettings: [string, string];
  DefaultSetting: [string, string];
  changeStatus: boolean;
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let [, SetValue] = React.useState<string | null>(null);
  let [newValue, SetNewValue] = React.useState<string | null>(null);
  let [name, ePromVal] = setting;

  const displayName = GetDisplayNameForEepromKey(name);
  const componentName = GetComponentName(name);
  const Controller = SettingValueViews[componentName];
  useEffect(() => {
    SetValue(ePromVal);
  }, [setting]);

 
  if (newValue == null) {
    return (<TableRow>
      <Controller setting={setting} defaultSetting={DefaultSetting} CurrentSettings={CurrentSettings} SetNewValue={SetNewValue} value={newValue}></Controller>
    </TableRow>);
  }
  return (<>
    <TableRow>
      <Controller setting={setting} defaultSetting={DefaultSetting} CurrentSettings={CurrentSettings} SetNewValue={SetNewValue} value={newValue}></Controller>
    </TableRow>
    <TableRow>
      <PresOkButton name={name} value={newValue} SetSettings={SetSettings} SetChangeStatus={SetChangeStatus} SetNewValue={SetNewValue}></PresOkButton>

    </TableRow>
  </>);

}

function ValueSettingView({ setting, CurrentSettings, defaultSetting, value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  return (
    <>
      <Grid item container xs={4} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
        <Typography fontSize={"0.6vmax"} variant={"button"} color={"black"}>
          {value?.toString()}
        </Typography>
      </Grid>
      <Grid item container xs={5} justifyContent={"center"} alignContent={"center"}>
        <Grid item xs={9} bgcolor={"white"}>
          <TextField
            inputProps={{ style: { textAlign: "center" } }}
            size={"small"}
            defaultValue={value}
            onChange={(event) => {
              let new_value = event.target.value;

              SetNewValue(new_value);
            }}
          ></TextField>
        </Grid>
      </Grid>
    </>
  );
}

function FlagButton({ setting, CurrentSettings, defaultSetting, value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  const value_int = parseInt(value!);
  const [name, ePromVal] = setting;
  const [, current_val] = CurrentSettings;
  const [, default_val] = defaultSetting;

  const checked = value_int === 0 ? false : !isNaN(value_int) ? true : parseInt(ePromVal) === 0 ? false : true;

  const checked_currently = parseInt(current_val) === 0 ? false : true;
  const check_default = parseInt(default_val) === 0 ? false : true;
  return (
    <>
      <TableCell sx={{ maxWidth: "5vw", wordWrap: "break-word" }} >
        <Typography variant={"button"} fontSize={"0.6vmax"} color={"black"}>{name}</Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: "4vw", margin: "auto" }} >
        <Grid container justifyContent={"center"}>
          <Grid item ><Switch checked={check_default} disabled={true}></Switch></Grid>

        </Grid>
      </TableCell>
      <TableCell sx={{ maxWidth: "4vw" }}>
      </TableCell>
      <TableCell sx={{ maxWidth: "4vw", }}>
        <Grid container justifyContent={"center"}>

          <Grid item><Switch checked={checked_currently} disabled={true}></Switch></Grid>
        </Grid>

      </TableCell>
      <TableCell sx={{ maxWidth: "4vw" }}>
      </TableCell>

      <TableCell sx={{ maxWidth: "4vw" }}>
        <Grid container justifyContent={"center"}>

          <Grid item><Switch
            checked={checked}
            onChange={(event) => {
              SetNewValue((prev) => {
                return prev == "0" ? "1" : "0";
              });
            }}
          ></Switch></Grid>
        </Grid>

      </TableCell>
    </>
  );
}

function ValueAdjusterSettingsView({ setting, CurrentSettings, defaultSetting, value, SetNewValue }: { setting: [string, string]; defaultSetting: [string, string]; CurrentSettings: [string, string]; value: string | null; SetNewValue: React.Dispatch<React.SetStateAction<string | null>> }) {
  const [name, ePromVal] = setting;
  const Interval = ParseKeyToInterval(name);
  const adjusted_val = ConvertEepromData(name, parseInt(value!));
  const [current_name, current_val] = CurrentSettings;
  const [default_name, default_val] = defaultSetting;
  const adjuststed_default_val = ConvertEepromData(name, parseInt(default_val!));
  const adjusted_current_val = ConvertEepromData(name, parseInt(current_val!));

  const unit = GetUnit(current_name);
  if (!Interval) return <></>;

  return (
    <>
      <TableCell sx={{ maxWidth: "6vw", wordWrap: "break-word" }} >
      <Typography variant={"button"} fontSize={"0.6vmax"} color={"black"}> {name} </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: "3.5vw" }} align="center">
        <Typography fontSize={"0.6vmax"} variant={"button"} color={"black"} sx={{whiteSpace: "nowrap", wordBreak: "break-word"}} >
          {adjuststed_default_val.toString()}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: "3.5vw" }} align="center">
        <Typography fontSize={"0.6vmax"} variant={"button"} color={"black"}>
          {adjusted_current_val.toString()}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: "3.5vw" }} align="center">
        <Typography fontSize={"0.6vmax"} variant="button">{isNaN(adjusted_val) ? ConvertEepromData(name, parseInt(ePromVal!)) : adjusted_val}</Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: "2vw" }} align="center">
        <CreateUnitTextElement unit={unit} ></CreateUnitTextElement>
      </TableCell>
      <TableCell sx={{ maxWidth: "5vw" }}>
        <Grid item container alignContent={"center"} justifyContent={"center"}>
          <Grid container item xs={4} justifyContent="center" paddingRight={"0.5vmax"}>
            <IconButton
              disabled={value ? parseInt(value) <= Interval!.low : parseInt(ePromVal) <= Interval!.low}
              onClick={() => {
                SetNewValue((prev) => {
                  if (prev != null) {
                    let prev_int = parseInt(prev);
                    return (prev_int - Interval.interval).toString();
                  }
                  return (parseInt(ePromVal) - Interval.interval).toString();
                });
              }}
            >
              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            </IconButton>
          </Grid>
          <Grid container item xs={4} justifyContent="center"  paddingLeft={"0.5vmax"}>
            <IconButton
              disabled={value ? parseInt(value) >= Interval!.high : parseInt(ePromVal) >= Interval!.high}
              onClick={() => {
                SetNewValue((prev) => {
                  if (prev != null) {
                    let prev_int = parseInt(prev);
                    return (prev_int + Interval.interval).toString();
                  }

                  return (parseInt(ePromVal) + Interval.interval).toString();
                });
              }}
            >
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </>
  );
}

function CreateUnitTextElement({ unit }: { unit: string }): JSX.Element {
  if (unit.includes("/")) {
    let [first, second] = unit.split("/");

    return <>
      <Typography fontSize={"0.6vmax"} variant="button">{first + "/"}</Typography>
      <br />
      <Typography fontSize={"0.6vmax"} variant="button">{second}</Typography>

    </>
  }
  return <Typography fontSize={"0.6vmax"} variant="button">{unit}</Typography>

}

function PresOkButton({
  name,
  value,
  SetSettings,
  SetChangeStatus,
  SetNewValue,
}: {
  name: string;
  value: string | null;
  SetSettings: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  SetChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
  SetNewValue: React.Dispatch<React.SetStateAction<string | null>>;
}): JSX.Element | null {
  if (!value) {
    return null;
  }
  return (
    <TableCell colSpan={6}>
    <Grid container bgcolor={"white"} justifyContent={"center"} alignContent={"center"} alignItems={"center"} borderRadius={"0.5vw"} >

      <Typography fontSize={"0.6vmax"} style={{ cursor: "pointer" }}
        onClick={() => {
          SetSettings((prev) => {
            return new Map(prev.set(name, value));
          });
          SetNewValue(null);
          SetChangeStatus(true);

        }} variant="button" color={"black"}>Ok</Typography>
    </Grid>
    </TableCell>
  );
}
