import { Grid, Typography } from "@mui/material";
import React from "react";
import { ErrorLogEntry } from "../../Models/Errorlog/ErrorLogEntry";
function GetDateString(date: Date) {
  let monthString = date.toLocaleString("default", { month: "short" });
  let min = date.getMinutes();
  return date.getHours() + ":" + (min < 10 ? "0" + min : min) + " " + monthString + " " + date.getFullYear();
}

export default function ErrorLog({ error }: { error: ErrorLogEntry | null }) {
  if (!error) {
    return null;
  }
  const date = new Date(error.date);
  return (
    <Grid item xs={10} container direction="column" maxHeight={"16vmax"} >
      <Grid item xs={1} container direction="row" justifyContent="space-evenly" style={{ borderColor: "white", borderStyle: "solid" }}>
        <Grid item container justifyContent="flex-start" xs={6} style={{ maxHeight: "10vh" }}>

          <Typography fontSize={"2vh"}> error description</Typography>
        </Grid>
        <Grid item xs={6} container direction="row" spacing={4}>
          <Grid item xs={2}>
            <Typography fontSize={"2vh"}>Time</Typography>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={10}>

            <Typography fontSize={"2vh"}>{GetDateString(date)} </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11} container justifyContent="center" alignItems="center" direction="column" style={{ borderColor: "white", borderStyle: "solid", borderTopWidth: "0px" }}>
        <h1>Cause: </h1>
        <Grid item xs={5} container direction="column">
          <Grid item container>
            <Grid item container xs={2} justifyContent="center">
              <p style={{ fontSize: "1rem" }}>causes: </p>
            </Grid>
            <Grid item xs={10}>
              {error.causes.map((cause, index) => {
                return (
                  <Grid item xs container justifyContent="flex-start" key={index}>
                    <p style={{ fontSize: "1cqmin" }}>{cause}</p>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs container wrap="nowrap" direction="column">
          <Grid item xs container>
            <h1>Remedies: </h1>

            <Grid item xs={10}>
              {error.remedies.map((remedy, index) => {
                return (
                  <Grid item container justifyContent="flex-start" wrap="nowrap" key={index}>

                    <p style={{ fontSize: "1rem" }}>{remedy}</p>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
