import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import { useCreateFactory } from "hooks/factory/useCreateFactory";
import { useQueryClient } from "@tanstack/react-query";
import ErrorAlert from "../../../components/ErrorAlert";

export default function CreateFactorySlidePanel({
  createFactoryOpen: open,
  setCreateFactoryOpen: setOpen,
}) {
  const queryClient = useQueryClient();
  const {
    mutate: createFactory,
    isPending,
    isError,
    isSuccess,
    reset: resetMutationStatus,
  } = useCreateFactory();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
    resetMutationStatus();
  }, [open, reset, resetMutationStatus]);

  const onSubmit = (data) => {
    console.log("data", data);
    if (!data.address) {
      setError("address", {
        type: "required",
        message: "Please select an address from the dropdown list",
      });
      return;
    }

    const newFactory = {
      name: data["factory-name"],
      identifier: data["vat-number"],
      address: data["address"],
      zip: data["postal-code"],
      city: data["city"],
      country: data["country"],
    };

    createFactory(newFactory, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["factories"] });
        setOpen(false);
        reset();
      },
      onError: (error) => {
        console.log("error", error.response);
        if (error.response?.data?.msg === "This factory name already exists!") {
          setError("factory-name", {
            type: "custom",
            message: "This factory name is already used",
          });
        }
      },
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <form
                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-nilfisk px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            New Factory
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-blue-500 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-400"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-blue-300">
                            Get started by filling in the information below to
                            create your new factory.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pb-5 pt-6">
                            <div>
                              <label
                                htmlFor="factory-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Factory name
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id="factory-name"
                                  disabled={isPending}
                                  {...register("factory-name", {
                                    required: true,
                                  })}
                                  className={`${
                                    errors["factory-name"]
                                      ? "ring-red-500 focus:ring-red-500"
                                      : "ring-gray-300 focus:ring-blue-500"
                                  } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                                />

                                <p
                                  className="mt-2 text-sm text-red-600"
                                  hidden={!errors["factory-name"]}
                                >
                                  {errors["factory-name"]?.message}
                                </p>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                VAT number
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id="vat-number"
                                  disabled={isPending}
                                  {...register("vat-number", {
                                    required: false,
                                  })}
                                  className={`${
                                    errors["vat-number"]
                                      ? "ring-red-500 focus:ring-red-500"
                                      : "ring-gray-300 focus:ring-blue-500"
                                  } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                                />
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  hidden={!errors["vat-number"]}
                                >
                                  {errors["vat-number"]?.message}
                                </p>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Address
                              </label>
                              <div className="mt-2">
                                <Autocomplete
                                  type="text"
                                  id="address"
                                  disabled={isPending}
                                  apiKey={
                                    "AIzaSyB3Dg5U3BkMN6VpHvLuu_P4XK4e-rGbwyI"
                                  }
                                  options={{ types: ["address"] }}
                                  onPlaceSelected={(details) => {
                                    if (details) {
                                      // let hasStreetName = false;
                                      let streetName = "";
                                      let streetNumber = "";
                                      let city = "";
                                      let postalCode = "";
                                      let country = "";

                                      details.address_components.forEach(
                                        (component) => {
                                          if (
                                            component.types.includes("locality")
                                          ) {
                                            city = component.long_name;
                                          }
                                          if (
                                            component.types.includes(
                                              "postal_code",
                                            )
                                          ) {
                                            postalCode = component.long_name;
                                          }
                                          if (
                                            component.types.includes("country")
                                          ) {
                                            country = component.long_name;
                                          }
                                          if (
                                            component.types.includes("route")
                                          ) {
                                            streetName = component.long_name;
                                          }
                                          if (
                                            component.types.includes(
                                              "street_number",
                                            )
                                          ) {
                                            streetNumber = component.long_name;
                                          }
                                        },
                                      );

                                      let address =
                                        `${streetName} ${streetNumber}`.trim();

                                      const addressInput =
                                        document.getElementById("address");
                                      if (addressInput) {
                                        addressInput.value = address;
                                      }
                                      setValue("address", address);
                                      setValue("city", city);
                                      setValue("postal-code", postalCode);
                                      setValue("country", country);
                                    }
                                  }}
                                  {...register("address", { required: false })}
                                  className={`${
                                    errors["address"]
                                      ? "ring-red-500 focus:ring-red-500"
                                      : "ring-gray-300 focus:ring-blue-500"
                                  } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                                />
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  hidden={!errors["address"]}
                                >
                                  {errors["address"]?.message}
                                </p>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Postal Code
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id="postal-code"
                                  disabled={isPending}
                                  {...register("postal-code", {
                                    required: true,
                                  })}
                                  className={`${
                                    errors["postal-code"]
                                      ? "ring-red-500 focus:ring-red-500"
                                      : "ring-gray-300 focus:ring-blue-500"
                                  } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                                />
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  hidden={!errors["postal-code"]}
                                >
                                  {errors["postal-code"]?.message}
                                </p>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                City
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id="city"
                                  disabled={isPending}
                                  {...register("city", { required: true })}
                                  className={`${
                                    errors["city"]
                                      ? "ring-red-500 focus:ring-red-500"
                                      : "ring-gray-300 focus:ring-blue-500"
                                  } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                                />
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  hidden={!errors["city"]}
                                >
                                  {errors["city"]?.message}
                                </p>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Country
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  id="country"
                                  disabled={isPending}
                                  {...register("country", { required: true })}
                                  className={`${
                                    errors["country"]
                                      ? "ring-red-500 focus:ring-red-500"
                                      : "ring-gray-300 focus:ring-blue-500"
                                  } block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset disabled:opacity-50 sm:text-sm sm:leading-6`}
                                />
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  hidden={!errors["country"]}
                                >
                                  {errors["country"]?.message}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="pb-4 pt-4">
                            <div className="flex text-sm">
                              <a
                                href="#"
                                className="group inline-flex items-center text-gray-500 hover:text-gray-900"
                              >
                                <QuestionMarkCircleIcon
                                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="ml-2">
                                  Learn more about creating a factory
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="pt-4">
                            {isError ? (
                              <ErrorAlert text="There was an error creating the factory" />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 items-center justify-end gap-x-4 px-4 py-4">
                      {isPending ? (
                        <div
                          className="inline-block size-6 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
                          role="status"
                          aria-label="loading"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : null}
                      <button
                        type="button"
                        disabled={isPending}
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-blue-500 disabled:opacity-50 disabled:hover:bg-white"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={isPending}
                        className="inline-flex justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:opacity-50 disabled:hover:bg-blue-500"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
