import { CircularProgress } from "@material-ui/core";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { ErrorPopUps } from "../Models/Errorlog/ErrorPopUps";
import { Menu as MenuModel, SubScreen as SubScreenModel, UiElement } from "../Models/Menus";
import { GetServer } from "../Models/ServerCommuncation/Server";
import { DatedIotData, GetErrorCodes } from "../Models/ServerCommuncation/serverInterFace";
import { SubScreen } from "../Subscreen/Subscreen";
import ErrorPopUp from "./Errror/ErrorPopUp";
import GoBackButton from "./uiElements/interactive/GoBackButton";
import LeftButton from "./uiElements/interactive/LeftButton";
import RightButton from "./uiElements/interactive/RightButton";
import StopMachineButton from "./uiElements/interactive/StopMachinButton";

export default function Menu({ subScreen, menu, globalUiElements, screenUpdating, ScreenUpdate }: { subScreen: SubScreenModel | null; menu: MenuModel | null; globalUiElements: UiElement[], screenUpdating: boolean, ScreenUpdate?: () => void }): JSX.Element | null {
  if (screenUpdating) {
    return <Grid container width={"36vmax"} height={"20vmax"} bgcolor={"darkblue"} justifyContent={"center"} alignContent={"center"}>
      <CircularProgress size={"5vmax"} style={{ color: "white" }}></CircularProgress>
    </Grid>;
  }
  if (!subScreen || !menu) {
    return <Grid container width={"36vmax"} height={"20vmax"} bgcolor={"darkblue"} justifyContent={"center"} alignContent={"center"}>
      <Typography fontSize={"2vmax"} variant="button" color={"white"}>Menu not Implemented </Typography>
    </Grid>;
  }

  return (
    <Grid item container direction={"column"} justifyContent={"center"}>
      <ScreenView subScreen={subScreen} menu={menu} ScreenUpdate={ScreenUpdate}></ScreenView>

    </Grid>
  );
}

function ScreenView({ subScreen, menu, ScreenUpdate }: { subScreen: SubScreenModel; menu: MenuModel, ScreenUpdate?: () => void }) {
  const Server = GetServer();
  const [ErrorConfig, SetErrorConfig] = React.useState<Record<string, any>>({});
  const [ErrorData, SetErrorData] = React.useState<Record<string, number>>({});
  const [ErrorModel, SetErrorModel] = React.useState(new ErrorPopUps(ErrorData, ErrorConfig));
  const [HasErrors, SetHasErros] = React.useState<boolean>(false);
  const ErrorModelRef = React.useRef(ErrorModel);
  useEffect(() => {
    let error_model = new ErrorPopUps(ErrorData, ErrorConfig);
    SetHasErros(error_model.HasErrors());
    if (!ErrorModelRef.current.ErrorIsShown() && !ErrorModelRef.current.HasJustShownErrors()) {
      SetErrorModel(error_model);
      ErrorModelRef.current = error_model;
    }
  }, [ErrorData, ErrorConfig]);

  useEffect(() => {
    const on_error_data = (data: DatedIotData) => {
      SetErrorData(GetErrorCodes(data.data));
    };
    Server.on_telemtry(on_error_data);
    async function getConfig() {
      const machineConfig = await GetServer().GetErrorFile();

      SetErrorConfig(machineConfig);
    }
    getConfig();
    return () => {
      Server.off_telemtry(on_error_data);
    };
  }, []);

  if (ErrorConfig && ErrorModel.HasErrors()) {
    return (<Grid item container direction={"column"} width={"36vmax"} bgcolor={"darkblue"}>
      <ErrorPopUp disabled={false} errorPopUpModel={ErrorModel}></ErrorPopUp>       </Grid>);
  }
  const canGoRight = menu.subScreenKeys.some((subScreenKey) => subScreenKey > subScreen.SubScreenName);
  const subScreenIdx = menu.subScreenKeys.sort().findIndex((subScreenKey) => subScreenKey === subScreen.SubScreenName);
  const canGoLeft = menu.subScreenKeys.some((subScreenKey) => subScreenKey < subScreen.SubScreenName);

  return <MenuView subScreen={subScreen} subScreenIdx={subScreenIdx} menu={menu} canGoRight={canGoRight} canGoLeft={canGoLeft} ScreenUpdate={ScreenUpdate} disabled={false}></MenuView>;
}


function MenuView({ subScreen,subScreenIdx, menu, canGoRight, canGoLeft, ScreenUpdate, disabled }: { subScreen: SubScreenModel; subScreenIdx : number; menu: MenuModel, canGoRight: boolean, canGoLeft: boolean, ScreenUpdate?: () => void, disabled: boolean }) {
  const Server = GetServer();
  function SlideLeft() {
    Server.SendCommand(`SetNavigatorButton`, `SlideLeft`, () => { });
  }
  function SlideRight() {
    Server.SendCommand(`SetNavigatorButton`, `SlideRight`, () => { });
  }
  return (
    <>
      <Grid item container direction={"column"} width={"36vmax"} height={"20vmax"} bgcolor={"darkblue"} position={"relative"}>

        <Grid item xs={1} container justifyContent="center" alignContent="center" alignItems="center">
          <Typography overflow="hidden">{menu.name}</Typography>
        </Grid>

        <Grid item xs={10} container direction="row" justifyContent="center" alignItems="center" alignContent="center">

          <Grid item xs={1} container justifyContent="end">
            {canGoLeft ? (
              <LeftButton
                onclickHandler={() => {
                  if (disabled) {
                    return;
                  }
                  SlideLeft();
                  ScreenUpdate?.();
                }}
                disabled={disabled}
              />
            ) : null}
          </Grid>
                
          <Grid item xs={10} container direction="column" border={1} height={"16vmax"} position={"relative"} borderColor={"white"} alignContent="center" justifyContent="center">
            <Grid item xs={1} position={"absolute"} sx={{ top: 0, left: 0 }}>
              <GoBackButton menuNo={menu.menuNo} sendUpdate={ScreenUpdate} disabled={false}></GoBackButton>
            </Grid>
            <Grid item xs={1} position={"absolute"} style={{ bottom: 0, right: 0 }}>
              <StopMachineButton sendUpdate={ScreenUpdate} disabled={false}></StopMachineButton>
            </Grid>
            <Grid item xs container direction="column" alignContent="center" alignItems="center">
              <SubScreen Subscreen={subScreen} ScreenUpdate={ScreenUpdate} disabled={disabled}></SubScreen>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            {canGoRight ? (
              <RightButton
                onclickHandler={() => {
                  if (disabled) {
                    return;
                  }
                  SlideRight();
                  ScreenUpdate?.();

                }}
                disabled={disabled}

              />
            ) : null}
          </Grid>

        </Grid>
        <Grid item xs={1} container justifyContent="center" alignContent="center" alignItems="center">
          <Typography overflow="hidden">{subScreenIdx + 1 + "/" + menu.numOfSubScreens}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

function BorderView(): React.JSX.Element | null {
  // return ( <svg version="1.1" viewBox="-10 30 210 297" xmlns="http://www.w3.org/2000/svg"><path transform="matrix(1.1149 0 0 1 -30.414 -3.7042)" d="m48.044 30.75c-6.2424-9.6e-5 -11.303 3.3045-11.303 7.3809v82.777c0 1.3594-0.02624 3.6304 0.59269 4.8261 1.5097 2.9164 5.7344 5.0159 10.71 5.0158h130.53c6.2418-9e-5 11.302-3.3043 11.302-7.3804v-85.239c1.5e-4 -4.0763-5.06-7.3809-11.302-7.3809zm65.264 1.5002c0.6904-2.8e-5 1.2501 0.55966 1.25 1.2501 3e-5 0.6904-0.55965 1.2501-1.25 1.2501-0.69039 2.2e-5 -1.2501-0.55966-1.25-1.2501-3e-5 -0.69039 0.55965-1.2501 1.25-1.2501zm-50.496 6.0002h100.99c8.0806 0 14.586 4.2482 14.586 9.525v65.95c0 5.2768-6.5056 9.525-14.586 9.525h-100.99c-8.0806 0-14.586-4.2482-14.586-9.525v-65.95c0-5.2768 6.5051-9.525 14.586-9.525z" fill="#333" stroke-width="1.0704"/></svg>
  return null;
}
/*
 */
