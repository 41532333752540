import React from "react";
import { login, getMyAccount } from "api/user.service";
import nilfiskFoodLogo from "shared/utils/images/NilfiskFoodLogoFULLHD.png";
import { useDispatch } from "react-redux";
import { setUser } from "shared/store/user/actions/userActions";
import ErrorAlert from "components/ErrorAlert";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();

  const [accountBanned, setAccountBanned] = React.useState(false);
  const [hasPassError, setHasPassError] = React.useState(false);
  const [hasConnError, setHasConnError] = React.useState(false);
  const [invalidEmailError, setInvalidEmailError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  // const history = createBrowserHistory({ forceRefresh: true });

  let navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.target);
    const email = formData.get("email");
    const password = formData.get("password");

    const user = { email, password };

    setHasPassError(false);
    setHasConnError(false);
    setAccountBanned(false);
    setInvalidEmailError(false);

    login(user)
      .then((res) => {
        console.log("res", res);
        if (localStorage.getItem("token") !== null) {
          //!verify this

          getMyAccount().then((res) => {
            const myAccount = res.data.user;
            console.log("myAccount", myAccount);
            dispatch(setUser({ user: myAccount }));
            navigate("/home");
          });

          // console.log(res)
          // setHasConnError(true); //! if the server is not running the app will refresh the page and the user won't know why he can't log in
          // setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status == 400) {
            if (error.response.data.email == "Email is invalid") setInvalidEmailError(true);
          }
          if (error.response.status == 403) {
            if (error.response.data.msg == "This account is banned!") setAccountBanned(true);
            else setAccountBanned(false);

            if (error.response.data.msg == "Password is incorrect!") setHasPassError(true);
            else setHasPassError(false);
          }
          if (error.response.status == 404) {
            setHasPassError(true);
          }
        } else if (error.request) {
          setHasConnError(true);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-nilfisk">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-2xl sm:px-12">
          <img className="mx-auto mb-6 w-auto" src={nilfiskFoodLogo} alt="Nilfisk Food" />
          <form className="space-y-6" id="myform" onSubmit={onSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    invalidEmailError ? "focus:ring-red-500 ring-red-500" : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    hasPassError ? "focus:ring-red-500 ring-red-500" : "focus:ring-blue-500 ring-gray-300"
                  }  sm:text-sm sm:leading-6`}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                />
                <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm leading-6">
                <Link to="/forgot-password" className="font-semibold text-blue-500 hover:text-blue-400">
                  Forgot password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              >
                Sign in
              </button>
              {loading ? (
                <div className="flex justify-center">
                  <div className="mt-4 animate-spin rounded-full w-8 h-8 border-t-4 border-r-4 border-b-4 border-l-4 border-blue-300 border-t-transparent"></div>
                </div>
              ) : null}
            </div>
            {accountBanned ? <ErrorAlert text="This account has been restricted!" /> : null}
            {invalidEmailError ? <ErrorAlert text="Email is invalid!" /> : null}
            {hasPassError ? <ErrorAlert text="Email or password is incorrect!" /> : null}
            {hasConnError ? <ErrorAlert text="There is a connection error." /> : null}
          </form>
          <p className="mt-10 text-center text-sm text-gray-500">
            Don't have an account?{" "}
            <a href="/register" className="font-semibold leading-6 text-blue-500 hover:text-blue-400">
              Sign up
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Login;
