import * as yup from "yup";
import { useChangePassword } from "hooks/user/useChangePassword";
import { useForm } from "react-hook-form";
import ErrorAlert from "components/ErrorAlert";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

const validationSchema = yup.object({
  firstName: yup.string("Enter first name").required("First name is required"),
  lastName: yup.string("Enter last name").required("Last name is required"),
  username: yup.string("Enter username").required("Username is required"),
  email: yup.string("Enter email").email("Must be a valid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/, "Password must contain at least one special character"),
  repeatPassword: yup.string("Enter repeat password").required("Repeat password required"),
  rememberMe: yup.boolean().oneOf([true], "Accepting Terms and Conditions is required"),
});

export default function Password() {
  const { mutate: changePassword, isPending, isError, isSuccess } = useChangePassword();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    clearErrors();
    let hasError = false;
    const regexLowercase = /[a-z]/;
    const regexUppercase = /[A-Z]/;
    const regexNumber = /[0-9]/;
    const regexSpecial = /[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/;

    if (data.newPassword.length < 6) {
      setError("newPassword", {
        type: "required",
        message: "Password must be at least 6 characters long",
      });
      hasError = true;
    }

    if (!regexLowercase.test(data.newPassword)) {
      setError("newPassword", {
        type: "required",
        message: "Password must contain at least one lowercase letter",
      });
      hasError = true;
    }

    if (!regexUppercase.test(data.newPassword)) {
      setError("newPassword", {
        type: "required",
        message: "Password must contain at least one uppercase letter",
      });
      hasError = true;
    }

    if (!regexNumber.test(data.newPassword)) {
      setError("newPassword", {
        type: "required",
        message: "Password must contain at least one number",
      });
      hasError = true;
    }

    if (!regexSpecial.test(data.newPassword)) {
      setError("newPassword", {
        type: "required",
        message: "Password must contain at least one special character",
      });
      hasError = true;
    }

    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "required",
        message: "Passwords do not match",
      });
      hasError = true;
    }

    if (hasError) {
      return;
    }

    // if (data.newPassword !== data.confirmPassword) {
    //   setError("confirmPassword", { type: "required", message: "Passwords do not match" });
    //   return;
    // }

    console.log("1");
    const newData = {
      oldPassword: data.currentPassword,
      newPassword: data.newPassword,
      repeatNewPassword: data.confirmPassword,
    };
    changePassword(newData, {
      onSuccess: (data) => {
        reset();
      },
      onError: (error) => {
        console.log("error", error.response);

        if (error.response.data.msg === "Incorrect old password.") {
          setError("currentPassword", { type: "required", message: "Incorrect password" });
        }
        if (error.response.data.msg === "Password do not match") {
          setError("repeatPassword", { type: "required", message: "Passwords do not match" });
        }
        if (error.response.data.msg === "The password is too short. It must be at least 6 characters long.") {
          setError("newPassword", { type: "required", message: "Password must be at least 6 characters long" });
        }
      },
    });
  };
  console.log("errors", errors);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Tabs */}

        {/* Description list with inline editing */}
        <div className="mt-10 divide-y divide-gray-200">
          <div className="space-y-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Change password</h3>
            <p className="max-w-2xl text-sm text-gray-500">Update your password associated with your account.</p>
          </div>
          <div className="mt-6">
            <dl className="divide-y divide-gray-200">
              <div className="py-4 sm:grid  sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 flex items-center">Current password</dt>
                <div className="relative flex-1">
                  <input
                    id="currentPassword"
                    name="currentPassword"
                    type="password"
                    autoComplete="password"
                    disabled={isPending}
                    required
                    {...register("currentPassword", { required: true })}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                      errors?.currentPassword ? "focus:ring-red-500 ring-red-500" : "focus:ring-blue-500 ring-gray-300"
                    }  sm:text-sm sm:leading-6`}
                  />
                  <p className="mt-2 text-sm text-red-600" id="email-error" hidden={!errors.currentPassword}>
                    {errors?.currentPassword?.message}
                  </p>
                </div>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4  sm:border-gray-200 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 flex items-center">New password</dt>
                <div>
                  {/* <!-- Strong Password --> */}
                  <div className="flex">
                    <div className="relative flex-1">
                      <input
                        id="newPassword"
                        name="newPassword"
                        type="password"
                        autoComplete="new-password"
                        disabled={isPending}
                        {...register("newPassword", { required: true })}
                        className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                          errors?.newPassword ? "focus:ring-red-500 ring-red-500" : "focus:ring-blue-500 ring-gray-300"
                        }  sm:text-sm sm:leading-6`}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error" hidden={!errors.newPassword}>
                        {errors?.newPassword?.message}
                      </p>

                      <div
                        id="hs-strong-password-popover"
                        className="hidden absolute z-10 w-full bg-white shadow-md rounded-lg p-4 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700"
                      >
                        <div
                          id="hs-strong-password-in-popover"
                          data-hs-strong-password='{
                          "target": "#newPassword",
                          "hints": "#hs-strong-password-popover",
                          "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-teal-500 h-2 flex-auto rounded-full bg-blue-500 opacity-50 mx-1",
                          "mode": "popover"
                        }'
                          className="flex mt-2 -mx-1"
                        ></div>

                        <h4 className="mt-3 text-sm font-semibold text-gray-800 dark:text-white">
                          Your password must contain:
                        </h4>

                        <ul className="space-y-1 text-sm text-gray-500">
                          <li
                            data-hs-strong-password-hints-rule-text="min-length"
                            className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                          >
                            <span className="hidden" data-check>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Minimum number of characters is 6.
                          </li>
                          <li
                            data-hs-strong-password-hints-rule-text="lowercase"
                            className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                          >
                            <span className="hidden" data-check>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Should contain lowercase.
                          </li>
                          <li
                            data-hs-strong-password-hints-rule-text="uppercase"
                            className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                          >
                            <span className="hidden" data-check>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Should contain uppercase.
                          </li>
                          <li
                            data-hs-strong-password-hints-rule-text="numbers"
                            className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                          >
                            <span className="hidden" data-check>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Should contain numbers.
                          </li>
                          <li
                            data-hs-strong-password-hints-rule-text="special-characters"
                            className="hs-strong-password-active:text-teal-500 flex items-center gap-x-2"
                          >
                            <span className="hidden" data-check>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span data-uncheck>
                              <svg
                                className="flex-shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M18 6 6 18" />
                                <path d="m6 6 12 12" />
                              </svg>
                            </span>
                            Should contain special characters.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Strong Password --> */}
                </div>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4  sm:border-gray-200 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 flex items-center">Confirm password</dt>

                <div className="flex">
                  <div className="relative flex-1">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      autoComplete="new-password"
                      required
                      disabled={isPending}
                      {...register("confirmPassword", { required: true })}
                      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                        errors?.confirmPassword
                          ? "focus:ring-red-500 ring-red-500"
                          : "focus:ring-blue-500 ring-gray-300"
                      }  sm:text-sm sm:leading-6`}
                    />
                    <p className="mt-2 text-sm text-red-600" id="email-error" hidden={!errors.confirmPassword}>
                      {errors?.confirmPassword?.message}
                    </p>
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>

        {/* <ErrorAlert text="Couldn't connect to the display!" /> */}
        <div className="flex items-center justify-end gap-x-6 px-4 py-4 sm:px-8">
          {isPending ? (
            <div
              className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
              role="status"
              aria-label="loading"
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : null}
          <button
            type="submit"
            disabled={isPending}
            className=" rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:pointer-events-none disabled:opacity-50"
          >
            Change password
          </button>
        </div>
        {isSuccess && <SuccessMessage />}
        {isError && <ErrorAlert text="There was an error changing your password. Please try again." />}
      </form>
    </>
  );
}

function SuccessMessage() {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">Password changed successfully!</p>
        </div>
      </div>
    </div>
  );
}
