import React, { useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import { UiElement, StateInfoField as infoFieldModel } from "../../../Models/Menus";
import { GetServer } from "../../../Models/ServerCommuncation/Server";
import { DatedIotData } from "../../../Models/ServerCommuncation/serverInterFace";

export default function StateInfoField({ element, ScreenUpdate, disabled }: { element: UiElement, ScreenUpdate?: () => void, disabled: boolean }) {
  return <InnerStateInfoField stateInfoField={element}></InnerStateInfoField>;
}

function InnerStateInfoField({ stateInfoField }: { stateInfoField: infoFieldModel }) {
  if (stateInfoField.telemtryField) {
    return <TelemetryInfoField stateInfoField={stateInfoField} telemtryField={stateInfoField.telemtryField}></TelemetryInfoField>;
  }

  return (
    <Grid item container>
      <Grid item xs={6} container justifyContent="center" bgcolor="white" bottom={0} top="auto" sx={{ borderTopLeftRadius: "2vw", borderBottomLeftRadius: "2vw" }}>

        <Typography fontSize="0.75em" color="black" textAlign="center" bottom={0}>
          {stateInfoField.display_name}
        </Typography>
      </Grid>
      <Grid item xs={6} bgcolor="white" borderLeft={1} borderColor="black" sx={{ borderBottomRightRadius: "2vw", borderTopRightRadius: "2vw" }}>

        <Typography fontSize="0.75em" color="black" textAlign="center" bottom={0}>
          {stateInfoField.fieldVal}
        </Typography>
      </Grid>
    </Grid>
  );
  //default case
}

function TelemetryInfoField({ stateInfoField, telemtryField }: { stateInfoField: infoFieldModel; telemtryField: string }) {
  const Server = GetServer();
  let [color, setColor] = React.useState<string>("");
  function SetTelemetry(data: DatedIotData) {
    let val = data.data[stateInfoField.telemtryField!] as number > 300;

    setColor(val ? "green" : "red");
  }

  useEffect(() => {
    Server.on_telemtry(SetTelemetry);

    return () => {
      Server.off_telemtry(SetTelemetry);
    };
  }, []);
  //   if (infoField.fieldUnit) {
  //     return (
  //       <Grid item container>
  //         <Grid item xs={12} container justifyContent="center" bgcolor="white" bottom={0} top="auto" sx={{ borderTopLeftRadius: "2vw", borderBottomLeftRadius: "2vw", borderBottomRightRadius: "2vw", borderTopRightRadius: "2vw" }}>
  //           <Typography fontSize="0.75em" color="black" textAlign="center" bottom={0}>
  //             {val + " " + infoField.fieldUnit}
  //           </Typography>
  //         </Grid>
  //       </Grid>
  //     );
  //   }
  return (
    <Grid item container>
      <Grid item xs={6} container height={"1.5vmax"} width={"1.5vmax"} justifyContent="center" alignContent={"center"} bottom={0} top="auto">
        <Typography fontSize="0.75em" color="white" variant="button" textAlign="center" bottom={0}>{stateInfoField.display_name}</Typography>
      </Grid>

      <Grid item xs={3} bgcolor={color} height={"1.5vmax"} borderLeft={1} borderColor="black" ></Grid>
    </Grid>
  );
}
