import { useQuery } from "@tanstack/react-query";
import { getOwnUserById, getUserById } from "api/user.service";
import { useSelector } from "react-redux";

export const useUserByIdQuery = (userId) => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useQuery({
    queryKey: ["user", userId],
    queryFn: () => (isAdmin ? getUserById(userId) : getOwnUserById(userId)),
    enabled: !!userId, // This prevents the query from automatically running if the userId is not provided
  });
};
