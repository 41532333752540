import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getFactoryRooms, getOwnFactoryRooms } from "api/factory.service";

export const useRoomsByFactoryIdQuery = (id) => {
  const myAccount = useSelector((state) => state.user.user);

  const isAdmin =
    myAccount &&
    (myAccount.Role.name === "Admin" || myAccount.Role.name === "Super Admin");

  return useQuery({
    queryKey: ["factory", id, "rooms"],
    queryFn: () => (isAdmin ? getFactoryRooms(id) : getOwnFactoryRooms(id)),
    enabled: !!id, // This prevents the query from automatically running if the userId is not provided
  });
};
