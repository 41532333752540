import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BellIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useSelector } from "react-redux";
//@ts-ignore
import React from "react";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import { userService } from "shared/services/authentication.service";
import { DeIntializeServer, GetServer, ServerExist } from "./Models/ServerCommuncation/Server";

const userNavigation = [
    { name: "Your profile", onClick: () => console.log("Your profile") },
    { name: "Sign out", onClick: () => userService.logout() },
];


function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

export default function NavigatorHeaderBar() {
    const navigate = useNavigate();
    const myAccount = useSelector((state: any) => state.user.user);
    function Disconnect() {
        if (ServerExist()) {
            navigate("/navigator/connect");
            GetServer().SendCommand("SetNavigatorDisconnect", "", () => {

            });
            DeIntializeServer();

            return;
   
        }
        
        navigate("/navigator/connect",{state : {errorMessage : "Didnt Disconnect display. Please ask user of machine to exit navigator mode"}});


    }


    function RebootSystem() {
        if (ServerExist()) {
            navigate("/navigator/connect");
            GetServer().SendCommand("RebootSystem", null, () => { });
            GetServer().disconnectNoError();

            return;
   
        }
        
        navigate("/navigator/connect",{state : {errorMessage : "Didnt Reboot display. Please ask user of machine to exit navigator mode and reboot"}});


    }

    return (
        <div>
            <div className="lg:pl-100">
                <div className="sticky top-0 z-40  flex h-[5vh] shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">


                    {/* Separator */}
                    <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

                    <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-1">
                        <div className="relative flex flex-1 items-center">
                            <div className="relative flex  items-center justify-start	">
                                <button //TODO create the new method for creating factories
                                    type="button"
                                    onClick={() => { Disconnect() }}
                                    className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                >
                                    Disconnect
                                </button>
                            </div>
                            <div className="relative flex flex-1 items-center justify-start	">
                                <button //TODO create the new method for creating factories
                                    type="button"
                                    onClick={() => { RebootSystem() }}
                                    className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                                >
                                    Reboot System
                                </button>
                            </div>
                            <div className="relative flex flex-1 items-center">

                                <h1 className="text-lg font-semibold leading-6 text-nilfisk">Navigator Tool</h1>
                            </div>
                        </div>
                        {/* <form className="relative flex flex-1">
              //TODO when implementing search use this form
               <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                type="search"
                name="search"
              />
            </form> */}

                        <div className="flex items-center gap-x-4 lg:gap-x-6">
                            <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>

                            {/* Separator */}
                            <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

                            {/* Profile dropdown */}
                            <Menu as="div" className="relative">
                                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                    <span className="sr-only">Open user menu</span>
                                    <span className="inline-flex items-center justify-center size-[32px] text-sm font-semibold leading-none rounded-full bg-blue-100 text-blue-800 dark:bg-blue-800/30 dark:text-blue-500">
                                        {myAccount.firstName.charAt(0) + myAccount.lastName.charAt(0)}
                                    </span>
                                    <span className="hidden lg:flex lg:items-center">
                                        <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                                            {myAccount.firstName} {myAccount.lastName}
                                        </span>
                                        <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                        {userNavigation.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {({ active }) => (
                                                    <button
                                                        onClick={item.onClick}
                                                        className={classNames(
                                                            active ? "bg-gray-100" : "",
                                                            "block w-full text-left px-4 py-2 text-sm  text-gray-900"
                                                        )}
                                                    >
                                                        {item.name}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
