import React, { useState } from "react";
import SidebarNav from "components/SidebarNav";
import HeaderBar from "components/HeaderBar";
import SidebarNavBranded from "components/SidebarNavBranded";
import SidebarNavTesting from "components/SidebarNavTesting";
import { Outlet } from "react-router-dom";
const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      {/* <SidebarNavTesting sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
      <SidebarNavBranded sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* <SidebarNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
      <HeaderBar setSidebarOpen={setSidebarOpen} />
      <div className="lg:pl-72">
        <main className="py-6 sm:py-10">
          <div className="px-0 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
};

export default Layout;
