import React from "react";
import { useOutletContext } from "react-router-dom";
import ChartsWrapper from "./components/ChartsWrapper";
import { useMachineConsumptionDataQuery } from "hooks/machine/useMachineConsumptionDataQuery";
import BarChartConsumption from "pages/Dashboard/Chart/BarChartConsumption";
import BarChartPower from "pages/Dashboard/Chart/BarChartPower";

export default function ViewMachineTelemetry() {
  const { machineData } = useOutletContext();

  // const {data, isLoading, error} = useMachinePerformanceDataQuery(machineData?.id);
  const { data, isLoading, error } = useMachineConsumptionDataQuery(
    machineData?.serialNumber,
  );

  const runtimeData = data?.data?.result?.RunningHours;
  var runtimeToday = 0;
  var runtimeLast7Days = 0;
  var runtimeMin = 0;
  var runtimeMax = 0;

  if (typeof runtimeData !== "undefined") {
    runtimeData.forEach((element, index) => {
      if (index === 0) {
        runtimeToday = element;
      }
      if (index < 7) {
        runtimeLast7Days += element;
      }
      if (runtimeMin > element) {
        runtimeMin = element;
      }
      if (runtimeMax < element) {
        runtimeMax = element;
      }
    });
  }

  const waterTempData = data?.data?.result?.WaterTempAvg;

  var waterTempToday = 0;
  var waterTempLast7Days = 0;
  var waterTempMin = 0;
  var waterTempMax = 0;

  if (typeof waterTempData !== "undefined") {
    waterTempData.forEach((element, index) => {
      if (index === 0) {
        waterTempToday = element;
      }
      if (index < 7) {
        waterTempLast7Days += element;
      }
      if (waterTempMin > element) {
        waterTempMin = element;
      }
      if (waterTempMax < element) {
        waterTempMax = element;
      }
    });

    waterTempLast7Days = waterTempLast7Days / 7;
  }

  var powerData = data?.data?.result?.Power;
  var powerToday = 0;
  var powerLast7Days = 0;
  var powerMin = 0;
  var powerMax = 0;

  if (typeof powerData !== "undefined") {
    powerData.forEach((element, index) => {
      if (index === 0) {
        powerToday = element;
      }
      if (index < 7) {
        powerLast7Days += element;
      }
      if (powerMin > element) {
        powerMin = element;
      }
      if (powerMax < element) {
        powerMax = element;
      }
    });
  }

  return (
    <>
      <ChartsWrapper machineData={machineData} />

      <div className="justify-between gap-4 sm:grid sm:grid-cols-2">
        <div className="w-full ">
          <div className="my-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Performance Data
              </h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Performance data from the last 7 days.
              </p>
            </div>
            <div className="border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Run time
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Today
                    </p>
                  </dt>
                  <dd className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {runtimeToday} h
                  </dd>
                </div>
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Run time
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Last 7 days
                    </p>
                  </dt>
                  <dd className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {runtimeLast7Days} h
                  </dd>
                </div>
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Run time min/max
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Last 7 days
                    </p>
                  </dt>
                  <dd className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {runtimeMin}/{runtimeMax} h
                  </dd>
                </div>
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Water temp. avg.
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Today
                    </p>
                  </dt>
                  <dd className="col-span-1 text-sm leading-6 text-gray-700">
                    {waterTempToday} °C
                  </dd>
                </div>
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Water temp. avg.
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Last 7 days
                    </p>
                  </dt>
                  <dd className="col-span-1 text-sm leading-6 text-gray-700 ">
                    {waterTempLast7Days} °C
                  </dd>
                </div>
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Water temp. min/max
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Last 7 days
                    </p>
                  </dt>
                  <dd className=" col-span-1 text-sm leading-6 text-gray-700 ">
                    {waterTempMin}/{waterTempMax} °C
                  </dd>
                </div>
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Power
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Today
                    </p>
                  </dt>
                  <dd className=" col-span-1 text-sm leading-6 text-gray-700 ">
                    {powerToday} kWh
                  </dd>
                </div>
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Power
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Last 7 days
                    </p>
                  </dt>
                  <dd className=" col-span-1 text-sm leading-6 text-gray-700 ">
                    {powerLast7Days} kWh
                  </dd>
                </div>
                <div className="grid grid-cols-2 px-4 py-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-900">
                    Power min/max
                    <p className="max-w-2xl text-xs leading-6 text-gray-500">
                      Last 7 days
                    </p>
                  </dt>
                  <dd className=" col-span-1 text-sm leading-6 text-gray-700 ">
                    {powerMin}/{powerMax} kWh
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="mt-4 w-full ">
          <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Running Hours
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <div className=" min-w-80 px-4 py-6 sm:px-6">
                <BarChartConsumption
                  data={data?.data?.result}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
          <div className=" my-4 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Power Consumption
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> */}
            </div>
            <div className="border-t border-gray-100">
              <div className=" min-w-80 px-4 py-6 sm:px-6">
              <BarChartPower
                    data={data?.data?.result}
                    loading={isLoading}
                  />
              </div>
            </div>
          </div>
          {/* <div className="my-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-2xl">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Power Consumption
              </h3>
              <div className="border-t border-gray-100">
                <div className="min-h-80 min-w-80 px-4 py-6 sm:px-6">
                  <BarChartPower
                    data={data?.data?.result}
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
