import decode from "jwt-decode";
import { createBrowserHistory } from "history";
const history = createBrowserHistory({ forceRefresh: true });

export const userService = {
  loggedIn: loggedIn,
  setToken: setToken,
  isTokenExpired: isTokenExpired,
  getToken: getToken,
  logout: logout,
  getRole: getRole,
  getId: getId,
  getLanguage: getLanguage,
};

function loggedIn() {
  const token = this.getToken();
  // token exists && is not expired
  return !!token && !this.isTokenExpired(token);
}

function getRole() {
  try {
    const token = this.getToken();
    // const decoded = decode(token);
    // console.log(decoded);
    const role = JSON.parse(token).role;
    // console.log("this is in the service");
    // console.log(JSON.parse(token).role);
    // console.log(token);
    return role;
  } catch (err) {
    // console.log("role unavailable");
    return 1;
  }
}

function getId() {
  try {
    const token = this.getToken();
    const decoded = decode(token);
    // console.log(decoded.id);

    return decoded.id;
  } catch (err) {
    console.log("expired check failed! Line 42: AuthService.js");
    return false;
  }
}
function getLanguage() {
  try {
    const token = this.getToken();

    // console.log(decoded.id);

    return JSON.parse(token).language;
  } catch (err) {
    console.log("there was a problem getting the language from the token");
    return false;
  }
}
function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    // console.log(decoded);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    } else return false;
  } catch (err) {
    console.log("expired check failed! Line 42: AuthService.js");
    return false;
  }
}

function setToken(idToken) {
  localStorage.setItem("token", idToken);
}

function getToken() {
  return localStorage.getItem("token");
}

function logout() {
  localStorage.removeItem("token");
  history.push("/login");
}
