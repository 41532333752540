import { useMutation } from "@tanstack/react-query";
import { updateFactory } from "api/factory.service";

export const useUpdateFactory = () => {
  return useMutation({
    mutationFn: (data) => {
      return updateFactory(data);
    },
  });
};
