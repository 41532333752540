import { Box, Button, CircularProgress, Grid, List, ListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GetServer } from "../../Models/ServerCommuncation/Server";
import { DatedIotData, IotData } from "../../Models/ServerCommuncation/serverInterFace";

const DIGITAL_DIODE_INPUT_NAMES = ["DI1", "DI2", "DI3", "DI4", "DI5", "DI6"];
const ANALOG_INPUT_DIODE = ["AI1", "AI2"];

const DIODE_OUTPUT_NAMES = ["DO1", "DO2", "DO3", "DO4", "DO5", "DO6", "DO7", "DO8", "DO9", "RL1", "RL2"];

type Diode = { diode_flag: boolean; diode_name: string };

type AnalogDiode = { diode_val: number; diode_name: string };

type DiodeType = "Output" | "Input";
type DiodeView = () => JSX.Element;
const DiodeViews: Record<DiodeType, DiodeView> = {
  Output: OutputDiodes,
  Input: InputDiodes,
};

export default function DiodeBox(): JSX.Element {
  const [diodeViewChosen, SetDiodeViewChosen] = React.useState<DiodeType>("Output");

  const DiodeView = DiodeViews[diodeViewChosen];
  return (
    <Grid item container xs bgcolor={"white"} borderRadius={"0.5vw"} className="shadow">
      <Grid item container direction="column">
        <Grid item container>
          <Grid item xs>
            <DiodeMenuButton
              name="Output"
              DiodeMenu={diodeViewChosen}
              onClick={() => {
                SetDiodeViewChosen("Output");
              }}
            ></DiodeMenuButton>
          </Grid>
          <Grid item xs>
            <DiodeMenuButton
              name="Input"
              DiodeMenu={diodeViewChosen}
              onClick={() => {
                SetDiodeViewChosen("Input");
              }}
            ></DiodeMenuButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container >
        <DiodeView></DiodeView>
      </Grid>
    </Grid>
  );
}

function DiodeMenuButton({ name, DiodeMenu, onClick }: { name: DiodeType; DiodeMenu: DiodeType; onClick: () => void }): JSX.Element {
  if (DiodeMenu === name) {
    return (
      <Grid item xs bgcolor={"#001b54"}>
        <Button fullWidth onClick={onClick}>
          <Typography color={"white"} variant="button">
            {name}
          </Typography>
        </Button>
      </Grid>
    );
  }
  return (
    <Grid item xs>
      <Button fullWidth onClick={onClick}>
        {name}{" "}
      </Button>
    </Grid>
  );
}

function ParseOutPutDiodes(data: IotData): [Diode, Diode?][] {
  let diodes: [Diode, Diode?][] = [];
  for (let i = 0; i <= DIODE_OUTPUT_NAMES.length - 2; i += 2) {
    let diode1: Diode = { diode_flag: !!((data.DiodesState >> i) & 1), diode_name: DIODE_OUTPUT_NAMES[i] };
    let diode2: Diode = { diode_flag: !!((data.DiodesState >> (i + 1)) & 1), diode_name: DIODE_OUTPUT_NAMES[i + 1] };
    diodes.push([diode1, diode2]);
  }
  let last = DIODE_OUTPUT_NAMES.length - 1;
  let diode: Diode = { diode_flag: !!((data.Diodes >> (last)) & 1), diode_name: DIODE_OUTPUT_NAMES[last] };
  diodes.push([diode, undefined]);
  return diodes;
}
const ToVoltValue = 0.0030625;
function ParseAnalogInPutDiodes(data: IotData): [AnalogDiode, AnalogDiode] {
  let Ai1: AnalogDiode = { diode_val: (data.AnalogState & 0xFF) * ToVoltValue, diode_name: "AI1" }
  let Ai2: AnalogDiode = { diode_val: ((data.AnalogState >> 16) & 0xFF) * ToVoltValue, diode_name: "AI2" };

  return [Ai1, Ai2];
}

function ParseInPutDiodes(data: IotData): [Diode, Diode?][] {
  let diodes: [Diode, Diode?][] = [];
  let inputShift = 16;
  for (let i = 0; i < DIGITAL_DIODE_INPUT_NAMES.length; i += 2) {
    let diode1: Diode = { diode_flag: !!((data.DiodeState >> (i + inputShift)) & 1), diode_name: DIGITAL_DIODE_INPUT_NAMES[i] };
    let diode2: Diode = { diode_flag: !!((data.DiodeState >> (i + inputShift + 1)) & 1), diode_name: DIGITAL_DIODE_INPUT_NAMES[i + 1] };

    diodes.push([diode1, diode2]);
  }

  return diodes;
}

function OutputDiodes(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<[Diode, Diode?][]>([]);
  const server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    let diodes = ParseOutPutDiodes(data.data);
    SetDiodes(diodes);
  };
  useEffect(() => {
    server.on_telemtry(ParseAndSetdiodes);
    return () => server.off_telemtry(ParseAndSetdiodes);
  }, []);

  if (Diodes.length === 0) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" height={"6vmax"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  return (
    <List className="scroll" style={{ width: "100%", overflow: "auto", height: "100%", maxHeight: "25vh" }}>
      {Diodes.map((diode) => {
        return (
          <ListItem>
            <Grid container>
              <Grid item xs={6}>
                <Diode diode={diode[0]}></Diode>
              </Grid>
              <Grid item xs={6}>
                <Diode diode={diode[1]}></Diode>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
}

function InputDiodes(): JSX.Element {
  const [Diodes, SetDiodes] = React.useState<[Diode, Diode?][]>([]);
  const [analogDiodes, SetAnalogDiodes] = React.useState<[AnalogDiode, AnalogDiode] | undefined>(undefined);
  const server = GetServer();
  const ParseAndSetdiodes = (data: DatedIotData) => {
    let diodes = ParseInPutDiodes(data.data);
    let analogDiodes = ParseAnalogInPutDiodes(data.data);
    SetDiodes(diodes);
    SetAnalogDiodes(analogDiodes);
  };
  useEffect(() => {
    server.on_telemtry(ParseAndSetdiodes);
    return () => server.off_telemtry(ParseAndSetdiodes);
  }, []);
  if (Diodes.length === 0) {
    return <Grid item container xs={12} justifyContent={"center"} alignContent={"center"} bgcolor={"white"} borderRadius={"0.5vw"} className="shadow" height={"6vh"}>
      <CircularProgress></CircularProgress>
    </Grid>;
  }
  return (
    <List className="scroll" style={{ width: "100%", overflow: "auto", maxHeight: "25vh" }}>
      {Diodes.map((diode) => {
        return (
          <ListItem>
            <Grid container>
              <Grid item xs={6}>
                <Diode diode={diode[0]}></Diode>
              </Grid>
              <Grid item xs={6}>
                <Diode diode={diode[1]}></Diode>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
      <ListItem>
        <Grid container>
          <Grid item xs={6}>
            <AnalogDiode analogDiode={analogDiodes ? analogDiodes[0] : undefined}></AnalogDiode>
          </Grid>
          <Grid item xs={6}>
            <AnalogDiode analogDiode={analogDiodes ? analogDiodes[1] : undefined}></AnalogDiode>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
}
function AnalogDiode({ analogDiode }: { analogDiode?: AnalogDiode }): JSX.Element | null {
  if (analogDiode === undefined) {
    return null;
  }

  return (
    <Grid item container>
      <Grid item container xs={6} justifyContent={"center"} alignContent={"center"}>
        <Typography variant="button" color={"black"} alignItems={"center"} textAlign={"center"}>
          {analogDiode.diode_name}
        </Typography>
      </Grid>

      <Grid item container xs={6} justifyContent={"center"}>
        <Grid item xs>
          <Typography variant="button" color={"black"} alignItems={"center"} textAlign={"center"}>
            {analogDiode.diode_val}

          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="button" color={"black"} alignItems={"center"} textAlign={"center"}>
            V
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
function Diode({ diode }: { diode?: Diode }): JSX.Element | null {
  if (diode === undefined) {
    return null;
  }

  return (
    <Grid item container>
      <Grid item container xs={6} justifyContent={"center"} alignContent={"center"}>
        <Typography variant="button" color={"black"} alignItems={"center"} textAlign={"center"}>
          {diode.diode_name}
        </Typography>
      </Grid>

      <Grid item container xs={6} justifyContent={"center"}>
        <Grid item>
          <Box height={"1vw"} width={"1vw"} className={"diode " + (diode.diode_flag ? "green" : "white")} borderColor={"silver"} borderRadius={"50%"} border={"0.2vw solid grey"}></Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
